import { Box, BoxProps, styled } from '@mui/material';
import mime from 'mime/lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useKey } from 'react-use';

import { downloadMedia } from '../../utils';
import { useVideoAutoplay } from './useVideoAutoplay';
import { useVideoCanvasBackdrop } from './useVideoCanvasBackdrop';

/**
 * `VideoPlayerProps` interface extends the standard video HTML attributes
 * and includes an optional `ContainerProps` property for additional styling
 * and configuration of the container.
 */
export interface VideoPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  canvasBackdrop?: boolean;
  inViewAutoplay?: boolean;
  inViewThreshold?: number;
  disabled?: boolean;
  disabledKeyboardEvents?: boolean;
  ContainerProps?: BoxProps;
}

const canvasUpdateTimeout = 1000 / 25; // 25 fps

const StyledBox = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  '& canvas': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    filter: 'blur(50px)',
    transition: `filter ${canvasUpdateTimeout}ms linear`,
    opacity: 0.5,
    transform: 'translateZ(0)',
    willChange: 'filter',
  },
  '& video': {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

/**
 * `VideoPlayer` component wraps a video element in a responsive container, supporting
 * various formats. It ensures the video fits the container while maintaining aspect ratio.
 * The container fills any empty space with a canvas for an ambient light effect.
 */
export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  autoPlay,
  canvasBackdrop,
  inViewAutoplay = false,
  inViewThreshold = 1,
  disabled,
  disabledKeyboardEvents,
  ContainerProps,
  ...rest
}) => {
  const [videoSrc, setVideoSrc] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useVideoCanvasBackdrop(videoRef, canvasUpdateTimeout);
  const { ref, inView } = useInView({ threshold: inViewThreshold });

  useVideoAutoplay(videoRef, autoPlay, inViewAutoplay, inView, disabled);

  const togglePlayPause = useCallback(() => {
    if (!disabled && !disabledKeyboardEvents) {
      const video = videoRef.current;
      if (video) {
        if (video.paused) {
          video.play().catch((e) => console.error('Error playing video:', e));
        } else {
          video.pause();
        }
      }
    }
  }, [disabled, disabledKeyboardEvents]);

  useEffect(() => {
    const controller = new AbortController();

    const fetchVideo = async () => {
      try {
        const blob = await downloadMedia(src, controller);
        setVideoSrc(blob ?? '');
      } catch (error) {
        // Error already handled, no need to show error — media just won't play
      }
    };

    fetchVideo();

    return () => {
      controller.abort();
    };
  }, [src]);

  useKey('Space', togglePlayPause, { event: 'keydown' });

  return (
    <StyledBox ref={inViewAutoplay ? ref : null} {...ContainerProps}>
      {canvasBackdrop && <canvas ref={canvasRef}></canvas>}
      <video ref={videoRef} {...rest}>
        {!!videoSrc && <source src={videoSrc} type={mime.getType(videoSrc) ?? undefined} />}
      </video>
    </StyledBox>
  );
};

import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';

import { Typography } from '../../../../Typography';
import { ProgressBarRow, ProgressBarRowProps } from './internal/ProgressBarRow';

export interface ProgressBarsTemplateProps {
  title: string;
  rows: Omit<ProgressBarRowProps, 'absoluteMax'>[];
  /**
   * Optional absolute maximum value for all rows.
   * If not provided, the largest rawValue in rows is used.
   */
  absoluteMax?: number;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['xxlarge'],
}));

const Rows = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.tokens.spacing['xxsmall'],
}));

export const ProgressBarsTemplate: React.FC<ProgressBarsTemplateProps> = ({
  title,
  rows,
  absoluteMax,
}) => {
  // Determine absoluteMax: Use prop value or find the largest rawValue in rows
  const calculatedMax = useMemo(
    () => absoluteMax ?? Math.max(...rows.map(({ rawValue }) => rawValue)),
    [rows, absoluteMax],
  );

  return (
    <Root>
      <Typography variant="heading.H9" lineClamp={2}>
        {title}
      </Typography>
      <Rows>
        {rows.map((rowProps, index) => (
          <ProgressBarRow
            key={index}
            {...rowProps}
            absoluteMax={calculatedMax} // Pass calculated or fixed absoluteMax
          />
        ))}
      </Rows>
    </Root>
  );
};

import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { TrendIndicator } from '../../../../../../TrendIndicator';
import { Typography } from '../../../../../../Typography';

type Sentiment = 'positive' | 'negative';
type TrendDirection = 'up' | 'down';

export interface LeaderboardInsightsRowTemplateProps {
  rank: number;
  title: string;
  additionalInfo: string;
  additionalInfoSentiment: Sentiment;
  description: string;
  value: string;
  valueSentiment: Sentiment;
  trendDirection: TrendDirection;
  trendSentiment: Sentiment;
}

const LeaderboardInsightsRowRoot = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['8'],
  paddingBottom: theme.tokens.spacing['xxlarge'],
  flexDirection: 'row',
}));

const ContentStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const TitleRowStack = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const ValueContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.tokens.spacing['4'],
}));

const TrendIndicatorWrapper = styled(Box)({
  paddingTop: '2px',
});

const getSentimentColor = (sentiment: Sentiment) =>
  sentiment === 'positive' ? 'text.success' : 'text.danger';

export const LeaderboardInsightsRowTemplate: React.FC<LeaderboardInsightsRowTemplateProps> = ({
  rank,
  title,
  additionalInfo,
  additionalInfoSentiment,
  description,
  value,
  trendDirection,
  trendSentiment,
  valueSentiment,
}) => {
  return (
    <LeaderboardInsightsRowRoot>
      <Typography variant="heading.H7" color={'text.subtle'}>
        #{rank.toString().padStart(2, '0')}
      </Typography>
      <ContentStack>
        <TitleRowStack>
          <Typography variant="heading.H7" lineClamp={2}>
            {title}
          </Typography>
          <ValueContainer>
            <Typography variant="heading.H9" color={getSentimentColor(valueSentiment)}>
              {value}
            </Typography>
            <TrendIndicatorWrapper>
              <TrendIndicator direction={trendDirection} sentiment={trendSentiment} />
            </TrendIndicatorWrapper>
          </ValueContainer>
        </TitleRowStack>
        <Typography
          variant="body.bold"
          color={getSentimentColor(additionalInfoSentiment)}
          lineClamp={2}
        >
          {additionalInfo}
        </Typography>
        <Typography variant="body.regular-condensed" color={'text.regular'} lineClamp={6}>
          {description}
        </Typography>
      </ContentStack>
    </LeaderboardInsightsRowRoot>
  );
};

import React, { useId } from 'react';

export const Glow = () => {
  const id = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="14" viewBox="0 0 64 14" fill="none">
      <g filter={`url(#${id}-filter0)`}>
        <ellipse
          cx="28"
          cy="3"
          rx="28"
          ry="3"
          transform="matrix(-1 0 0 1 60 4)"
          fill={`url(#${id}-paint0)`}
        />
      </g>
      <defs>
        <filter
          id={`${id}-filter0`}
          x="0"
          y="0"
          width="64"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result={`${id}-effect1_foregroundBlur`} />
        </filter>
        <radialGradient
          id={`${id}-paint0`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28 3) scale(28 3)"
        >
          <stop offset="0.385" stopColor="white" />
          <stop offset="0.49" stopColor="#48FF9B" />
          <stop offset="1" stopColor="#48FF9B" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

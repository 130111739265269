import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ArrowRightIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <path d="M14 23L20.5 17L14 11C13.7 10.7 13.7 10.3 14 10C14.3 9.7 14.7 9.7 15 10L21.8 16.3C22.1 16.6 22.1 17.1 21.8 17.4L15 23.9C14.7 24.2 14.3 24.2 14 23.9C13.7 23.6 13.7 23.3 14 23Z" />
  </SvgIcon>
);

export default ArrowRightIcon;

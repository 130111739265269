import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material';
import { FC } from 'react';

import { Stack } from '../../../../Stack';
import { Typography } from '../../../../Typography';

export type DecisionTemplateSentiment = 'positive' | 'negative';

export interface DecisionTemplateSentimentValue {
  value: string;
  sentiment: DecisionTemplateSentiment;
}

export interface DecisionTemplateSubtitleSentimentText {
  primaryText: string;
  sentiment: DecisionTemplateSentiment;
  secondaryText: string;
}

export interface DecisionTemplateProps {
  title: string;
  primaryValue: DecisionTemplateSentimentValue;
  primaryValueSubtitle: DecisionTemplateSubtitleSentimentText;
  secondaryValue: DecisionTemplateSentimentValue;
}

const sentimentContentColorMap: Record<DecisionTemplateSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

const Container = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.tokens.spacing.xxlarge,
}));

const Content = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.tokens.spacing.medium,
}));

const SentimentTextContainer = styled('div')(({ theme }) => ({
  paddingRight: theme.tokens.spacing.xsmall,
}));

export const DecisionTemplate: FC<DecisionTemplateProps> = ({
  title,
  primaryValue,
  primaryValueSubtitle,
  secondaryValue,
}) => (
  <Container>
    <Typography variant="heading.H5" lineClamp={4}>
      {title}
    </Typography>
    <Content>
      <Typography
        variant="heading.H2"
        color={sentimentContentColorMap[primaryValue.sentiment]}
        lineClamp={1}
      >
        {primaryValue.value}
      </Typography>
      <Stack direction="row">
        <SentimentTextContainer>
          <Typography
            variant="heading.H7"
            color={sentimentContentColorMap[primaryValueSubtitle.sentiment]}
            lineClamp={1}
          >
            {primaryValueSubtitle.primaryText}
          </Typography>
        </SentimentTextContainer>
        <Typography variant="heading.H7" lineClamp={1}>
          {primaryValueSubtitle.secondaryText}
        </Typography>
      </Stack>
      <Typography
        variant="heading.H2"
        color={sentimentContentColorMap[secondaryValue.sentiment]}
        lineClamp={1}
      >
        {secondaryValue.value}
      </Typography>
    </Content>
  </Container>
);

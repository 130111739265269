import H from '../../../../config/highchartsConfig';

/**
 * Converts a date string (e.g. "2029-01-01T00:00:00") into a "Q1 29" format.
 */
function formatDateToQuarterYear(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth(); // 0-based
  const quarter = Math.floor(month / 3) + 1; // 1–4
  const shortYear = String(year).slice(-2); // e.g., '29' if year is 2029

  return `Q${quarter} ${shortYear}`;
}

/**
 * Highcharts formatter that uses `formatDateToQuarterYear` on each axis category.
 */
export const quarterYearFormatter: H.AxisLabelsFormatterCallbackFunction = function () {
  const index = this.pos;
  const categories = this.axis.categories as string[];

  // Safely retrieve the string from categories and format it
  const dateString = categories[index];
  return formatDateToQuarterYear(dateString);
};

/**
 * Formats a number with the appropriate abbreviation.
 *
 * @param value The number to format.
 * @returns A formatted string (e.g., "500", "2.5K", "1.2M", "3B").
 */
function formatLargeNumber(value: number): string {
  const options = H.getOptions();
  const lang = options?.lang ?? {};
  // Provide default symbols if not defined
  const numericSymbols = lang.numericSymbols ?? ['K', 'M', 'B'];

  if (value >= 1e9) {
    return (value / 1e9).toFixed(1).replace(/\.0$/, '') + numericSymbols[2];
  } else if (value >= 1e6) {
    return (value / 1e6).toFixed(1).replace(/\.0$/, '') + numericSymbols[1];
  } else if (value >= 1e3) {
    return (value / 1e3).toFixed(1).replace(/\.0$/, '') + numericSymbols[0];
  } else {
    return value.toString();
  }
}

/**
 * Y‑axis formatter that uses a custom helper to display numbers
 * with the correct abbreviation (e.g., "0", "500", "2.5K", "1.2M", "3B").
 */
export const yAxisFormatter: H.AxisLabelsFormatterCallbackFunction = function () {
  // Ensure that this.value is a number, even if it's a string
  const numericValue = typeof this.value === 'number' ? this.value : parseFloat(this.value);
  return formatLargeNumber(numericValue);
};

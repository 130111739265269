import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const FlashIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path d="M6 0V11H9V20L16 8H12L15 0H6Z" />
  </SvgIcon>
);

export default FlashIcon;

import * as T from '@aily/graphql-sdk/schema';
import { NavigateOptions } from 'react-router-dom';

export const isPriorityResultFilter = (value?: any): value is T.PriorityResult =>
  value?.__typename === 'PriorityResult';

export const isDimensionResultFilter = (value?: any): value is T.DimensionResult =>
  value?.__typename === 'DimensionResult';

export interface AddPrioritiesProps {
  search?: string;
  handleSearch?: (
    newState: string | undefined,
    navigateOptions?: NavigateOptions,
    paramsToRemove?: string[],
    newPathname?: string,
  ) => void;
}

export interface ListItemResult {
  label: string;
  dimensionCode?: T.Maybe<string>;
  isFollowed: boolean;
  priorityId?: number;
  sortOrder?: number;
  isDrillable?: boolean;
  children?: ListItemResult[];
  dimensionId?: number;
}

export interface DataPrioritiesResult {
  categoryName: string;
  priorities: ListItemResult[];
}

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { map } from 'lodash-es';
import React from 'react';

import { Stack } from '../../../../Stack';
import {
  LeaderboardInsightsRowTemplate,
  LeaderboardInsightsRowTemplateProps,
} from './internal/LeaderboardInsightsRowTemplate/LeaderboardInsightsRowTemplate';

const Separator = styled(Box)({
  height: '1px',
  // TODO: Remove hardcoded color once we have the correct border color in the design system
  backgroundColor: '#404040',
});

export interface LeaderboardInsightsTemplateProps {
  rows: LeaderboardInsightsRowTemplateProps[];
}

export const LeaderboardInsightsTemplate: React.FC<LeaderboardInsightsTemplateProps> = ({
  rows,
}) => {
  return (
    <Stack spacing={'medium'}>
      {map(rows, (row) => (
        <>
          <LeaderboardInsightsRowTemplate {...row} />
          <Separator />
        </>
      ))}
    </Stack>
  );
};

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Typography } from '../../../../../../Typography';

export interface IRDecisionRowProps {
  module?: string;
  text?: string;
  sentiment?: 'positive' | 'negative';
  sentimentText?: string;
}

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const IRDecisionRow: React.FC<IRDecisionRowProps> = ({
  module,
  text,
  sentiment,
  sentimentText,
}) => {
  return (
    <Container>
      {module && (
        <Typography variant="heading.H9" color="text.subtle">
          {module}
        </Typography>
      )}
      {text && <Typography variant="body.regular-condensed">{text}</Typography>}
      {sentiment && sentimentText && (
        <Typography
          variant="heading.H6"
          color={sentiment === 'positive' ? 'text.success' : 'text.danger'}
        >
          {sentimentText}
        </Typography>
      )}
    </Container>
  );
};

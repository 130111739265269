import { Typography } from '../../Typography';
import { useBanner } from '../context';

export function Subject({ children }: { children: string }) {
  const { type } = useBanner();
  return (
    <Typography
      variant="body.small-condensed"
      color={type === 'grey' ? 'text.success' : 'neutral.black'}
      lineClamp={1}
    >
      {children}
    </Typography>
  );
}

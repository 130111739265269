import { Typography } from '../../Typography';
import { useBanner } from '../context';

export function Body({ children }: { children: string }) {
  const { type } = useBanner();
  return (
    <Typography
      variant="body.regular-condensed"
      color={type === 'grey' ? 'text.subtle' : 'background.positive'}
      lineClamp={2}
    >
      {children}
    </Typography>
  );
}

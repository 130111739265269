import { createContext, useContext } from 'react';

import type { BannerType } from './types';

const bannerContext = createContext<{
  type: BannerType;
}>({
  type: 'grey',
});

export const BannerProvider = bannerContext.Provider;

export const useBanner = () => useContext(bannerContext);

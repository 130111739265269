import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { TrendIndicator, Typography } from '../../../../../..';

export interface LeaderboardTopRowProps {
  rank: number;
  title: string;
  subtitle: string;
  value: string;
  valueSentiment: 'positive' | 'negative';
  trendDirection: 'up' | 'down';
  trendSentiment: 'positive' | 'negative';
}

const LeaderboardTopRowRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.tokens.spacing['8'],
  width: 274,
}));

const ContentStack = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.tokens.spacing['4'],
}));

const TrendIndicatorWrapper = styled(Box)({
  paddingTop: '3px',
});

export const LeaderboardTopRow: React.FC<LeaderboardTopRowProps> = ({
  rank,
  title,
  subtitle,
  value,
  trendDirection,
  trendSentiment,
  valueSentiment,
}) => {
  return (
    <LeaderboardTopRowRoot>
      <Typography variant="heading.H9" color={'text.subtle'}>
        #{rank.toString().padStart(2, '0')}
      </Typography>
      <ContentStack>
        <Typography variant="heading.H9">{title}</Typography>
        <Typography variant="body.regular-condensed" color={'text.subtle'}>
          {subtitle}
        </Typography>
        <ValueContainer>
          <Typography
            variant="heading.H8"
            color={valueSentiment === 'positive' ? 'text.success' : 'text.danger'}
          >
            {value}
          </Typography>
          <TrendIndicatorWrapper>
            <TrendIndicator direction={trendDirection} sentiment={trendSentiment} />
          </TrendIndicatorWrapper>
        </ValueContainer>
      </ContentStack>
    </LeaderboardTopRowRoot>
  );
};

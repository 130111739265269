import { ColorTokenKey } from '@aily/ui-theme';
import { Box, Stack, styled } from '@mui/material';
import { map } from 'lodash-es';
import { FC } from 'react';

import { Typography } from '../../../../Typography';

export type RecommendationsInsightsTemplateSentiment = 'positive' | 'negative';

export interface RecommendationsInsightsTemplateProps {
  title: string;
  subTitle: string;
  sentiment: RecommendationsInsightsTemplateSentiment;
  recommendationsTitle: string;
  recommendations: string[];
}

const sentimenHeaderTitleColorMap: Record<RecommendationsInsightsTemplateSentiment, ColorTokenKey> =
  {
    positive: 'sentiment.positive',
    negative: 'sentiment.negative',
  };

const Container = styled(Stack)(() => ({
  flexDirection: 'column',
}));

const Header = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.tokens.spacing.xlarge,
  paddingBottom: theme.tokens.spacing.xlarge,
}));

const RecommendationsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.tokens.spacing.medium,
}));

const RecommendationItemsContainer = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing.medium,
  padding: theme.spacing(theme.tokens.spacing.medium, 0),
}));

const SeparatorLine = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.tokens.color['neutral.grey'],
  width: '100%',
}));

export const RecommendationsInsightsTemplate: FC<RecommendationsInsightsTemplateProps> = ({
  title,
  subTitle,
  sentiment,
  recommendationsTitle,
  recommendations,
}) => {
  return (
    <Container>
      <Header>
        <Typography variant="heading.H4" color={sentimenHeaderTitleColorMap[sentiment]}>
          {title}
        </Typography>
        <Typography variant="heading.H8">{subTitle}</Typography>
      </Header>
      <RecommendationsContainer>
        <Typography variant="heading.H8" color="text.subtle">
          {recommendationsTitle}
        </Typography>
        <RecommendationItemsContainer divider={<SeparatorLine />}>
          {map(recommendations, (recommendation, index) => (
            <Typography variant="body.regular-condensed" lineClamp={8} key={index}>
              {recommendation}
            </Typography>
          ))}
        </RecommendationItemsContainer>
      </RecommendationsContainer>
    </Container>
  );
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Card: ['KpiCardResult', 'MicroChartCardResult', 'SimpleCardResult'],
    ChartAxis: [
      'ChartAxisCategoryViewResult',
      'ChartAxisPercentViewResult',
      'ChartAxisValueViewResult',
    ],
    ColorUnion: ['ColorString', 'ThemeColor'],
    Component: [
      'ButtonComponent',
      'CardsComponent',
      'CashFlowKpiComponent',
      'ChartComponent',
      'ColumnLayoutComponent',
      'ComponentBase',
      'DemoBdnlDriversComponent',
      'DemoHomeChartComponent',
      'DemoHomeMicroChartComponent',
      'DemoInsightsBdnlTableComponent',
      'DialsComponent',
      'EbiHomeCategoriesDialsComponent',
      'EbiHomeDetailsChartComponent',
      'EbiHomeDetailsChartHeaderComponent',
      'EbiHomeDetailsDriversChartComponent',
      'EbiHomeDetailsDriversChartHeaderComponent',
      'EbiHomeDetailsDriversComponent',
      'EbiHomeDetailsRecommendationsComponent',
      'FilterComponent',
      'FiltersComponent',
      'FinGtnOpexAiRecommendationComponent',
      'FinHomePageCardsComponent',
      'FinOverviewKpisComponent',
      'FinOverviewRecommenderComponent',
      'FinPnlAiRecommendationComponent',
      'GraFacilitatedPathwaysTableComponent',
      'GraHomeDialsComponent',
      'GraRecommendedPathwaysDetailsComponent',
      'GraRecommendedPathwaysTableComponent',
      'GridComponent',
      'GtmFrequencyChartComponent',
      'GtmFrequencyTableComponent',
      'GtmHomeCardsComponent',
      'GtmHomeDialComponent',
      'GtmHomepageCardsComponent',
      'GtmImpactChartComponent',
      'GtmImpactRecommendationComponent',
      'GtmImpactTableComponent',
      'GtmImpactTableHeaderComponent',
      'GtmInteractionsChartComponent',
      'GtmInteractionsTableComponent',
      'GtmPulseDetailsChartComponent',
      'GtmPulseDetailsChartHeaderComponent',
      'GtmPulseDetailsDriversComponent',
      'GtmPulseDialComponent',
      'GtmPulseLeaderboardListComponent',
      'GtmPulseLeaderboardPositionComponent',
      'GtmPulseRecommendedActionsComponent',
      'GtmReachChartComponent',
      'GtmReachTableComponent',
      'GtmResourceAllocationTableComponent',
      'HeaderRowComponent',
      'HeaderSimpleComponent',
      'KpiComponent',
      'ListComponent',
      'MarkdownComponent',
      'MicroChartComponent',
      'MnsDeviationsDetailsComponent',
      'MnsDeviationsDialComponent',
      'NavigationTabsComponent',
      'PlailistComponent',
      'PnlRebatesBreakdownChartComponent',
      'PnlRebatesBreakdownChartHeaderComponent',
      'PnlRebatesBreakdownTableComponent',
      'PnlRebatesInsightsCardsComponent',
      'PnlRebatesInsightsHeaderComponent',
      'PplHomeCardsComponent',
      'PplHomeCategoriesDialsComponent',
      'PplHomeDetailsChartComponent',
      'PplHomeDetailsChartHeaderComponent',
      'PplHomeDetailsDriversChartComponent',
      'PplHomeDetailsDriversChartHeaderComponent',
      'PplHomeDetailsDriversComponent',
      'PplHomeDetailsRecommendationsComponent',
      'PplHomeDialComponent',
      'ProAgentDetailsComponent',
      'ProChatComponent',
      'ProDailyAgentComponent',
      'ProHomeFeedComponent',
      'ProLibraryComponent',
      'ProSuperAgentComponent',
      'RecommendationsActionsComponent',
      'RecommendationsBreakdownComponent',
      'RecommenderComponent',
      'RndHomeDialsComponent',
      'RndInsightsBusinessDevelopmentAndLicensingDriversComponent',
      'RndInsightsBusinessDevelopmentAndLicensingTableComponent',
      'RndInventoryRecommendationsBreakdownComponent',
      'RndInventoryRecommendationsComponent',
      'RndInventoryRootCausesComponent',
      'RndStudyDesignFormComponent',
      'RndStudyDesignsSiteDistributionTableComponent',
      'RndStudyDesignsTableComponent',
      'RndTrialsAiPosDriversComponent',
      'RndTrialsKpiCategoriesComponent',
      'RndTrialsKpiDetailTableComponent',
      'RndTrialsPatientsEnrollmentHasWhatIfComponent',
      'RndTrialsRndInvestmentsPnlTableComponent',
      'RndValueOverviewChartComponent',
      'RndValueOverviewChartHeaderComponent',
      'SpendCmoPulseDialComponent',
      'SpendCmoPulseDriversChartComponent',
      'SpendCmoPulseDriversListComponent',
      'SpendCmoPulseKeyDriversChartComponent',
      'SpendCmoPulseKeyDriversListComponent',
      'SpendCmoPulseLeaderboardListComponent',
      'SpendCmoPulseLeaderboardPositionComponent',
      'SpendCmoPulseRecommendedActionsComponent',
      'SpendGlocalPulseDialComponent',
      'SpendGlocalPulseDriversChartComponent',
      'SpendGlocalPulseDriversListComponent',
      'SpendGlocalPulseKeyDriversChartComponent',
      'SpendGlocalPulseKeyDriversListComponent',
      'SpendGlocalPulseLeaderboardListComponent',
      'SpendGlocalPulseLeaderboardPositionComponent',
      'SpendGlocalPulseRecommendedActionsComponent',
      'SpendHomeCardsComponent',
      'SpendHomeDialComponent',
      'SpendProcurementChartComponent',
      'SpendProcurementTableComponent',
      'SpendTrendingComponent',
      'StoryListComponent',
      'StratPlanPnlAnalyzerChartComponent',
      'StratPlanPnlAnalyzerChartHeaderComponent',
      'StratPlanPnlAnalyzerTableComponent',
      'StratPlanPnlTableComponent',
      'StratPlanSalesChartComponent',
      'StratPlanSalesChartHeaderComponent',
      'StratPlanSalesTableComponent',
      'StratPlanSgnaTableComponent',
      'SummaryInfoComponent',
      'SupplyHomeCardsComponent',
      'SupplyHomeDialsComponent',
      'SupplyLowInventoryRiskRootCausesComponent',
      'SupplyPulseLeaderboardListComponent',
      'SupplyShortDatedRootCausesComponent',
      'SupportComponent',
      'TableComponent',
      'TableSwitcherComponent',
      'TimelineComponent',
      'UserAnalyticsComponent',
      'UserPrioritiesComponent',
      'VideoComponent',
    ],
    Filter: ['SelectFilter', 'SelectGroupFilter', 'TreeSelectFilter'],
    FilterValue: ['MultiSelectFilterValue', 'SelectFilterValue', 'SelectGroupFilterValue'],
    IDataView: [
      'ButtonDataView',
      'CardsDataView',
      'CashFlowKpiDataViewResult',
      'ChartDataViewResult',
      'CmoPulseLeaderboardPositionDataView',
      'CmoPulseRecommendedActionsDataView',
      'DemoBdnlDriversDataView',
      'DemoBdnlTableDataView',
      'DialsDataView',
      'EbiHomeCategoriesDialsDataView',
      'EbiHomeDetailsChartHeaderDataView',
      'EbiHomeDetailsDriverChartHeaderDataView',
      'EbiHomeDetailsDriversDataView',
      'EbiHomeDetailsRecommendationsDataView',
      'EbiMicroChartResult',
      'FinGtnOpexAiRecommendationDataView',
      'FinHomePageCardsDataView',
      'FinOverviewKpisDataViewResult',
      'FinOverviewRecommenderDataViewResult',
      'FinPnlAiRecommendationDataView',
      'GlocalPulseLeaderboardPositionDataView',
      'GlocalPulseRecommendedActionsDataView',
      'GraFacilitatedPathwaysTableDataView',
      'GraHomeCardsDataView',
      'GraHomeDialsDataView',
      'GraRecommendedPathwaysDetailsDataView',
      'GraRecommendedPathwaysTableDataView',
      'GridDataView',
      'GtmCardDataView',
      'GtmFrequencyChartDataView',
      'GtmFrequencyTableDataView',
      'GtmHomeDialDataView',
      'GtmHomepageCardsDataView',
      'GtmImpactChartDataView',
      'GtmImpactRecommendationDataView',
      'GtmImpactTableDataView',
      'GtmImpactTableHeaderDataView',
      'GtmInteractionsChartDataView',
      'GtmInteractionsTableDataView',
      'GtmPulseDetailsChartHeaderDataView',
      'GtmPulseDetailsDriversDataView',
      'GtmPulseDialDataView',
      'GtmPulseLeaderboardListDataView',
      'GtmPulseLeaderboardPositionDataView',
      'GtmPulseRecommendedActionsDataView',
      'GtmReachChartDataView',
      'GtmReachTableDataView',
      'GtmResourceAllocationTableDataView',
      'HeaderRow',
      'HeaderSimple',
      'KpiStatusOverview',
      'ListDataView',
      'MicroChartResult',
      'MnsDeviationsDetailsDataView',
      'MnsDeviationsDialDataView',
      'PnlRebatesBreakdownChartDataView',
      'PnlRebatesBreakdownChartHeaderDataView',
      'PnlRebatesBreakdownQuartersTableDataView',
      'PnlRebatesBreakdownTableDataView',
      'PnlRebatesInsightsCardsDataView',
      'PnlRebatesInsightsHeaderDataView',
      'PplCardDataView',
      'PplHomeCategoriesDialsDataView',
      'PplHomeDetailsChartHeaderDataView',
      'PplHomeDetailsDriverChartHeaderDataView',
      'PplHomeDetailsDriversDataView',
      'PplHomeDetailsRecommendationsDataView',
      'PplHomeDialDataView',
      'PplMicroChartResult',
      'ProAgentDetailsDataView',
      'ProDailyAgentDataView',
      'ProHomeFeedDataView',
      'RecommendationsActionsDataView',
      'RecommendationsBreakdownDataView',
      'RecommenderDataView',
      'RndHomeDialsDataView',
      'RndInsightsBusinessDevelopmentAndLicensingDriversView',
      'RndInsightsBusinessDevelopmentAndLicensingTableDataView',
      'RndInventoryRecommendationsBreakdownDataView',
      'RndInventoryRecommendationsDataView',
      'RndInventoryRootCausesDataView',
      'RndStudyDesignerSiteDistributionTableDataView',
      'RndStudyDesignsTableDataView',
      'RndTimelineChartDataViewResult',
      'RndTrialsAiPosDriversDataView',
      'RndTrialsBreakdownAiPtrsDriversDataView',
      'RndTrialsKpiCategoriesDataView',
      'RndTrialsKpiDetailTableDataView',
      'RndTrialsPatientsEnrollmentHasWhatIfDataView',
      'RndTrialsRndInvestmentsPnlTableDataView',
      'RndTrialsValueOverviewChartDataView',
      'RndTrialsValueOverviewChartDataViewResult',
      'RndTrialsValueOverviewChartHeaderDataView',
      'SpendCardDataView',
      'SpendCmoPulseDialDataView',
      'SpendCmoPulseDriversChartDataView',
      'SpendCmoPulseDriversListDataView',
      'SpendCmoPulseLeaderboardListDataView',
      'SpendGlocalPulseDialDataView',
      'SpendGlocalPulseDriversChartDataView',
      'SpendGlocalPulseDriversListDataView',
      'SpendGlocalPulseLeaderboardListDataView',
      'SpendHomeDialDataView',
      'SpendProcurementChartDataView',
      'SpendProcurementTableDataView',
      'SpendTrendingResult',
      'StratPlanPnlAnalyzerChartDataView',
      'StratPlanPnlAnalyzerChartHeaderDataView',
      'StratPlanPnlAnalyzerTableDataView',
      'StratPlanPnlTableDataView',
      'StratPlanSalesChartDataView',
      'StratPlanSalesChartHeaderDataView',
      'StratPlanSalesTableDataView',
      'StratPlanSgnaTableDataView',
      'SummaryInfoDataView',
      'SupplyHomeCardsDataView',
      'SupplyHomeDialsDataView',
      'SupplyLowInventoryRiskRootCausesDataView',
      'SupplyPulseDriverKpiChartDataView',
      'SupplyPulseDriverKpisDataView',
      'SupplyPulseLandingResult',
      'SupplyPulseLeaderboardListDataView',
      'SupplyShortDatedRootCausesDataView',
      'TableDataView',
    ],
    IProAgentStructureTemplateResult: [
      'ProAgentStructureTemplateMobileResult',
      'ProAgentStructureTemplateWebResult',
    ],
    IProPayloadTemplateResult: [
      'ProPayloadTemplateClinicalSupplyResult',
      'ProPayloadTemplateDecisionResult',
      'ProPayloadTemplateErrorResult',
      'ProPayloadTemplateGenericScenarioResult',
      'ProPayloadTemplateGetMoneyResult',
      'ProPayloadTemplateImpRoadResult',
      'ProPayloadTemplateInsightsResult',
      'ProPayloadTemplateInsightsSentimentResult',
      'ProPayloadTemplateIntroResult',
      'ProPayloadTemplateIrDecisionsResult',
      'ProPayloadTemplateIrFocusResult',
      'ProPayloadTemplateLaunchesResult',
      'ProPayloadTemplateLeaderboardInsightsResult',
      'ProPayloadTemplateLeaderboardResult',
      'ProPayloadTemplateLeaderboardTopResult',
      'ProPayloadTemplateLeversResult',
      'ProPayloadTemplateMessageInsightResult',
      'ProPayloadTemplateOutroResult',
      'ProPayloadTemplatePharmaProductResult',
      'ProPayloadTemplatePortfolioResult',
      'ProPayloadTemplateProgressBarsColumnResult',
      'ProPayloadTemplateProgressBarsResult',
      'ProPayloadTemplateRecommendationsBaseResult',
      'ProPayloadTemplateRecommendationsInsightsResult',
      'ProPayloadTemplateRelatedDecisionsResult',
      'ProPayloadTemplateSummaryResult',
      'ProPayloadTemplateTrialsResult',
    ],
    InsightType: ['Real', 'Story'],
    Media: ['ImageResult', 'VideoContent'],
    ScreenResultType: [
      'DailyAgentActionsScreenResult',
      'DailyAgentFireworksScreenResult',
      'DailyAgentProgressScreenResult',
    ],
    TableCellContent: [
      'BadgeResult',
      'ColoredVarianceResult',
      'ColoredVarianceWithDeltaResult',
      'ComparatorTrendResult',
      'CriteriaBadgeResult',
      'Delta',
      'DrillDownResult',
      'GraphicElementResult',
      'IconLinkResult',
      'IconsLinkResult',
      'KpiStatus',
      'KpiStatusOverview',
      'LocationMarkerResult',
      'MicroChartResult',
      'ProgressBarResult',
      'RegionCountryResult',
      'RiskIndicatorResult',
      'StatusTextResult',
      'StudyDesignLinkResult',
      'TextExtendedResult',
      'TextLinkResult',
      'TextResult',
      'Timeline',
      'TimelineDataPoint',
      'TimelinePhases',
      'ValueWithForecastResult',
      'ValueWithVarianceResult',
      'VarianceResult',
    ],
  },
};
export default result;

import { alpha, styled } from '@mui/material/styles';
import React, { FC } from 'react';

import { MaybeButtonBase } from '../../buttons';
import { Divider } from '../../Divider';
import { ArrowRightIcon, IconName, icons } from '../../icons';
import { Stack } from '../../Stack';
import { Typography } from '../../Typography';
import { Glow } from './Glow';

export interface CardRecommendationBasicProps {
  title: string;
  description: string;
  iconName?: CardRecommendationBasicIconName;
  onClick?: () => void;
}

export type CardRecommendationBasicIconName = Extract<IconName, 'ai' | 'correlator'>;

const borderWidth = 1;

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 76,
  borderRadius: theme.tokens.borderRadius['medium'],
  padding: borderWidth,
  background: `linear-gradient(
    230deg,
    ${theme.tokens.color['neutral.white']} 0%,
    ${alpha(theme.tokens.color['brand.mint'], 0.5)} 15%,
    ${theme.tokens.color['brand.aqua']} 35%,
    ${alpha(theme.tokens.color['brand.aqua'], 0.5)} 70%
  )`,
  button: {
    width: '100%',
    height: '100%',
  },
}));

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: parseInt(theme.tokens.borderRadius['medium']) - borderWidth / 2, // Correct way to calculate inner border radius
  padding: theme.tokens.spacing['medium'],
  backgroundColor: theme.tokens.color['background.regular'],
  overflow: 'hidden',
}));

const GlowContainer = styled('div')({
  position: 'absolute',
  top: -10,
  right: 30,
  svg: {
    width: '100%',
    height: '100%',
  },
});

export const CardRecommendationBasic: FC<CardRecommendationBasicProps> = ({
  title,
  description,
  iconName = 'ai',
  onClick,
}) => {
  const IconComponent = icons[iconName];
  return (
    <Root>
      <MaybeButtonBase onClick={onClick} disableHoverStyle>
        <Content>
          <Stack direction="row" alignItems="center" spacing="medium" sx={{ flex: 1, minWidth: 0 }}>
            <IconComponent size="icon.medium-large" />
            <Stack
              direction="row"
              alignItems="center"
              spacing="medium"
              divider={<Divider orientation="vertical" flexItem height={44} />}
              sx={{ overflow: 'visible', minHeight: '44px' }}
            >
              <Typography variant="heading.H9" lineClamp={1}>
                {title}
              </Typography>
              <Typography
                variant="body.regular-condensed"
                sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {description}
              </Typography>
            </Stack>
          </Stack>
          {onClick && <ArrowRightIcon size="icon.medium-large" color="neutral.400" />}
        </Content>
      </MaybeButtonBase>
      <GlowContainer>
        <Glow />
      </GlowContainer>
    </Root>
  );
};

export const BannerVariants = [
  'badge',
  'kpis',
  'notification',
  'pro',
  'share',
  'what-if',
  'wrapped',
] as const;

export type BannerType = 'green' | 'grey';
export type BannerVariant = (typeof BannerVariants)[number];

export type VisualType =
  | { visualType: 'external'; visualSrc: string }
  | { visualType?: 'variant'; variant: BannerVariant };

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const useSentryRouteTags = (pathname: string) => {
  useEffect(() => {
    const trimmedPath = pathname.startsWith('/web/') ? pathname.slice(5) : pathname.slice(1);
    const pathSegments = trimmedPath.split('/');

    const module = pathSegments[0] || 'unknown';
    const activePage = pathSegments[1] || 'unknown';
    const focusArea = pathSegments[2] || 'none';

    Sentry.setTags({
      module,
      activePage,
      focusArea,
    });
  }, [pathname]);
};

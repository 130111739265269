import { ColorTokenKey } from '@aily/ui-theme';
import { alpha, styled } from '@mui/material/styles';
import { motion, Transition, useAnimation } from 'framer-motion';
import React, { memo, useEffect } from 'react';

export interface ProgressBarProps {
  /**
   * Progress value between 0 and 100.
   * Controls the width of the bar and position of the indicator.
   */
  value: number;
  /**
   * Sentiment of the progress bar.
   * Determines the color of the bar and indicator.
   */
  sentiment?: ProgressBarSentiment;
  /**
   * The component width. Defaults to '100%'.
   */
  width?: string | number;
  /**
   * Disable animation and update the bar instantly.
   */
  disableAnimation?: boolean;
}

export type ProgressBarSentiment = 'positive' | 'negative';

const sentimentColorMap: Record<ProgressBarSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

// Clamp value between 0 and 100
const clampValue = (value: number) => Math.min(100, Math.max(0, value));

const transition: Transition = {
  duration: 0.3,
  ease: 'circInOut',
};

const Root = styled('div')<{ width: string | number }>(({ width }) => ({
  position: 'relative',
  width,
  height: 24,
}));

const Bar = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: ColorTokenKey }>(({ theme, color }) => ({
  height: '100%',
  borderTopLeftRadius: theme.tokens.borderRadius['small'],
  borderBottomLeftRadius: theme.tokens.borderRadius['small'],
  backgroundColor: theme.tokens.color[color],
  opacity: 0.2,
}));

const Indicator = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: ColorTokenKey }>(({ theme, color }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 6,
  height: '100%',
  backgroundColor: theme.tokens.color[color],
  boxShadow: `
    0 0 10px 5px ${alpha(theme.tokens.color[color], 0.5)},
    0 0 20px ${alpha(theme.tokens.color[color], 0.3)}
  `,
}));

export const ProgressBar: React.FC<ProgressBarProps> = memo(
  ({ value, sentiment = 'positive', width = '100%', disableAnimation = false }) => {
    const clampedValue = clampValue(value);
    const color = sentimentColorMap[sentiment];

    const barControls = useAnimation();
    const indicatorControls = useAnimation();

    useEffect(() => {
      if (disableAnimation) {
        barControls.set({ width: `${clampedValue}%` });
        indicatorControls.set({ left: `${clampedValue}%` });
      } else {
        barControls.start({ width: `${clampedValue}%`, transition });
        indicatorControls.start({ left: `${clampedValue}%`, transition });
      }
    }, [clampedValue, disableAnimation, barControls, indicatorControls]);

    return (
      <Root
        width={width}
        role="progressbar"
        aria-valuenow={clampedValue}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <Bar color={color} initial={{ width: 0 }} animate={barControls} />
        <Indicator color={color} initial={{ left: 0 }} animate={indicatorControls} />
      </Root>
    );
  },
);

import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const MinusCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="29" height="28" viewBox="0 0 29 28" {...props}>
    <path d="M0.684222 13.9876C0.696573 6.26028 6.98328 -0.0104948 14.7027 0.00184925C22.4222 0.0141933 28.6965 6.29731 28.6842 14.0123C28.6718 21.7274 22.3851 27.9981 14.6657 27.9981C6.94623 27.9858 0.671871 21.7027 0.684222 13.9876ZM26.3004 14.0123C26.3128 7.60578 21.1006 2.39659 14.6904 2.38425C8.28015 2.3719 3.06798 7.58109 3.05563 13.9876C3.04328 20.3942 8.25545 25.6034 14.6657 25.6157C21.0759 25.6281 26.3004 20.4065 26.3004 14.0123Z" />
    <path d="M7.99573 12.7781L21.3843 12.7904L21.372 15.2099L7.99573 15.1975V12.7781Z" />
  </SvgIcon>
);

export default MinusCircleIcon;

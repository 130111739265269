import { ColorTokenKey } from '@aily/ui-theme';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Stack } from '../../../../../../Stack';
import { Typography } from '../../../../../../Typography';

type ChartHorizontalBarRowSentiment = 'positive' | 'negative';

export interface ChartHorizontalBarRowProps {
  title: string;
  subtitle: string;
  sentiment: ChartHorizontalBarRowSentiment;
  percentage?: number;
}

const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const ProgressRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.tokens.spacing.small,
}));

const ProgressContainer = styled(Box)({
  position: 'relative',
  flex: 1,
  height: 8,
});

const Progress = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'percentage' && prop !== 'sentiment',
})<{ percentage?: number; sentiment: ChartHorizontalBarRowSentiment }>(({ percentage = 0 }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: `${Math.min(100, Math.max(0, percentage))}%`,
  height: 8,
  backgroundColor: '#48FF9B',
  opacity: 0.2,
  borderRadius: 4,
  transition: 'width 0.3s ease-in-out',
}));

const sentimentColorMap: Record<ChartHorizontalBarRowSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
} as const;

export const ChartHorizontalBarRow: React.FC<ChartHorizontalBarRowProps> = ({
  title,
  subtitle,
  sentiment,
  percentage = 0,
}) => {
  return (
    <Stack>
      <TextContainer>
        <Typography
          variant="body.regular-condensed"
          color="text.subtle"
          lineClamp={2}
          sx={{ width: '100%', maxWidth: '100%' }}
        >
          {title}
        </Typography>
      </TextContainer>
      <ProgressRow>
        <Typography variant="body.bold-condensed" color={sentimentColorMap[sentiment]}>
          {subtitle}
        </Typography>
        <ProgressContainer>
          <Progress percentage={percentage} sentiment={sentiment} />
        </ProgressContainer>
      </ProgressRow>
    </Stack>
  );
};

export default ChartHorizontalBarRow;

import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Body } from './components/Body';
import { CloseButton } from './components/CloseButton';
import { Container } from './components/Container';
import { Subject } from './components/Subject';
import { Title } from './components/Title';
import { Visual } from './components/Visual';
import { BannerProvider } from './context';
import type { BannerType, VisualType } from './types';

export const IMAGE_HEIGHT = 64;

const BannerContent = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingLeft: 130,
  paddingRight: 24,
});

type BannerProps = VisualType & {
  onPress?: () => void;
  onClose?: () => void;
  type?: BannerType;
  subject?: string;
  title?: string;
  body?: string;
};

export function Banner({
  onPress,
  onClose,
  type = 'grey',
  subject,
  title,
  body,
  ...visualProps
}: BannerProps) {
  return (
    <BannerProvider
      value={{
        type,
        ...visualProps,
      }}
    >
      <Container>
        <BannerContent
          onClick={onPress}
          sx={{
            cursor: onPress ? 'pointer' : 'default',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Visual height={IMAGE_HEIGHT} {...visualProps} />
            <Stack flex={1} minWidth={0}>
              {subject && <Subject>{subject}</Subject>}
              {title && <Title>{title}</Title>}
              {body && <Body>{body}</Body>}
            </Stack>
            {onClose && <CloseButton onPress={onClose} />}
          </Stack>
        </BannerContent>
      </Container>
    </BannerProvider>
  );
}

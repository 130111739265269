import {
  GetDimensionsQuery,
  GetPrioritiesQuery,
  useGetDimensionsQuery,
  useGetPrioritiesQuery,
} from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { isNil, reject } from 'lodash-es';
import { useCallback, useState } from 'react';

import {
  DataPrioritiesResult,
  isPriorityResultFilter,
  ListItemResult,
} from '../components/UserPriorities/types';
import { useModule } from '../providers';

export const transformData = (data: T.PriorityResult[] | T.DimensionResult[]): ListItemResult[] => {
  return data.map((filter) => {
    if (isPriorityResultFilter(filter)) {
      const { priorityName, dimensionCode, isDrillable, isFollowed, priorityId, sortOrder } =
        filter;
      return {
        label: priorityName,
        dimensionCode,
        isFollowed,
        priorityId,
        sortOrder,
        isDrillable,
      };
    }
    const { children, dimensionId, dimensionCode, dimensionLabel, isFollowed } = filter;

    return {
      label: dimensionLabel,
      dimensionCode,
      isFollowed,
      dimensionId,
      children: children ? transformData(children) : [],
    };
  });
};

export function usePrioritiesDimensions() {
  const [priorities, setPriorities] = useState<DataPrioritiesResult[]>([]);

  const [selectedPriority, setSelectedPriority] = useState<ListItemResult | null>();

  const [dimensions, setDimensions] = useState<ListItemResult[]>([]);
  const [selectedDimension, setSelectedDimension] = useState<ListItemResult | null>();

  const moduleId = useModule()?.id ?? '';
  const moduleName = useModule()?.moduleCode ?? '';

  const onPrioritiesCompleted = useCallback(
    (data: GetPrioritiesQuery) => {
      const priorities = data.priorities.map((category) => ({
        categoryName: category.categoryName,
        priorities: transformData(category.priorities),
      }));

      setPriorities(priorities);
    },
    [transformData],
  );

  const { loading, error } = useGetPrioritiesQuery({
    variables: { input: { moduleId } },
    onCompleted: onPrioritiesCompleted,
  });

  const onDimensionsCompleted = useCallback(
    (data: GetDimensionsQuery) => {
      let dimensions = transformData(data?.dimensions as T.DimensionResult[]);

      // Temp fix for not showing total company as dimension on FE until BE/DB finds solution
      if (moduleName === T.ModuleCode.Gtm) {
        dimensions = reject(dimensions, { dimensionCode: 'total_company' });
      }
      setDimensions(dimensions);
    },
    [transformData],
  );

  const { loading: dimensionsLoading, error: dimensionsError } = useGetDimensionsQuery({
    ...(selectedPriority && !isNil(selectedPriority.priorityId)
      ? { variables: { input: { moduleId, priorityId: selectedPriority.priorityId } } }
      : { skip: true }),
    onCompleted: onDimensionsCompleted,
  });

  return {
    priorities,
    dimensions,
    selectedPriority,
    selectedDimension,
    setSelectedDimension,
    setSelectedPriority,
    loading: loading || dimensionsLoading,
    error: error || dimensionsError,
  };
}

import { put, take, takeLatest } from 'redux-saga/effects';

import { getLocalUserSettings } from '@/core/services/localUserSettings';
import { fetchModulesRequest, fetchModulesSuccess } from '@/store/modules/slices';
import {
  verifyUserDisclaimerRequest,
  verifyUserDisclaimerSuccess,
} from '@/store/userDisclaimer/slices';

import { appInitialized, appStarted } from './slices';

// Watchers
function* onAppStarted() {
  yield takeLatest(appStarted, handleAppStarted);
}

// Workers
export function* handleAppStarted() {
  const settings = getLocalUserSettings();

  if (!settings?.userDisclaimerConfirmedOn) {
    yield put(verifyUserDisclaimerRequest());
    yield take(verifyUserDisclaimerSuccess);
  }

  yield put(fetchModulesRequest());
  yield take(fetchModulesSuccess);

  yield put(appInitialized());
}

export default [onAppStarted];

export function Wrapped() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none">
      <path
        d="M44.3716 22.0097H42.9166V21.5703C42.9166 19.6173 41.7936 17.9377 39.9871 17.1956C38.1806 16.4436 36.208 16.844 34.8214 18.2209L32.1458 20.8965L29.4702 18.2209C28.0933 16.8342 26.111 16.4436 24.3143 17.1858C22.5077 17.9377 21.3848 19.6075 21.3848 21.5605V21.9999H19.6271C18.3771 21.9999 17.3518 23.0057 17.3518 24.2459V44.938C17.3518 46.1781 18.3771 47.1839 19.6271 47.1839H44.3716C45.6215 47.1839 46.6468 46.1781 46.6468 44.938V24.2557C46.6468 23.0155 45.6215 22.0097 44.3716 22.0097ZM36.208 19.5977C37.1748 18.631 38.4149 18.6505 39.245 18.9923C40.075 19.3341 40.9636 20.1934 40.9636 21.5605V21.9999H33.7961L36.208 19.588V19.5977ZM23.3378 21.5605C23.3378 20.1934 24.2264 19.3341 25.0564 18.9923C25.8864 18.6505 27.1168 18.6212 28.0933 19.5977L30.5053 22.0097H23.3378V21.5703V21.5605ZM44.811 44.9477C44.811 45.1821 44.606 45.3871 44.3716 45.3871H19.6271C19.3829 45.3871 19.1876 45.1918 19.1876 44.9477V24.2557C19.1876 24.0213 19.3927 23.8162 19.6271 23.8162H44.3716C44.6157 23.8162 44.811 24.0115 44.811 24.2557V44.9477Z"
        fill="white"
      />
      <path
        d="M37.9465 32.7803L29.5193 28.0053C28.0546 27.1948 26.2871 28.2396 26.2871 29.8704V39.3424C26.2871 41.022 28.1229 41.9595 29.5096 41.2173L37.9368 36.4715C39.3527 35.6513 39.3527 33.6006 37.9368 32.7803H37.9465ZM37.0189 34.8994L28.6209 39.6256C28.3866 39.7428 28.1132 39.5573 28.1132 39.3424V29.8704C28.1132 29.6165 28.3768 29.4602 28.6112 29.5872L37.0189 34.3525C37.2239 34.4697 37.2239 34.7822 37.0189 34.8994Z"
        fill="#48FF9B"
      />
    </svg>
  );
}

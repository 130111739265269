import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ExclamationCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <rect
      x="15.3125"
      y="15.8457"
      width="2.1875"
      height="7.9697"
      rx="1.09375"
      transform="rotate(-180 15.3125 15.8457)"
      fill="#F29A2E"
    />
    <ellipse
      cx="14.2188"
      cy="19.5234"
      rx="1.09375"
      ry="1.0936"
      transform="rotate(-180 14.2188 19.5234)"
      fill="#F29A2E"
    />
    <path
      d="M1.82037e-05 13.9858C0.0123693 6.25839 6.29909 -0.0123584 14.0185 1.81929e-05C21.738 0.0123948 28.0124 6.29549 28 14.0105C27.9877 21.7256 21.7009 27.9963 13.9815 27.9963C6.26204 27.984 -0.0123329 21.7009 1.82037e-05 13.9858ZM25.6162 14.0105C25.6285 7.60394 20.4164 2.39477 14.0062 2.38242C7.59596 2.37007 2.38379 7.57924 2.37143 13.9858C2.35908 20.3924 7.57126 25.6016 13.9815 25.6139C20.3917 25.6263 25.6162 20.4047 25.6162 14.0105Z"
      fill="#F29A2E"
    />
  </SvgIcon>
);

export default ExclamationCircleIcon;

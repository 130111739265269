import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PauseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <path d="M14.1127 19.6004C13.3584 19.6004 12.713 19.332 12.1763 18.7954C11.6387 18.2578 11.3699 17.6118 11.3699 16.8575V3.14325C11.3699 2.38896 11.6387 1.74348 12.1763 1.20679C12.713 0.66919 13.3584 0.400391 14.1127 0.400391H16.8556C17.6099 0.400391 18.2558 0.66919 18.7934 1.20679C19.3301 1.74348 19.5984 2.38896 19.5984 3.14325V16.8575C19.5984 17.6118 19.3301 18.2578 18.7934 18.7954C18.2558 19.332 17.6099 19.6004 16.8556 19.6004H14.1127ZM3.14129 19.6004C2.38701 19.6004 1.74152 19.332 1.20484 18.7954C0.667237 18.2578 0.398438 17.6118 0.398438 16.8575V3.14325C0.398438 2.38896 0.667237 1.74348 1.20484 1.20679C1.74152 0.66919 2.38701 0.400391 3.14129 0.400391H5.88415C6.63844 0.400391 7.28438 0.66919 7.82198 1.20679C8.35867 1.74348 8.62701 2.38896 8.62701 3.14325V16.8575C8.62701 17.6118 8.35867 18.2578 7.82198 18.7954C7.28438 19.332 6.63844 19.6004 5.88415 19.6004H3.14129Z" />
  </SvgIcon>
);

export default PauseIcon;

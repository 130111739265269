import { alpha, styled } from '@mui/material/styles';
import React from 'react';

export interface DividerProps {
  orientation?: 'horizontal' | 'vertical';
  flexItem?: boolean;
  height?: string | number;
}

const DividerRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'orientation' && prop !== 'flexItem' && prop !== 'height',
})<DividerProps>(({ theme, orientation = 'horizontal', flexItem = false, height = 'auto' }) => {
  const mainColor = theme.tokens.color['brand.green'];
  const transparentColor = alpha(mainColor, 0);
  const gradientDirection = orientation === 'horizontal' ? '90deg' : '0deg';

  return {
    width: orientation === 'horizontal' ? 'auto' : 1,
    height: orientation === 'horizontal' ? 1 : 'auto',
    border: 'none',
    background: `linear-gradient(${gradientDirection}, ${transparentColor} 0%, ${mainColor} 50%, ${transparentColor})`,
    boxShadow: `0 0 5px 0 ${alpha(mainColor, 0.7)}`,
    filter: `drop-shadow(0px 0px 10px ${mainColor})`,

    ...(flexItem &&
      orientation === 'vertical' && {
        height,
        alignSelf: 'stretch',
        flexShrink: 0, // Prevent shrinking
      }),
  };
});

export const Divider: React.FC<DividerProps> = (props) => <DividerRoot {...props} />;

import { useMarkStoryAsReadMutation } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { CardContent } from '@aily-labs/ui';
import {
  alpha,
  Box,
  Card,
  CardActionArea,
  styled,
  SvgIcon,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { upperCase } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { colors } from '../../../../theme/default/colors';
import { lineClamp } from '../../../../theme/utils';
import { mapSentimentToColor, parseCaption } from '../../../../utils';
import { AgentGlowBackground } from './AgentGlowBackground';

const CheckedIcon = (props: SvgIconProps) => (
  <SvgIcon width="11" height="9" viewBox="0 0 11 9" {...props}>
    <path d="M10.7579 0.695923H9.88392C9.76139 0.695923 9.64512 0.75297 9.5701 0.850584L4.41519 7.47184L1.94339 4.29622C1.90599 4.24808 1.85833 4.20916 1.80398 4.18238C1.74963 4.15559 1.69 4.14163 1.62957 4.14156H0.75562C0.671851 4.14156 0.625591 4.23917 0.676853 4.3051L4.10137 8.70405C4.26141 8.90942 4.56898 8.90942 4.73026 8.70405L10.8366 0.85819C10.8879 0.793537 10.8416 0.695923 10.7579 0.695923Z" />
  </SvgIcon>
);

const isValidSentiment = (value: any): value is T.Sentiment => {
  return Object.values(T.Sentiment).includes(value);
};

const TriangularCorner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disableXxl',
})<{ disableXxl?: boolean }>(({ theme, disableXxl }) => ({
  position: 'absolute',
  right: -1,
  bottom: -1,
  width: 42,
  height: 42,
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)',
  pointerEvents: 'none',
  color: theme.palette.text.primary,

  [theme.breakpoints.up('xl')]: {
    width: 46,
    height: 46,
  },

  ...(!disableXxl && {
    [theme.breakpoints.up('xxl')]: {
      width: 50,
      height: 50,
    },
  }),

  '& .MuiSvgIcon-root': {
    position: 'absolute',
    right: 8,
    bottom: 8,
    fontSize: 10,
    [theme.breakpoints.up('xl')]: {
      right: 9,
      bottom: 9,
      fontSize: 12,
    },
    [theme.breakpoints.up('xxl')]: {
      right: 10,
      bottom: 10,
      fontSize: 14,
    },
  },
}));

const RecommenderItemRoot = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'disableXxl',
})<{ disableXxl?: boolean }>(({ theme, disableXxl }) => ({
  minWidth: 151,
  width: 151,
  height: 151,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2.5, 2, 1.625, 1.625),

  [theme.breakpoints.up('xl')]: {
    width: 170,
    height: 170,
  },

  ...(!disableXxl && {
    [theme.breakpoints.up('xxl')]: {
      width: 220,
      height: 220,
    },
  }),
}));

const StyledBoxCardActionArea = styled(CardActionArea)(({ theme }) => ({
  minWidth: 151,
  width: 151,
  height: 151,
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('xl')]: {
    width: 170,
    height: 170,
  },
  [theme.breakpoints.up('xxl')]: {
    width: 220,
    height: 220,
  },
}));

export interface AgentRecommenderItemProps {
  story: T.Story;
  isWideCard?: boolean;
  onPress?: () => void;
  disableXxl?: boolean;
}

export const AgentRecommenderItem: React.FC<AgentRecommenderItemProps> = ({
  story,
  isWideCard = true,
  onPress,
  disableXxl,
}) => {
  const agent = parseCaption(story?.caption || '{}');
  const cardInfo = story.decision ? agent?.imp_agent : agent?.opt_agent;
  const [markStoryAsRead] = useMarkStoryAsReadMutation();

  const sentimentGradientColors = getGradientColors(
    (upperCase(agent?.tags?.sentiment) as T.Sentiment) ?? T.Sentiment.Neutral,
  );

  const handleOnClick = useCallback(() => {
    onPress?.();
    markStoryAsRead({ variables: { input: { storyId: story.id } } });
  }, [markStoryAsRead]);

  const title = useMemo(() => {
    if (story.decision?.title && story.decision?.title.length > 0) {
      // temporary fix because BE doesn't format invalid values properly
      return story.decision.title === 'null' ? '' : story.decision.title;
    }

    return agent.title;
  }, [story.decision, agent.title]);

  const sentiment = upperCase(agent?.tags?.sentiment);
  const color = isValidSentiment(sentiment)
    ? mapSentimentToColor(sentiment as T.Sentiment)
    : mapSentimentToColor(T.Sentiment.Neutral);
  return (
    <>
      {isWideCard ? (
        <CardContent
          testID="test-id-feed-card-agent-content"
          pressable
          onPress={handleOnClick}
          type="trend"
          text={cardInfo?.name ?? title}
          secondaryText={cardInfo?.agent_type ? cardInfo?.agent_type : ''}
          background="AGENT"
          value={
            cardInfo?.impact?.text && cardInfo?.impact?.text !== 'null'
              ? cardInfo?.impact?.text
              : ''
          }
          secondValue={cardInfo?.modules?.join(' • ')}
          sentiment={cardInfo?.impact?.sentiment?.toUpperCase() ?? 'NEUTRAL'}
        />
      ) : (
        <StyledBoxCardActionArea onClick={handleOnClick}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              background: `linear-gradient(180deg, ${sentimentGradientColors[0]}, ${sentimentGradientColors[1]})`,
              opacity: 0.2,
              position: 'absolute',
              borderRadius: 3,
            }}
          />
          <RecommenderItemRoot
            disableXxl={disableXxl}
            data-testid="test-id-feed-card-agent-content"
          >
            <Typography
              variant="bodyBoldTight"
              sx={{
                ...lineClamp(3),
                typography: { xs: 'bodyBoldTight', xl: 'h9', xxl: !disableXxl ? 'h7' : undefined },
              }}
            >
              {cardInfo?.name ?? title}
            </Typography>

            <AgentGlowBackground />
          </RecommenderItemRoot>
          {story.isRead && (
            <TriangularCorner
              sx={{
                background: alpha(color, 0.4),
                borderRadius: '0 0 14px 0',
              }}
            >
              <CheckedIcon
                sx={{ fontSize: { xs: 10, xl: 12, xxl: 15 } }}
                data-testid="checked-icon"
              />
            </TriangularCorner>
          )}
        </StyledBoxCardActionArea>
      )}
    </>
  );
};

function getGradientColors(sentiment: T.Sentiment) {
  switch (sentiment) {
    case T.Sentiment.Positive:
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.VeryPositive:
      return [colors.gradients.green[0], colors.gradients.green[1]];
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
    case T.Sentiment.SlightlyNegative:
      return [colors.gradients.pink[0], colors.gradients.pink[1]];
    case T.Sentiment.Neutral:
    default:
      return [colors.neutrals.black, colors.primary.aqua];
  }
}

import { styled } from '@mui/material';
import React from 'react';

import { Typography } from '../../../../../../Typography';
import { H4Dual } from '../H4Dual';

//This name (PopupSentimentTitle) makes no sense for me at all as well, maybe legacy from the old implementation?
//Ask for review

export interface PopupSentimentTitleProps {
  title: string;
  subtitle: string;
  description?: string;
  /**
   * List of example items. A maximum of 5 items will be displayed.
   */
  examples?: string[];
  sentiment?: 'positive' | 'negative';
}

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflow: 'hidden',
  gap: theme.tokens.spacing.large,
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xxsmall,
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
}));

const Examples = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xxsmall,
  width: '100%',
  maxWidth: '100%',
}));

export const PopupSentimentTitle: React.FC<PopupSentimentTitleProps> = ({
  title,
  subtitle,
  description,
  examples = [],
  sentiment = 'positive',
}) => {
  const validExamples = examples.slice(0, 5);

  return (
    <Container>
      <ContentWrapper>
        <H4Dual value={title} sentiment={sentiment} />
        <Typography
          variant="heading.H8"
          color="text.regular"
          lineClamp={2}
          sx={{ width: '100%', maxWidth: '100%' }}
        >
          {subtitle}
        </Typography>
        {description && (
          <Typography variant="body.regular" color="text.subtle">
            {description}
          </Typography>
        )}
        {validExamples.length > 0 && (
          <Examples>
            {validExamples.map((example, index) => (
              <Typography
                key={`${index}-${example}`}
                variant="body.small"
                color="text.subtle"
                lineClamp={2}
                sx={{ width: '100%', maxWidth: '100%' }}
              >
                {example}
              </Typography>
            ))}
          </Examples>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default PopupSentimentTitle;

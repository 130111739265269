import { Stack, styled } from '@mui/material';
import React from 'react';

import { Typography } from '../../../../../../Typography';
import { ProgressBar } from '../../../../../commons/utils/ProgressBar';

export interface TitleBarHorizontalRowProps {
  title: string;
  value: string;
  percentage: number;
}

const TitleBarHorizontalRowRoot = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['4'],
  flexDirection: 'row',
  alignItems: 'center',
  width: 278,
}));

const TitleValueStack = styled(Stack)(() => ({
  width: 200,
  flexShrink: 0,
}));

const ProgressBarStack = styled(Stack)(() => ({
  flex: 1,
}));

export const TitleBarHorizontalRow: React.FC<TitleBarHorizontalRowProps> = ({
  title,
  value,
  percentage,
}) => {
  return (
    <TitleBarHorizontalRowRoot>
      <TitleValueStack>
        <Typography variant="body.regular-condensed" lineClamp={1}>
          {title}
        </Typography>
        <Typography variant="body.regular-condensed" color="text.success">
          {value}
        </Typography>
      </TitleValueStack>
      <ProgressBarStack>
        <ProgressBar percentage={percentage} />
      </ProgressBarStack>
    </TitleBarHorizontalRowRoot>
  );
};

import { useId } from 'react';

export function Pro() {
  const id = useId();
  const clip0Id = `${id}-clip-0`;

  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none">
      <g clipPath={`url(#${clip0Id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.1429 24.6857C31.0995 24.6857 32.6857 23.0995 32.6857 21.1429C32.6857 19.1862 31.0995 17.6 29.1429 17.6C27.1862 17.6 25.6 19.1862 25.6 21.1429C25.6 23.0995 27.1862 24.6857 29.1429 24.6857ZM32.3731 25.1449C33.5397 24.2021 34.2857 22.7596 34.2857 21.1429C34.2857 18.3025 31.9832 16 29.1429 16C26.3025 16 24 18.3025 24 21.1429C24 22.9616 24.9441 24.5599 26.369 25.4743L23.4137 31.3848C22.7289 31.0472 21.958 30.8576 21.1429 30.8576C18.3025 30.8576 16 33.1601 16 36.0004C16 38.8407 18.3025 41.1433 21.1429 41.1433C23.0656 41.1433 24.7419 40.0881 25.6243 38.5253L38.0426 42.4766L38.5277 40.9519L26.1838 37.0243C26.2506 36.6934 26.2857 36.351 26.2857 36.0004C26.2857 34.5578 25.6917 33.2539 24.735 32.32L27.8359 26.1182C28.2532 26.2275 28.6912 26.2857 29.1429 26.2857C29.7926 26.2857 30.4143 26.1652 30.9866 25.9453L39.3214 39.281L40.6782 38.433L32.3731 25.1449ZM21.1429 39.5433C23.0995 39.5433 24.6857 37.9571 24.6857 36.0004C24.6857 34.0438 23.0995 32.4576 21.1429 32.4576C19.1862 32.4576 17.6 34.0438 17.6 36.0004C17.6 37.9571 19.1862 39.5433 21.1429 39.5433Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8579 46.4002C44.8146 46.4002 46.4008 44.814 46.4008 42.8573C46.4008 40.9007 44.8146 39.3145 42.8579 39.3145C40.9013 39.3145 39.3151 40.9007 39.3151 42.8573C39.3151 44.814 40.9013 46.4002 42.8579 46.4002ZM42.8579 48.0002C45.6983 48.0002 48.0008 45.6977 48.0008 42.8573C48.0008 40.017 45.6983 37.7145 42.8579 37.7145C40.0176 37.7145 37.7151 40.017 37.7151 42.8573C37.7151 45.6977 40.0176 48.0002 42.8579 48.0002Z"
          fill="#48FF9B"
        />
      </g>
      <defs>
        <clipPath id={clip0Id}>
          <rect width="32" height="32" fill="white" transform="translate(16 16)" />
        </clipPath>
      </defs>
    </svg>
  );
}

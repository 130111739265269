import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ChevronUpIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M7.648 6.15 4.611 9.137a.5.5 0 0 0-.006.709.5.5 0 0 0 .707.006L8 7.207l2.687 2.645a.5.5 0 0 0 .708-.006.5.5 0 0 0-.006-.71L8.349 6.15a.5.5 0 0 0-.7 0z" />
  </SvgIcon>
);

export default ChevronUpIcon;

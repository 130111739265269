import { ColorTokenKey } from '@aily/ui-theme';
import { styled, useTheme } from '@mui/material/styles';
import { FC, memo } from 'react';

import { CircularProgressBar, Stack, Typography } from '../../../../components';

export interface MyPrioritiesRowPulseProps {
  /**
   * Progress value between 0 and 100.
   */
  progressValue: number;
  /**
   * Main value displayed near the progress.
   */
  value: string;
  /**
   * Sentiment for the main value (affects its color).
   */
  valueSentiment: MyPrioritiesRowPulseValueSentiment;
  /**
   * Secondary value in "{value} / {total}" format.
   */
  total?: string;
  /**
   * Rank indicator shown on the right.
   */
  rank?: string;
  /**
   * Sentiment for the rank indicator.
   */
  rankSentiment?: MyPrioritiesRowPulseRankSentiment;
}

export type MyPrioritiesRowPulseValueSentiment = 'positive' | 'negative' | 'warning';

export type MyPrioritiesRowPulseRankSentiment = 'positive' | 'negative' | 'neutral';

const sentimentValueColorMap: Record<MyPrioritiesRowPulseValueSentiment, ColorTokenKey> = {
  positive: 'text.success',
  negative: 'text.danger',
  warning: 'text.warning',
};

const sentimentProgressColorMap: Record<MyPrioritiesRowPulseValueSentiment, ColorTokenKey> = {
  positive: 'gradient.mint.vertical',
  negative: 'gradient.pink.vertical',
  warning: 'gradient.orange.vertical',
};

const sentimentRankColorMap: Record<MyPrioritiesRowPulseRankSentiment, ColorTokenKey> = {
  positive: 'text.success',
  negative: 'text.danger',
  neutral: 'text.regular',
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.tokens.spacing['medium'],
}));

const MyPrioritiesRowPulse: FC<MyPrioritiesRowPulseProps> = ({
  progressValue,
  value,
  valueSentiment,
  total,
  rank,
  rankSentiment = 'neutral',
}) => {
  const theme = useTheme();
  return (
    <Root>
      <Stack direction="row" alignItems="center" spacing="xxsmall" sx={{ minWidth: 0 }}>
        <CircularProgressBar
          value={progressValue / 100}
          size={16}
          strokeWidth={3}
          trackColor={theme.tokens.color['neutral.grey']}
          progressColor={theme.tokens.color[sentimentProgressColorMap[valueSentiment]]}
        />
        <Typography
          variant="body.regular-condensed"
          color={sentimentValueColorMap[valueSentiment]}
          lineClamp={1}
        >
          {value}
          {total && (
            <Typography
              variant="inherit"
              color="text.regular"
              lineClamp={1}
              sx={{ display: 'inline' }}
            >
              {` / ${total}`}
            </Typography>
          )}
        </Typography>
      </Stack>
      {rank && (
        <Typography
          variant="body.regular-condensed"
          color={sentimentRankColorMap[rankSentiment]}
          align="right"
          lineClamp={1}
          sx={{ minWidth: '3em' }}
        >
          {rank}
        </Typography>
      )}
    </Root>
  );
};

export default memo(MyPrioritiesRowPulse);

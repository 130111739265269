import { Box, styled } from '@mui/material';

import { ButtonBase } from '../../buttons';
import { CloseIcon } from '../../icons';
import { useBanner } from '../context';

const CloseButtonContainer = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
});

export function CloseButton({ onPress }: { onPress?: () => void }) {
  const { type } = useBanner();
  const iconColor = type === 'grey' ? 'neutral.white' : 'neutral.black';

  return (
    <CloseButtonContainer>
      <ButtonBase
        onClick={(event) => {
          event.stopPropagation();
          onPress && onPress();
        }}
      >
        <CloseIcon size="icon.medium" color={iconColor} />
      </ButtonBase>
    </CloseButtonContainer>
  );
}

import {
  Backdrop,
  Box,
  BoxProps,
  ButtonProps,
  Fade,
  FadeProps,
  IconButton,
  Modal,
  ModalProps,
  Portal,
  styled,
} from '@mui/material';
import React from 'react';

import { Close } from '../../icons';

export interface ClosableModalProps extends ModalProps {
  onClose?: () => void;
  ContentBackgroundProps?: BoxProps;
  ContentContainerProps?: BoxProps;
  CloseButtonProps?: ButtonProps;
  FadeProps?: Partial<FadeProps>;
}

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const ContentBackground = styled(Box)({
  position: 'absolute',
  zIndex: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

const ContentContainer = styled(Box)({
  position: 'relative',
  alignSelf: 'start',
  margin: '0 auto',
  width: '100%',
  maxWidth: 1360,
  outline: 'none',
});

export const CloseButton = styled((props: ButtonProps) => (
  <IconButton {...props}>
    <Close />
  </IconButton>
))(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  top: 40,
  right: 10,
  color: theme.palette.text.secondary,
  textTransform: 'none',
  ...theme.typography.body,
  '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
    color: theme.palette.text.primary,
    background: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
}));

export const ClosableModal: React.FC<ClosableModalProps> = ({
  open,
  onClose,
  ContentBackgroundProps,
  ContentContainerProps,
  CloseButtonProps,
  FadeProps,
  children,
  ...rest
}) => (
  <Portal>
    <Modal open={open} slots={{ backdrop: StyledBackdrop }} onClose={onClose} {...rest}>
      <Fade in={open} {...FadeProps}>
        <ContentBackground {...ContentBackgroundProps}>
          <ContentContainer {...ContentContainerProps}>
            {!!onClose && (
              <CloseButton onClick={onClose} data-testid="close-button" {...CloseButtonProps} />
            )}
            {children}
          </ContentContainer>
        </ContentBackground>
      </Fade>
    </Modal>
  </Portal>
);

export function Notification() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none">
      <path
        d="M31.3316 48C30.4833 48 29.7594 47.7247 29.1596 47.174C28.5599 46.6234 28.2601 45.9615 28.2601 45.1883H34.416C34.416 45.9671 34.114 46.6304 33.51 47.1782C32.906 47.7261 32.1798 48 31.3316 48ZM18 43.4477V41.7071H21.078V27.9163C21.078 25.6424 21.8658 23.6424 23.4415 21.9163C25.0171 20.1902 27.0142 19.1152 29.4326 18.6912V17.7406C29.4326 17.2571 29.6174 16.8461 29.9871 16.5076C30.3568 16.1692 30.8056 16 31.3338 16C31.8619 16 32.3122 16.1692 32.6847 16.5076C33.0572 16.8461 33.2435 17.2571 33.2435 17.7406V17.8711C32.9675 18.2639 32.7256 18.66 32.5179 19.0594C32.3103 19.4589 32.1491 19.884 32.0343 20.3348C31.9183 20.3214 31.8051 20.3096 31.6948 20.2996C31.5846 20.2896 31.4657 20.2845 31.3381 20.2845C29.0222 20.2845 27.0508 21.0276 25.4239 22.5138C23.7969 24 22.9834 25.8008 22.9834 27.9163V41.7071H39.6927V31.1263C39.9956 31.1799 40.3076 31.2217 40.6289 31.2519C40.9501 31.282 41.2732 31.2859 41.5981 31.2636V41.7071H44.6761V43.4477H18ZM40.7963 27.1464C39.4742 27.1464 38.3491 26.7238 37.4208 25.8784C36.4925 25.033 36.0284 24.0065 36.0284 22.7989C36.0284 21.5913 36.4911 20.5634 37.4165 19.7155C38.3419 18.8675 39.4656 18.4435 40.7876 18.4435C42.1097 18.4435 43.2348 18.8662 44.1631 19.7115C45.0914 20.5569 45.5556 21.5834 45.5556 22.791C45.5556 23.9987 45.0929 25.0265 44.1674 25.8745C43.242 26.7225 42.1183 27.1464 40.7963 27.1464Z"
        fill="white"
      />
    </svg>
  );
}

import {
  AdditionalFilterOptions,
  ProHomeFeedFilterInput,
  Story,
  UserAdditionalFilters,
} from '@aily/graphql-sdk/schema';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { FilterRowButtonType } from '../hooks';

interface ProFiltersContextState {
  feedItems?: Story[];
  userAdditionalFilters?: UserAdditionalFilters[];
  selectedFilters: FilterRowButtonType[];
  newSelectedFilter: ProHomeFeedFilterInput | undefined;
  feedFilterOptions?: AdditionalFilterOptions[];
  customsFilters: UserAdditionalFilters[];
  updateFeedItems: (feedItems: Story[]) => void;
  updateCustomsFilters: (customsFilters: UserAdditionalFilters[]) => void;
  updateNewSelectedFilter: (newSelectedFilter: ProHomeFeedFilterInput | undefined) => void;
  updateFeedFilterOptions: (feedFilterOptions: AdditionalFilterOptions[]) => void;
  updateUserAdditionalFilters: (userAdditionalFilters: UserAdditionalFilters[]) => void;
  removeSelectedFilter: () => void;
  resetNewSelectedFilter: () => void;
  addSelectedFilter: (filter: FilterRowButtonType, option?: FilterRowButtonType) => void;
}

export const ProFiltersContext = createContext<ProFiltersContextState>({
  feedItems: [],
  feedFilterOptions: [],
  userAdditionalFilters: [],
  selectedFilters: [],
  newSelectedFilter: undefined,
  customsFilters: [],
  updateFeedItems: () => {},
  updateNewSelectedFilter: () => {},
  updateCustomsFilters: () => {},
  updateFeedFilterOptions: () => {},
  updateUserAdditionalFilters: () => {},
  removeSelectedFilter: () => {},
  resetNewSelectedFilter: () => {},
  addSelectedFilter: () => {},
});

export const ProFiltersProvider = ({ children }: { children: React.ReactNode }) => {
  const [feedItems, setFeedItems] = useState<Story[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<FilterRowButtonType[]>([]);
  const [newSelectedFilter, setNewSelectedFilter] = useState<ProHomeFeedFilterInput | undefined>();
  const [customsFilters, setCustomsFilters] = useState<UserAdditionalFilters[]>([]);
  const [userAdditionalFilters, setUserAdditionalFilters] = useState<UserAdditionalFilters[]>([]);
  const [feedFilterOptions, setFeedFilterOptions] = useState<AdditionalFilterOptions[]>([]);

  const updateFeedItems = (data: Story[]) => {
    setFeedItems(data);
  };

  const updateUserAdditionalFilters = useCallback(
    (filters: UserAdditionalFilters[]) => {
      setUserAdditionalFilters(filters);
    },
    [setUserAdditionalFilters],
  );

  const updateNewSelectedFilter = useCallback(
    (filter: ProHomeFeedFilterInput | undefined) => {
      setNewSelectedFilter(filter);
    },
    [setNewSelectedFilter],
  );

  const resetNewSelectedFilter = useCallback(() => {
    setNewSelectedFilter(undefined);
  }, [setNewSelectedFilter]);

  const updateFeedFilterOptions = (options: AdditionalFilterOptions[]) => {
    setFeedFilterOptions(options);
  };

  const addSelectedFilter = (
    filter: FilterRowButtonType,
    option?: FilterRowButtonType | undefined,
  ) => {
    const arrFilters = [filter];
    if (option) {
      arrFilters.push(option);
    }
    setSelectedFilters(arrFilters);
  };

  const updateCustomsFilters = (filters: UserAdditionalFilters[]) => {
    setCustomsFilters(filters);
  };

  const removeSelectedFilter = useCallback(() => {
    setSelectedFilters([]);
    setNewSelectedFilter(undefined);
  }, [setSelectedFilters, setNewSelectedFilter]);

  const contextValue = useMemo(
    () => ({
      feedItems,
      feedFilterOptions,
      userAdditionalFilters,
      newSelectedFilter,
      selectedFilters,
      customsFilters,
      updateFeedItems,
      updateCustomsFilters,
      updateNewSelectedFilter,
      updateFeedFilterOptions,
      updateUserAdditionalFilters,
      removeSelectedFilter,
      resetNewSelectedFilter,
      addSelectedFilter,
    }),
    [
      feedItems,
      feedFilterOptions,
      userAdditionalFilters,
      selectedFilters,
      customsFilters,
      newSelectedFilter,
      updateFeedItems,
      updateCustomsFilters,
      updateNewSelectedFilter,
      updateFeedFilterOptions,
      updateUserAdditionalFilters,
      removeSelectedFilter,
      resetNewSelectedFilter,
      addSelectedFilter,
    ],
  );

  return <ProFiltersContext.Provider value={contextValue}>{children}</ProFiltersContext.Provider>;
};

export function useProFiltersContext(): ProFiltersContextState | undefined {
  return useContext(ProFiltersContext);
}

import { styled } from '@mui/material';
import React from 'react';

import { Stack } from '../../../../Stack';
import { Typography } from '../../../../Typography';

export interface MessageInsightTemplateProps {
  sentiment: 'positive' | 'negative';
  sentimentText: string;
  text: string;
}

const ParagraphContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.tokens.spacing['medium'],
}));

export const MessageInsightTemplate: React.FC<MessageInsightTemplateProps> = ({
  sentiment,
  sentimentText,
  text,
}) => {
  return (
    <ParagraphContainer>
      <Typography variant="heading.H9">{text}</Typography>
      <Typography
        variant="heading.H9"
        color={sentiment === 'positive' ? 'text.success' : 'text.danger'}
      >
        {sentimentText}
      </Typography>
    </ParagraphContainer>
  );
};

import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PlayIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <path d="M19.4373 9.23016L2.93149 0.542891C1.59967 -0.158067 0 0.807706 0 2.31273V19.6873C0 21.1923 1.59967 22.1581 2.93149 21.4571L19.4373 12.7698C20.862 12.02 20.862 9.97999 19.4373 9.23016Z" />
  </SvgIcon>
);

export default PlayIcon;

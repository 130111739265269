import { useAuth } from '@aily/auth-service';
import { captureWarning } from '@aily/monitoring-service';
import { useEffect } from 'react';

export function useCaptureAuthError() {
  const { error } = useAuth();

  useEffect(() => {
    if (error) {
      console.error(`[Auth error]:`, error);

      captureWarning(`[Auth error]: ${error.message}`);
    }
  }, [error]);
}

export const greenGradientColors = {
  start: '#2B995C',
  end: '#48FF9B',
} as const;

export const greenGradientStyle = 'linear-gradient(180deg, #2B995C 0%, #48FF9B 100%)';

export function GreenGradient() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: greenGradientStyle,
      }}
    />
  );
}

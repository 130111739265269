import * as T from '@aily/graphql-sdk/schema';
import { Alert, Toolbar } from '@mui/material';
import { find } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';

import { LinkContextProvider } from '../../contexts';
import { usePriorityFilter } from '../../providers';
import { BackButton } from '../BackButton';
import { Props as PageContentProps } from '../PageContent/PageContent';
import PageContentContainer from '../PageContent/PageContentContainer';
import { TableColumnProps } from '../Table';
import { TableCellMicroChart } from '../TableCellContent';
import { TableDataViewProps } from '../TableDataView';
import TableSwitcherComponent from './TableSwitcher';

interface TableSwitcherWithPageContentProps extends Pick<PageContentProps, 'onComponentRender'> {
  component: T.TableSwitcherComponent;
  TableDataViewProps: Partial<TableDataViewProps>;
  storySourceModuleCode?: string;
}

const TableSwitcherWithPageContent: React.FC<TableSwitcherWithPageContentProps> = ({
  component,
  TableDataViewProps,
  onComponentRender,
  storySourceModuleCode,
}) => {
  const { priorityFilter } = usePriorityFilter() ?? {};
  const [link, setLink] = useState<T.Link>();

  const handleMicroChartClick = useCallback(
    ({ linkResult }: T.MicroChartResult) =>
      () => {
        setLink(linkResult ?? undefined);
        return;
      },
    [],
  );

  const handleBackButtonClick = useCallback(() => {
    setLink(undefined);
  }, []);

  const handleCellRender = useCallback(
    (row: T.TableRowResult, column: TableColumnProps) => {
      const { cellContent } = find(row.cells, { columnDataKey: column.dataKey }) ?? {};

      if (T.isMicroChartResult(cellContent)) {
        return (
          <TableCellMicroChart
            microChart={cellContent}
            onClick={handleMicroChartClick(cellContent)}
          />
        );
      }

      return undefined;
    },
    [handleMicroChartClick],
  );

  const priorityId = useMemo(() => {
    const linkPriorityFilter = component.tables?.[0].filters?.find(
      (f) => f.code === priorityFilter?.filterCode,
    );

    return linkPriorityFilter?.defaultValue ?? undefined;
  }, [component.tables, priorityFilter]);

  if (link) {
    if (!link.pageId) {
      return (
        <Alert variant="outlined" severity="error">
          Page not found
        </Alert>
      );
    }

    return (
      <LinkContextProvider link={link}>
        <Toolbar>
          <BackButton onClick={handleBackButtonClick} />
        </Toolbar>
        <PageContentContainer
          priorityId={priorityId}
          pageId={link.pageId}
          onComponentRender={onComponentRender}
        />
      </LinkContextProvider>
    );
  }

  return (
    <TableSwitcherComponent
      component={component}
      storySourceModuleCode={storySourceModuleCode}
      TableDataViewProps={{
        ...TableDataViewProps,
        onCellRender: handleCellRender,
        ContainerProps: { sx: { height: '100%' } },
      }}
    />
  );
};

export default TableSwitcherWithPageContent;

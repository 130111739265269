import * as T from '@aily/graphql-sdk/schema';
import { ModuleCode } from '@aily/graphql-sdk/schema';
import {
  findPageDepthFirst,
  ModuleProvider,
  PageMenu,
  PageMenuProps,
  useNavigateSearch,
} from '@aily/saas-core';
import { ProfilePageMenuItem } from '@aily/saas-core/features/Profile';
import { filter, kebabCase } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { MenuIcon } from '@/components/MenuIcon';
import ModuleSelectPageMenuItem from '@/components/ModuleSelect/ModuleSelectPageMenuItem';
import { isProfilePageEnabled } from '@/config/profile';
import { useCurrentModule } from '@/hooks/useCurrentModule';
import { getModules } from '@/store/modules/slices';

export const PageMenuContainer: React.FC<Omit<PageMenuProps, 'children'>> = ({ expanded }) => {
  const modules = useSelector(getModules);
  const currentModule = useCurrentModule();
  const { pathname, search } = useLocation();
  const navigateSearch = useNavigateSearch();
  const pages = useMemo(
    () => filter(currentModule?.pages, { isMenuHidden: false }),
    [currentModule],
  );

  const handlePageMenuItemClick = useCallback(
    (path: string) => () => {
      const isPriorityPage = path.includes('priorities');

      navigateSearch(path, isPriorityPage ? search : '');
    },
    [navigateSearch, search],
  );

  const handleModuleSelect = useCallback(
    (module: T.Module) => {
      if (currentModule?.id === module.id) {
        return;
      }

      if (
        currentModule?.moduleCode === ModuleCode.Ppl ||
        currentModule?.moduleCode === ModuleCode.Ebi
      ) {
        navigateSearch(`/${module.path}`);
        return;
      }

      // Redirect to page with same path when switching module
      if (currentModule && pathname.startsWith(`/${currentModule.path}`) && module.pages) {
        const newPathname = `/${module.path}` + pathname.slice(currentModule.path.length + 1);
        const { page: foundPage } = findPageDepthFirst(module.pages, newPathname);
        if (foundPage) {
          navigateSearch(foundPage.absolutePath);
          return;
        }
      }

      navigateSearch(`/${module.path}`);
    },
    [currentModule, pathname, navigateSearch],
  );

  return (
    <ModuleProvider value={currentModule ?? null}>
      <PageMenu expanded={expanded}>
        <>
          {pages
            .filter(({ pageType }) => pageType !== T.PageTypeEnum.SettingsPage)
            .map((page) => {
              // Match this page and its subpages
              const selected = !!matchPath(`${page.absolutePath}/*`, pathname);
              return (
                <PageMenu.Item
                  key={page.id}
                  title={page.menuTitle ?? page.title}
                  icon={page.menuIcon && <MenuIcon menuIcon={page.menuIcon} active={selected} />}
                  selected={selected}
                  onClick={handlePageMenuItemClick(page.absolutePath)}
                  data-x-path={kebabCase(page.path)}
                />
              );
            })}
        </>
        <ModuleSelectPageMenuItem
          title="Apps"
          icon={<MenuIcon menuIcon="SEARCH_OUTLINED" />}
          parentExpanded={expanded}
          modules={modules}
          currentModule={currentModule}
          onSelect={handleModuleSelect}
        />
        <>
          {pages
            .filter(({ pageType }) => pageType === T.PageTypeEnum.SettingsPage)
            .map((page) => {
              // Match this page and its subpages
              const selected = !!matchPath(`${page.absolutePath}/*`, pathname);
              return (
                <PageMenu.Item
                  key={page.id}
                  title={page.menuTitle ?? page.title}
                  icon={page.menuIcon && <MenuIcon menuIcon={page.menuIcon} active={selected} />}
                  selected={selected}
                  onClick={handlePageMenuItemClick(page.absolutePath)}
                  data-x-path={kebabCase(page.path)}
                />
              );
            })}
        </>
        <>{currentModule && isProfilePageEnabled(currentModule) && <ProfilePageMenuItem />}</>
      </PageMenu>
    </ModuleProvider>
  );
};

export default PageMenuContainer;

import { Box, styled } from '@mui/material';
import type { ReactNode } from 'react';

import { useBanner } from '../context';
import { GreenGradient } from './GreenGradient';

const BannerRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type?: 'green' | 'grey' }>(({ theme, type }) => ({
  backgroundColor: type === 'grey' ? '#2D2D2D' : 'transparent',
  position: 'relative',
  padding: theme.spacing(theme.tokens.spacing['xlarge'], theme.tokens.spacing['none']),
  display: 'flex',
  alignItems: 'center',
}));

export function Container({ children }: { children: ReactNode }) {
  const { type } = useBanner();

  return (
    <BannerRoot type={type}>
      {type === 'green' && <GreenGradient />}
      {children}
    </BannerRoot>
  );
}

import { styled } from '@mui/material/styles';
import React from 'react';

import { Typography } from '../../../../../Typography';

type SentimentVariantProps = {
  variant: 'sentiment';
  title: string;
  sentimentSubtitleValue: string;
  sentimentSubtitleText: string;
  subtitleSentiment: 'positive' | 'negative';
};

type DefaultVariantProps = {
  variant: 'default';
  title: string;
  subtitle?: string;
};

type CenteredVariantProps = {
  variant: 'centered';
  title: string;
};

type AgentCardTitleProps = SentimentVariantProps | DefaultVariantProps | CenteredVariantProps;

const TitleRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.tokens.spacing.medium,
}));

const SentimentSubtitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.tokens.spacing['4'],
  alignItems: 'center',
}));

const CenteredTitleRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.tokens.spacing.medium,
}));

export const AgentCardTitle: React.FC<AgentCardTitleProps> = (props) => {
  switch (props.variant) {
    case 'sentiment':
      return (
        <TitleRoot>
          <Typography variant="heading.H8">{props.title}</Typography>
          <SentimentSubtitleContainer>
            <Typography
              variant="heading.H8"
              color={props.subtitleSentiment === 'positive' ? 'text.success' : 'text.danger'}
            >
              {props.sentimentSubtitleValue}
            </Typography>
            <Typography
              variant="body.regular-condensed"
              color={props.subtitleSentiment === 'positive' ? 'text.success' : 'text.danger'}
            >
              {props.sentimentSubtitleText}
            </Typography>
            {/* MISSING REGULAR BOLD CONDENSED */}
          </SentimentSubtitleContainer>
        </TitleRoot>
      );

    case 'default':
      return (
        <TitleRoot>
          <Typography variant="heading.H8">{props.title}</Typography>
          {props.subtitle && (
            <Typography variant="body.regular-condensed" color="text.subtle">
              {props.subtitle}
            </Typography>
          )}
        </TitleRoot>
      );

    case 'centered':
      return (
        <CenteredTitleRoot>
          <Typography variant="heading.H8">{props.title}</Typography>
        </CenteredTitleRoot>
      );
  }
};

export default AgentCardTitle;

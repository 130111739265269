import { Typography } from '../../Typography';
import { useBanner } from '../context';

export function Title({ children }: { children: string }) {
  const { type } = useBanner();
  return (
    <Typography
      variant="heading.H7"
      color={type === 'grey' ? 'text.regular' : 'neutral.black'}
      lineClamp={2}
    >
      {children}
    </Typography>
  );
}

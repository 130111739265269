import * as T from '@aily/graphql-sdk/schema';
import { HStack, VStack } from '@aily-labs/ui';
import { Box, styled } from '@mui/material';
import React from 'react';

import { RealText, renderText } from '.';

const Card = styled(Box)({
  position: 'absolute',
  marginBottom: 50,
  backgroundColor: 'rgba(26, 26, 26, 0.50)',
  borderRadius: 24,
  backdropFilter: 'blur(22.5px)',
  padding: 24,
  width: 'max-content',
  textAlign: 'center',
});

export const OpeningScreen: React.FC<{
  screen?: T.RealScreen;
  pauseAudio: boolean;
  onAudioEnd: () => void;
  audio: React.ReactNode;
}> = ({ screen, audio }) => {
  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      paddingBottom="12%"
      marginLeft={90}
      minHeight={1080}
      maxWidth="100%"
    >
      <Card maxWidth="100%">
        <HStack flexWrap="wrap" flex={1} gap={5} justifyContent="center" maxWidth="100%">
          {screen?.content?.items?.map((content, index) => (
            <HStack alignItems="center" maxWidth="100%">
              {(content?.text as T.FormattedTextResult[]).map((item) =>
                renderText(
                  { ...item, fullWidth: true, boldText: true, isHeader: true } as RealText,
                  index,
                ),
              )}
            </HStack>
          ))}
        </HStack>
        {audio}
      </Card>
      <Agent />
    </VStack>
  );
};

const Agent = () => (
  <svg
    width="510"
    height="510"
    viewBox="0 0 510 510"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_23766_159184)">
      <circle cx="255" cy="255" r="192" fill="black" />
    </g>
    <g filter="url(#filter1_f_23766_159184)">
      <ellipse cx="257" cy="232.5" rx="157" ry="151.5" fill="#48FF9B" />
    </g>
    <g filter="url(#filter2_f_23766_159184)">
      <ellipse cx="299" cy="192.5" rx="80" ry="78.5" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_f_23766_159184"
        x="0.5"
        y="0.5"
        width="509"
        height="509"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="31.25" result="effect1_foregroundBlur_23766_159184" />
      </filter>
      <filter
        id="filter1_f_23766_159184"
        x="20"
        y="1"
        width="474"
        height="463"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="40" result="effect1_foregroundBlur_23766_159184" />
      </filter>
      <filter
        id="filter2_f_23766_159184"
        x="139"
        y="34"
        width="320"
        height="317"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="40" result="effect1_foregroundBlur_23766_159184" />
      </filter>
    </defs>
  </svg>
);

import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { TrendIndicator } from '../../../../../../TrendIndicator';
import { Typography } from '../../../../../../Typography';

type Sentiment = 'positive' | 'negative';
type TrendDirection = 'up' | 'down';

export interface LeaderboardRowTemplateProps {
  rank?: number;
  title: string;
  additionalInfo: string;
  value: string;
  valueSentiment: Sentiment;
  trendDirection: TrendDirection;
  trendSentiment: Sentiment;
}

const LeaderboardInsightsRowRoot = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['8'],
  flexDirection: 'row',
}));

const ContentStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const TitleRowStack = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const ValueContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.tokens.spacing['4'],
}));

const TrendIndicatorWrapper = styled(Box)({
  paddingTop: '2px',
});

const getSentimentColor = (sentiment: Sentiment) =>
  sentiment === 'positive' ? 'text.success' : 'text.danger';

export const LeaderboardRowTemplate: React.FC<LeaderboardRowTemplateProps> = ({
  rank,
  title,
  additionalInfo,
  value,
  trendDirection,
  trendSentiment,
  valueSentiment,
}) => {
  return (
    <LeaderboardInsightsRowRoot>
      {rank && (
        <Typography variant="heading.H9" color={'text.subtle'}>
          #{rank.toString().padStart(2, '0')}
        </Typography>
      )}
      <ContentStack>
        <TitleRowStack>
          <Typography variant="heading.H9" lineClamp={1}>
            {title}
          </Typography>
          <ValueContainer>
            <Typography variant="heading.H9" color={getSentimentColor(valueSentiment)}>
              {value}
            </Typography>
            <TrendIndicatorWrapper>
              <TrendIndicator direction={trendDirection} sentiment={trendSentiment} />
            </TrendIndicatorWrapper>
          </ValueContainer>
        </TitleRowStack>
        <Typography variant="body.regular-condensed" color="text.subtle" lineClamp={2}>
          {additionalInfo}
        </Typography>
      </ContentStack>
    </LeaderboardInsightsRowRoot>
  );
};

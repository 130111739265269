import { styled } from '@mui/material/styles';
import { motion, Transition } from 'framer-motion';
import React from 'react';

import { ButtonBase } from '../../../buttons';
import { Typography } from '../../../Typography';
import { useSegmentControl } from './useSegmentControl';

export interface SegmentControlProps<TValue> {
  defaultValue?: TValue;
  value?: TValue;
  onChange?: (newValue: TValue) => void;
  options?: SegmentControlOption<TValue>[];
  width?: number | string;
}

export interface SegmentControlOption<TValue> {
  value: TValue;
  label: string;
}

const Root = styled('div')<{ width: number | string }>(({ width }) => ({
  position: 'relative',
  width,
  borderRadius: 100,
  // In Figma, the background is defined using several layers of semi-transparent colors
  // with different blend modes, none of which token-based, I just picked the result color
  background: '#0c0c0c',
}));

const BackgroundSlider = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  padding: theme.tokens.spacing['xxsmall'],
}));

const ActiveSegment = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.tokens.color['background.light'],
  borderRadius: 20,
  border: '0.5px solid rgba(255, 255, 255, 0.1)',
  borderLeftColor: 'rgba(255, 255, 255, 0.3)',
  borderTopColor: 'rgba(255, 255, 255, 0.3)',
  backgroundBlendMode: 'color-dodge, lighten',
  backgroundImage:
    'linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06)',
}));

const SegmentList = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.tokens.spacing['xsmall'],
  height: 44,
  borderRadius: 44,
  padding: theme.tokens.spacing['xsmall'],
  boxShadow: '0 1px 0 0 rgba(255, 255, 255, 0.1)',
}));

const SegmentListItem = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  overflow: 'hidden',
  padding: theme.tokens.spacing['xsmall'],
  color: theme.tokens.color['text.disabled'],
  transition: 'color 150ms ease-in-out',

  ...(selected && {
    color: theme.tokens.color['text.regular'],
  }),
}));

const backgroundSliderTransition: Transition = { duration: 0.15, ease: 'circInOut' };

export const SegmentControl = <TValue,>({
  defaultValue,
  value,
  onChange,
  options = [],
  width = '100%',
}: SegmentControlProps<TValue>) => {
  const { currentIndex, handleChange } = useSegmentControl({
    defaultValue,
    value,
    onChange,
    options,
  });

  return (
    <Root width={width}>
      <BackgroundSlider
        style={{ width: `${100 / options.length}%` }}
        animate={{ x: `${currentIndex * 100}%` }}
        transition={backgroundSliderTransition}
      >
        <ActiveSegment />
      </BackgroundSlider>
      <SegmentList>
        {options?.map((option, index) => (
          <SegmentListItem
            key={index}
            selected={index === currentIndex}
            onClick={() => handleChange(option.value)}
            disableHoverStyle
          >
            <Typography variant="heading.H9" lineClamp={1}>
              {option.label}
            </Typography>
          </SegmentListItem>
        ))}
      </SegmentList>
    </Root>
  );
};

import type { BannerVariant } from './types';
import { Badge } from './variants/Badge';
import { KPIs } from './variants/KPIs';
import { Notification } from './variants/Notification';
import { Pro } from './variants/Pro';
import { Share } from './variants/Share';
import { WhatIf } from './variants/WhatIf';
import { Wrapped } from './variants/Wrapped';

export const requireBannerVariant = (variant: BannerVariant) => {
  switch (variant) {
    case 'badge':
      return Badge;
    case 'kpis':
      return KPIs;
    case 'notification':
      return Notification;
    case 'pro':
      return Pro;
    case 'share':
      return Share;
    case 'what-if':
      return WhatIf;
    case 'wrapped':
      return Wrapped;
    default:
      return variant satisfies never;
  }
};

import type { Operation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import type { GraphQLFormattedError } from 'graphql';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

interface MonitoringProps {
  dsn: string;
  environment: string;
  release: string;
  tenant: string;
  userEmail?: string;
  userId?: string;
}

function getUserEmailDomain(userEmail: string): string {
  const match = userEmail.match(/@([\w.-]+)/);
  return match ? match[1] : '';
}

function getSentryOptions(props: MonitoringProps): Sentry.BrowserOptions | undefined {
  const { dsn, environment, release } = props;

  if (dsn) {
    return {
      dsn,
      environment,
      release,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
          traceFetch: false,
        }),
      ],
      tracesSampleRate: 1,
    };
  }

  return undefined;
}

/**
 * Sets a custom tag for Sentry events.
 * @param key - The name of the tag.
 * @param value - The value of the tag.
 */

export function setSentryTag(key: string, value: string): void {
  Sentry.setTag(key, value);
}

/**
 * Initializes the monitoring service (Sentry).
 * @param props - Monitoring configuration properties.
 */
export function initMonitoringService(props: MonitoringProps) {
  const { tenant, userEmail, userId } = props;

  const sentryOptions = getSentryOptions(props);

  if (sentryOptions) {
    Sentry.init(sentryOptions);

    if (userId) {
      Sentry.setUser({ id: userId });
    }

    if (userEmail) {
      Sentry.setTag('emailDomain', getUserEmailDomain(userEmail));
    }

    if (tenant) {
      Sentry.setTag('tenant', tenant);
    }
  }
}

/**
 * Captures an exception and sends it to Sentry.
 * @param error - The error to capture.
 * @param hint - Optional additional data to attach to the Sentry event.
 */
export function captureException(error: unknown, hint?: Record<string, unknown>) {
  Sentry.captureException(error, hint);
}

/**
 * Captures a warning message and sends it to Sentry.
 * @param message - The warning message to capture.
 */
export function captureWarning(message: string) {
  Sentry.captureMessage(message, {
    level: 'warning',
  });
}

/**
 * Creates a Sentry Apollo Link for error reporting.
 * @param uri - The GraphQL endpoint URI.
 * @returns SentryLink instance.
 */
export function createSentryLink(uri: string): SentryLink {
  return new SentryLink({ uri });
}

/**
 * Reports GraphQL errors to Sentry.
 * @param graphQLErrors - Array of GraphQL errors.
 * @param operation - The GraphQL operation that caused the errors.
 */
export function reportGraphQLErrors(
  graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
  operation: Operation,
): void {
  graphQLErrors.forEach((graphQLError) => {
    console.error(`[GraphQL error]:`, graphQLError);

    Sentry.withScope((scope) => {
      scope.setTag('operationName', operation.operationName);
      scope.setExtra('query', operation.query.loc);
      scope.setExtra('variables', operation.variables);

      if (graphQLError.path) {
        scope.addBreadcrumb({
          category: 'queryPath',
          message: graphQLError.path.join('.'),
          level: 'debug',
        });
      }

      const error = new Error();
      error.name = '[GraphQL error]';
      error.message = (graphQLError.extensions?.message as string) ?? graphQLError.message;
      error.stack = graphQLError.extensions?.stackTrace as string;

      captureException(error);
    });
  });
}

/**
 * Reports Network errors to Sentry.
 * @param networkError - The network error object.
 */
export function reportNetworkError(networkError: Error): void {
  const error = new Error();
  error.name = '[Network error]';
  error.message = networkError.message;

  captureException(error);
}

/**
 * Wraps the React Router `Routes` component with Sentry's browser routing integration.
 *
 * @returns - A wrapped version of the `Routes` component with Sentry's routing integration.
 */
export function createSentryRoutes() {
  return Sentry.withSentryReactRouterV6Routing(Routes);
}

export * from './ErrorBoundaryProvider';
export * from './hooks';

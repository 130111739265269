import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PersonOutIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 38 38" {...props}>
    <path d="M24.19 10.0432C24.19 6.5662 21.3927 3.75 17.9391 3.75C14.48546 3.75 11.68817 6.5662 11.68817 10.0432C11.68817 13.5202 14.48546 16.3364 17.9391 16.3364C21.3927 16.3364 24.19 13.5202 24.19 10.0432ZM21.0646 10.0432C21.0646 11.7738 19.6581 13.1898 17.9391 13.1898C16.2201 13.1898 14.81363 11.7738 14.81363 10.0432C14.81363 8.31257 16.2201 6.8966 17.9391 6.8966C19.6581 6.8966 21.0646 8.31257 21.0646 10.0432Z" />
    <path d="M7 25.1034V28.25H28.8782V25.1034C28.8782 20.9184 21.59 18.8102 17.9391 18.8102C14.28819 18.8102 7 20.9184 7 25.1034ZM9.73478 25.1034C10.00826 23.9864 14.24716 21.9568 17.9391 21.9568C21.6174 21.9568 25.829 23.9706 26.1434 25.1034H9.73478Z" />
    <path d="M35 14.7137H26.6655V17.9592H35V14.7137Z" />
  </SvgIcon>
);

export default PersonOutIcon;

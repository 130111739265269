import { Stack, styled, Typography } from '@mui/material';
import React from 'react';

export type TaglineLogoProps = {
  logo: React.ReactElement;
  tagline: string;
  annotation?: React.ReactElement | string;
  compact?: boolean;
  inline?: boolean;
  gapless?: boolean;
} & (
  | { compact: true; inline?: false }
  | { inline: true; compact?: false }
  | { compact?: false; inline?: false }
);

const LogoWrapper = styled('div')({
  '& > svg': { display: 'block', width: 'auto', height: '100%' },
});

export const TaglineLogo: React.FC<TaglineLogoProps> = ({
  logo,
  tagline,
  annotation,
  compact,
  inline,
  gapless,
}) => {
  if (compact) {
    return (
      <Stack
        display="inline-flex"
        data-testid="tagline-logo"
        data-x-variant="compact"
        direction="column"
        spacing={1}
      >
        <LogoWrapper sx={{ height: 48 }}>{logo}</LogoWrapper>
        <Typography variant="h9" color="text.primary" data-testid="Tagline">
          {tagline}
        </Typography>
        {typeof annotation === 'string' ? (
          <Typography variant="xSmall" color="text.secondary" data-testid="annotation">
            {annotation}
          </Typography>
        ) : (
          annotation
        )}
      </Stack>
    );
  }

  if (inline) {
    return (
      <Stack
        display="inline-flex"
        data-testid="tagline-logo"
        data-x-variant="inline"
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <LogoWrapper sx={{ height: 24 }}>{logo}</LogoWrapper>
        <Typography variant="small" color="text.primary" data-testid="Tagline">
          {tagline}
        </Typography>
        {typeof annotation === 'string' ? (
          <Typography variant="xSmall" color="text.secondary" data-testid="annotation">
            {annotation}
          </Typography>
        ) : (
          annotation
        )}
      </Stack>
    );
  }

  if (gapless) {
    return (
      <Stack data-testid="tagline-logo" data-x-variant="gapless" direction="column" marginTop={3}>
        <LogoWrapper>{logo}</LogoWrapper>
        <Typography variant="h7" color="text.primary" data-testid="Tagline">
          {tagline}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack display="inline-flex" data-testid="tagline-logo" direction="column" spacing={1}>
      <LogoWrapper sx={{ height: 57 }}>{logo}</LogoWrapper>
      <Typography variant="h7" color="text.primary" data-testid="Tagline">
        {tagline}
      </Typography>
      {typeof annotation === 'string' ? (
        <Typography variant="small" color="text.secondary" data-testid="annotation">
          {annotation}
        </Typography>
      ) : (
        annotation
      )}
    </Stack>
  );
};

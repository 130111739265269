import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const DistributionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M23.4545 9.50217H19.9091V5H3.36364C2.06364 5 1 6.01299 1 7.25108V19.632H3.36364C3.36364 21.5004 4.94727 23.0087 6.90909 23.0087C8.87091 23.0087 10.4545 21.5004 10.4545 19.632H17.5455C17.5455 21.5004 19.1291 23.0087 21.0909 23.0087C23.0527 23.0087 24.6364 21.5004 24.6364 19.632H27V14.0043L23.4545 9.50217ZM6.90909 21.3203C5.92818 21.3203 5.13636 20.5662 5.13636 19.632C5.13636 18.6978 5.92818 17.9437 6.90909 17.9437C7.89 17.9437 8.68182 18.6978 8.68182 19.632C8.68182 20.5662 7.89 21.3203 6.90909 21.3203ZM22.8636 11.1905L25.18 14.0043H19.9091V11.1905H22.8636ZM21.0909 21.3203C20.11 21.3203 19.3182 20.5662 19.3182 19.632C19.3182 18.6978 20.11 17.9437 21.0909 17.9437C22.0718 17.9437 22.8636 18.6978 22.8636 19.632C22.8636 20.5662 22.0718 21.3203 21.0909 21.3203Z" />
  </SvgIcon>
);

export default DistributionIcon;

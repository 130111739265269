import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ManufacturingIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M25.8571 11.6V26H3V11.6L11 8V10.4L16.7143 8V11.6H25.8571ZM20.3714 9.8L21.2857 2H24.7143L25.6286 9.8H20.3714ZM13.2857 21.2H15.5714V16.4H13.2857V21.2ZM8.71429 21.2H11V16.4H8.71429V21.2ZM20.1429 16.4H17.8571V21.2H20.1429V16.4Z" />
  </SvgIcon>
);

export default ManufacturingIcon;

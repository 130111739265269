import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { AgentCardButton } from './AgentCardFooter/AgentCardButton';
import { AgentCardTitle } from './AgentCardTitle/AgentCardTitle';

type AgentCardTitleProps = React.ComponentProps<typeof AgentCardTitle>;
type AgentCardButtonProps = React.ComponentProps<typeof AgentCardButton>;

interface AgentBaseCardProps {
  titleProps?: AgentCardTitleProps;
  buttonProps?: AgentCardButtonProps;
  children?: React.ReactNode;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 540,
  width: 342,
  padding: theme.tokens.spacing.xxlarge,
  borderRadius: theme.tokens.borderRadius.medium,
  backgroundColor: '#40404059',
  overflow: 'hidden',
}));

const ButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export function AgentBaseCard({ titleProps, buttonProps, children }: Readonly<AgentBaseCardProps>) {
  return (
    <Container>
      {titleProps && <AgentCardTitle {...titleProps} />}
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
      {buttonProps && (
        <ButtonWrapper>
          <AgentCardButton {...buttonProps} />
        </ButtonWrapper>
      )}
    </Container>
  );
}

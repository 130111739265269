import { styled } from '@mui/material/styles';
import React from 'react';

import { Stack } from '../../Stack';
import { Typography } from '../../Typography';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export interface CrossLinkButtonProps extends ButtonBaseProps {
  /**
   * Text displayed before the label (children).
   */
  prefix?: string;
  /**
   * The button label.
   */
  children?: string;
}

const CrossLinkButtonRoot = styled(ButtonBase)(({ theme }) => ({
  gap: theme.tokens.spacing['xsmall'],
  borderRadius: 40, // Missing border radius token
  height: 40,
  padding: theme.spacing(theme.tokens.spacing['xxsmall'], theme.tokens.spacing['medium']),

  backgroundColor: theme.tokens.color['neutral.800'],
  color: theme.tokens.color['text.regular'],
}));

const CrossLinkButton: React.FC<CrossLinkButtonProps> = ({ children, prefix, ...rest }) => (
  <CrossLinkButtonRoot data-testid="cross-link-button" {...rest}>
    <Stack direction="row" alignItems="center" spacing="xxsmall" sx={{ maxWidth: '100%' }}>
      {prefix && (
        <Typography
          variant="heading.button"
          color="sentiment.neutral"
          sx={{ lineHeight: 1 }}
          data-testid="cross-link-button__prefix"
        >
          {prefix}
        </Typography>
      )}
      <Typography
        variant="heading.button"
        lineClamp={1}
        sx={{ lineHeight: 1 }}
        data-testid="cross-link-button__label"
      >
        {children}
      </Typography>
    </Stack>
  </CrossLinkButtonRoot>
);

export default CrossLinkButton;

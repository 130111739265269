import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ChevronDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M4.957 6.006a.5.5 0 0 0-.352.148.5.5 0 0 0 .006.71L7.648 9.85a.5.5 0 0 0 .702 0l3.039-2.987a.5.5 0 0 0 .006-.709.5.5 0 0 0-.707-.006L8 8.793 5.313 6.148a.5.5 0 0 0-.356-.142z" />
  </SvgIcon>
);

export default ChevronDownIcon;

import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type {
  DataViewFields_ProHomeFeedDataView_Fragment,
  RealFieldsFragment,
  StoryFieldsFragment,
} from '../../../../core/__generated__/fragments';
import {
  DataViewFieldsFragmentDoc,
  RealFieldsFragmentDoc,
  StoryFieldsFragmentDoc,
} from '../../../../core/__generated__/fragments';
import type * as T from '../../../../schema/__generated__/types';
import type { ComponentFields_ProHomeFeedComponent_Fragment } from '../../../rnd/__generated__/fragments';
import { ComponentFieldsFragmentDoc } from '../../../rnd/__generated__/fragments';
const defaultOptions = {} as const;
export type GetProHomePageContentQueryVariables = T.Exact<{
  pageId: T.Scalars['Uuid']['input'];
  priorityId?: T.InputMaybe<T.Scalars['Int']['input']>;
}>;

export type GetProHomePageContentQuery = {
  __typename?: 'Query';
  pageContent: {
    __typename?: 'PageContentResult';
    body: Array<
      { __typename?: 'ProHomeFeedComponent' } & ComponentFields_ProHomeFeedComponent_Fragment
    >;
  };
};

export type GetProHomeFeedInputQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.ProHomeFeedDataInput>;
}>;

export type GetProHomeFeedInputQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    home?: {
      __typename?: 'HomeResult';
      feed?:
        | ({
            __typename?: 'ProHomeFeedDataView';
            id: string;
            items?: Array<
              | ({ __typename?: 'Real' } & RealFieldsFragment)
              | ({ __typename?: 'Story' } & StoryFieldsFragment)
              | null
            > | null;
            feedFilterOptions?: Array<{
              __typename?: 'AdditionalFilterOptions';
              filterValue: string;
              filterCode: string;
              filterLabel: string;
              subFilters?: Array<{
                __typename?: 'AdditionalFilterOptions';
                filterValue: string;
                filterCode: string;
                filterLabel: string;
              } | null> | null;
            } | null> | null;
            userAdditionalFilters?: Array<{
              __typename?: 'UserAdditionalFilters';
              filterValue: string;
              filterCode: string;
              filterLabel: string;
              filterType: string;
              isDefault: boolean;
              isValid: boolean;
            } | null> | null;
          } & DataViewFields_ProHomeFeedDataView_Fragment)
        | null;
    } | null;
  };
};

export type GetProHomeFilterCategoriesQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetProHomeFilterCategoriesQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    home?: {
      __typename?: 'HomeResult';
      filterCategories?: Array<{
        __typename?: 'ProHomeFilterCategoryResult';
        categoryId: string;
        categoryLabel: string;
      } | null> | null;
    } | null;
  };
};

export type GetProHomeFiltersQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.ProHomeFiltersInput>;
}>;

export type GetProHomeFiltersQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    home?: {
      __typename?: 'HomeResult';
      filters?: Array<{
        __typename?: 'ProHomeFilterResult';
        filterId: string;
        filterLabel: string;
      } | null> | null;
    } | null;
  };
};

export type GetProHomeUserFiltersQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetProHomeUserFiltersQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    home?: {
      __typename?: 'HomeResult';
      userFilters?: Array<{
        __typename?: 'ProHomeFilterResult';
        filterId: string;
        filterLabel: string;
        isDefault: boolean;
      } | null> | null;
    } | null;
  };
};

export type GetProHomeFeedQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.ProHomeFeedDataInput>;
}>;

export type GetProHomeFeedQuery = {
  __typename?: 'Query';
  pro: {
    __typename: 'ProModuleResult';
    home?: {
      __typename: 'HomeResult';
      feed?:
        | ({
            __typename: 'ProHomeFeedDataView';
            id: string;
            items?: Array<
              | ({ __typename: 'Real' } & RealFieldsFragment)
              | ({ __typename: 'Story' } & StoryFieldsFragment)
              | null
            > | null;
            feedFilterOptions?: Array<{
              __typename: 'AdditionalFilterOptions';
              filterValue: string;
              filterCode: string;
              filterLabel: string;
              subFilters?: Array<{
                __typename: 'AdditionalFilterOptions';
                filterValue: string;
                filterCode: string;
                filterLabel: string;
              } | null> | null;
            } | null> | null;
            userAdditionalFilters?: Array<{
              __typename: 'UserAdditionalFilters';
              filterValue: string;
              filterCode: string;
              filterLabel: string;
              filterType: string;
              isDefault: boolean;
              isValid: boolean;
            } | null> | null;
          } & DataViewFields_ProHomeFeedDataView_Fragment)
        | null;
    } | null;
  };
};

export const GetProHomePageContentDocument = gql`
  query GetProHomePageContent($pageId: Uuid!, $priorityId: Int) {
    pageContent(pageId: $pageId, priorityId: $priorityId) {
      body {
        ... on ProHomeFeedComponent {
          ...ComponentFields
        }
      }
    }
  }
  ${ComponentFieldsFragmentDoc}
`;

/**
 * __useGetProHomePageContentQuery__
 *
 * To run a query within a React component, call `useGetProHomePageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomePageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomePageContentQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      priorityId: // value for 'priorityId'
 *   },
 * });
 */
export function useGetProHomePageContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  > &
    ({ variables: GetProHomePageContentQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProHomePageContentQuery, GetProHomePageContentQueryVariables>(
    GetProHomePageContentDocument,
    options,
  );
}
export function useGetProHomePageContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >(GetProHomePageContentDocument, options);
}
export function useGetProHomePageContentSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >(GetProHomePageContentDocument, options);
}
export type GetProHomePageContentQueryHookResult = ReturnType<typeof useGetProHomePageContentQuery>;
export type GetProHomePageContentLazyQueryHookResult = ReturnType<
  typeof useGetProHomePageContentLazyQuery
>;
export type GetProHomePageContentSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomePageContentSuspenseQuery
>;
export type GetProHomePageContentQueryResult = Apollo.QueryResult<
  GetProHomePageContentQuery,
  GetProHomePageContentQueryVariables
>;
export const GetProHomeFeedInputDocument = gql`
  query GetProHomeFeedInput($input: ProHomeFeedDataInput) {
    pro {
      home {
        feed(input: $input) {
          ...DataViewFields
          ... on ProHomeFeedDataView {
            id
          }
          items {
            ...RealFields
            ...StoryFields
          }
          feedFilterOptions {
            filterValue
            filterCode
            filterLabel
            subFilters {
              filterValue
              filterCode
              filterLabel
            }
          }
          userAdditionalFilters {
            filterValue
            filterCode
            filterLabel
            filterType
            isDefault
            isValid
          }
        }
      }
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${RealFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetProHomeFeedInputQuery__
 *
 * To run a query within a React component, call `useGetProHomeFeedInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeFeedInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeFeedInputQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProHomeFeedInputQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProHomeFeedInputQuery, GetProHomeFeedInputQueryVariables>(
    GetProHomeFeedInputDocument,
    options,
  );
}
export function useGetProHomeFeedInputLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetProHomeFeedInputQuery, GetProHomeFeedInputQueryVariables>(
    GetProHomeFeedInputDocument,
    options,
  );
}
export function useGetProHomeFeedInputSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >(GetProHomeFeedInputDocument, options);
}
export type GetProHomeFeedInputQueryHookResult = ReturnType<typeof useGetProHomeFeedInputQuery>;
export type GetProHomeFeedInputLazyQueryHookResult = ReturnType<
  typeof useGetProHomeFeedInputLazyQuery
>;
export type GetProHomeFeedInputSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeFeedInputSuspenseQuery
>;
export type GetProHomeFeedInputQueryResult = Apollo.QueryResult<
  GetProHomeFeedInputQuery,
  GetProHomeFeedInputQueryVariables
>;
export const GetProHomeFilterCategoriesDocument = gql`
  query GetProHomeFilterCategories {
    pro {
      home {
        filterCategories {
          categoryId
          categoryLabel
        }
      }
    }
  }
`;

/**
 * __useGetProHomeFilterCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProHomeFilterCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeFilterCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeFilterCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProHomeFilterCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeFilterCategoriesQuery,
    GetProHomeFilterCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetProHomeFilterCategoriesQuery,
    GetProHomeFilterCategoriesQueryVariables
  >(GetProHomeFilterCategoriesDocument, options);
}
export function useGetProHomeFilterCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeFilterCategoriesQuery,
    GetProHomeFilterCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProHomeFilterCategoriesQuery,
    GetProHomeFilterCategoriesQueryVariables
  >(GetProHomeFilterCategoriesDocument, options);
}
export function useGetProHomeFilterCategoriesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeFilterCategoriesQuery,
    GetProHomeFilterCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomeFilterCategoriesQuery,
    GetProHomeFilterCategoriesQueryVariables
  >(GetProHomeFilterCategoriesDocument, options);
}
export type GetProHomeFilterCategoriesQueryHookResult = ReturnType<
  typeof useGetProHomeFilterCategoriesQuery
>;
export type GetProHomeFilterCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetProHomeFilterCategoriesLazyQuery
>;
export type GetProHomeFilterCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeFilterCategoriesSuspenseQuery
>;
export type GetProHomeFilterCategoriesQueryResult = Apollo.QueryResult<
  GetProHomeFilterCategoriesQuery,
  GetProHomeFilterCategoriesQueryVariables
>;
export const GetProHomeFiltersDocument = gql`
  query GetProHomeFilters($input: ProHomeFiltersInput) {
    pro {
      home {
        filters(input: $input) {
          filterId
          filterLabel
        }
      }
    }
  }
`;

/**
 * __useGetProHomeFiltersQuery__
 *
 * To run a query within a React component, call `useGetProHomeFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProHomeFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeFiltersQuery,
    GetProHomeFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProHomeFiltersQuery, GetProHomeFiltersQueryVariables>(
    GetProHomeFiltersDocument,
    options,
  );
}
export function useGetProHomeFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeFiltersQuery,
    GetProHomeFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetProHomeFiltersQuery, GetProHomeFiltersQueryVariables>(
    GetProHomeFiltersDocument,
    options,
  );
}
export function useGetProHomeFiltersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeFiltersQuery,
    GetProHomeFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetProHomeFiltersQuery, GetProHomeFiltersQueryVariables>(
    GetProHomeFiltersDocument,
    options,
  );
}
export type GetProHomeFiltersQueryHookResult = ReturnType<typeof useGetProHomeFiltersQuery>;
export type GetProHomeFiltersLazyQueryHookResult = ReturnType<typeof useGetProHomeFiltersLazyQuery>;
export type GetProHomeFiltersSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeFiltersSuspenseQuery
>;
export type GetProHomeFiltersQueryResult = Apollo.QueryResult<
  GetProHomeFiltersQuery,
  GetProHomeFiltersQueryVariables
>;
export const GetProHomeUserFiltersDocument = gql`
  query GetProHomeUserFilters {
    pro {
      home {
        userFilters {
          filterId
          filterLabel
          isDefault
        }
      }
    }
  }
`;

/**
 * __useGetProHomeUserFiltersQuery__
 *
 * To run a query within a React component, call `useGetProHomeUserFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeUserFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeUserFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProHomeUserFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeUserFiltersQuery,
    GetProHomeUserFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProHomeUserFiltersQuery, GetProHomeUserFiltersQueryVariables>(
    GetProHomeUserFiltersDocument,
    options,
  );
}
export function useGetProHomeUserFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeUserFiltersQuery,
    GetProHomeUserFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProHomeUserFiltersQuery,
    GetProHomeUserFiltersQueryVariables
  >(GetProHomeUserFiltersDocument, options);
}
export function useGetProHomeUserFiltersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeUserFiltersQuery,
    GetProHomeUserFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomeUserFiltersQuery,
    GetProHomeUserFiltersQueryVariables
  >(GetProHomeUserFiltersDocument, options);
}
export type GetProHomeUserFiltersQueryHookResult = ReturnType<typeof useGetProHomeUserFiltersQuery>;
export type GetProHomeUserFiltersLazyQueryHookResult = ReturnType<
  typeof useGetProHomeUserFiltersLazyQuery
>;
export type GetProHomeUserFiltersSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeUserFiltersSuspenseQuery
>;
export type GetProHomeUserFiltersQueryResult = Apollo.QueryResult<
  GetProHomeUserFiltersQuery,
  GetProHomeUserFiltersQueryVariables
>;
export const GetProHomeFeedDocument = gql`
  query GetProHomeFeed($input: ProHomeFeedDataInput) {
    pro {
      home {
        feed(input: $input) {
          ...DataViewFields
          ... on ProHomeFeedDataView {
            id
            __typename
          }
          items {
            ...RealFields
            ...StoryFields
            __typename
          }
          feedFilterOptions {
            filterValue
            filterCode
            filterLabel
            subFilters {
              filterValue
              filterCode
              filterLabel
              __typename
            }
            __typename
          }
          userAdditionalFilters {
            filterValue
            filterCode
            filterLabel
            filterType
            isDefault
            isValid
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${RealFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetProHomeFeedQuery__
 *
 * To run a query within a React component, call `useGetProHomeFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeFeedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProHomeFeedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeFeedQuery,
    GetProHomeFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProHomeFeedQuery, GetProHomeFeedQueryVariables>(
    GetProHomeFeedDocument,
    options,
  );
}
export function useGetProHomeFeedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeFeedQuery,
    GetProHomeFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetProHomeFeedQuery, GetProHomeFeedQueryVariables>(
    GetProHomeFeedDocument,
    options,
  );
}
export function useGetProHomeFeedSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeFeedQuery,
    GetProHomeFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetProHomeFeedQuery, GetProHomeFeedQueryVariables>(
    GetProHomeFeedDocument,
    options,
  );
}
export type GetProHomeFeedQueryHookResult = ReturnType<typeof useGetProHomeFeedQuery>;
export type GetProHomeFeedLazyQueryHookResult = ReturnType<typeof useGetProHomeFeedLazyQuery>;
export type GetProHomeFeedSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeFeedSuspenseQuery
>;
export type GetProHomeFeedQueryResult = Apollo.QueryResult<
  GetProHomeFeedQuery,
  GetProHomeFeedQueryVariables
>;

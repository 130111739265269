import { colors } from '@aily/saas-core/theme/default/colors';
import { Slider, SliderProps, styled } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { mapValueToSliderValue } from './utils';

export interface SliderMark {
  value: number;
  label: string;
}

export interface PivotSliderProps extends Omit<SliderProps, 'onChange' | 'onChangeCommitted'> {
  baseValue: number;
  value?: number;
  defaultValue?: number;
  marks?: SliderMark[];
  railThickness?: number;
  thumbSize?: number;
  fixedThumbSize?: number;
  highlightedFixedThumb?: boolean;
  railColor?: string;
  onChange?: (value: number) => void;
  onChangeCommitted?: (value: number) => void;
}

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'fixedThumbIndex',
})<{
  fixedThumbIndex: number;
  railThickness?: number;
  thumbSize?: number;
  fixedThumbSize?: number;
  highlightedFixedThumb?: boolean;
  railColor?: string;
}>(
  ({
    theme,
    fixedThumbIndex,
    railThickness = 15,
    thumbSize = 21,
    fixedThumbSize = 11,
    highlightedFixedThumb,
    railColor,
  }) => ({
    borderRadius: railThickness,
    color: railColor ?? colors.primary.aqua,
    '& .MuiSlider-rail': {
      top: '50%',
      left: -railThickness / 2,
      width: `calc(100% + ${railThickness}px)`,
      height: railThickness,
      backgroundColor: theme.palette.background.paper,
      opacity: 1,
    },
    '& .MuiSlider-track': {
      height: railThickness,
      border: 'none',
      borderRadius: 0,
      '&::before': {
        position: 'absolute',
        top: 0,
        left: -railThickness / 2,
        borderRadius: railThickness,
        backgroundColor: 'inherit',
        width: `calc(100% + ${railThickness}px)`,
        height: '100%',
        content: '""',
      },
    },
    '& .MuiSlider-thumb': {
      zIndex: 1,
      width: thumbSize,
      height: thumbSize,
      backgroundColor: theme.palette.text.primary,
    },
    [`& .MuiSlider-thumb[data-index="${fixedThumbIndex}"]`]: {
      zIndex: 0,
      width: fixedThumbSize,
      height: fixedThumbSize,
      backgroundColor: highlightedFixedThumb
        ? theme.palette.background.paper
        : theme.palette.background.default,
      transition: 'none',
      border: highlightedFixedThumb ? '5px solid' : 'none',
      borderColor: highlightedFixedThumb ? railColor : 'none',
      '&:hover, &.Mui-focusVisible, &.Mui-active': {
        boxShadow: 'none',
      },
    },
  }),
);

export const PivotSlider: React.FC<PivotSliderProps> = ({
  baseValue,
  value,
  defaultValue,
  marks,
  railThickness,
  thumbSize,
  fixedThumbSize,
  highlightedFixedThumb,
  railColor,
  onChange,
  onChangeCommitted,
  slotProps,
  ...rest
}) => {
  // A local state holding the currently selected value
  const localValue =
    // The only reason to use a reference is to keep the `handleOnChangeCommitted` callback consistent despite the value changes
    useRef(value ?? defaultValue ?? baseValue);

  // Current value based on whether the component is controlled or uncontrolled
  const currentValue = value !== undefined ? value : localValue.current;
  const [sliderValue, setSliderValue] = useState(mapValueToSliderValue(currentValue, baseValue));

  useUpdateEffect(() => {
    if (value !== undefined) {
      localValue.current = value;
      setSliderValue(mapValueToSliderValue(value, baseValue));
    }
  }, [value, localValue, baseValue]);

  // Determines which thumb is fixed based on the selected value
  const fixedThumbIndex = useMemo(
    () => (localValue.current > baseValue ? 0 : 1),
    [localValue.current, baseValue],
  );

  const handleOnChange = useCallback(
    (_event: Event, value: number | number[], activeThumb: number) => {
      const v = (value as [number, number])[activeThumb];
      localValue.current = v;
      setSliderValue(mapValueToSliderValue(v, baseValue));
      onChange?.(v);
    },
    [onChange, baseValue, localValue],
  );

  const handleOnChangeCommitted = useCallback(() => {
    setSliderValue(mapValueToSliderValue(localValue.current, baseValue));
    onChangeCommitted?.(localValue.current);
  }, [onChangeCommitted, baseValue, localValue]);

  const handleThumbClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      // Clicking on the fixed thumb will set the base value to be selected
      if ((event.target as HTMLElement).dataset.index === fixedThumbIndex.toString()) {
        localValue.current = baseValue;
        handleOnChangeCommitted();
      }
    },
    [baseValue, fixedThumbIndex, handleOnChangeCommitted],
  );

  return (
    <StyledSlider
      value={sliderValue}
      onChange={handleOnChange}
      onChangeCommitted={handleOnChangeCommitted}
      fixedThumbIndex={fixedThumbIndex}
      railThickness={railThickness}
      thumbSize={thumbSize}
      fixedThumbSize={fixedThumbSize}
      highlightedFixedThumb={highlightedFixedThumb}
      railColor={railColor}
      marks={marks ?? false}
      slotProps={{ thumb: { onClick: handleThumbClick }, ...slotProps }}
      {...rest}
    />
  );
};

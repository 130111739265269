import * as T from '@aily/graphql-sdk/schema';
import React, { createContext, ProviderProps, useContext } from 'react';

export type ModulesContextValue = T.Module[];

export const ModulesContext = createContext<ModulesContextValue | null>(null);

export const ModulesProvider = ({ value, children }: ProviderProps<ModulesContextValue>) => {
  return <ModulesContext.Provider value={value}>{children}</ModulesContext.Provider>;
};

export function useModules() {
  return useContext(ModulesContext);
}

import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const CustomerIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M10.0359 13.65C10.0359 14.4435 9.4226 15.0875 8.66688 15.0875C7.91117 15.0875 7.29783 14.4435 7.29783 13.65C7.29783 12.8565 7.91117 12.2125 8.66688 12.2125C9.4226 12.2125 10.0359 12.8565 10.0359 13.65ZM15.2383 12.2125C14.4826 12.2125 13.8693 12.8565 13.8693 13.65C13.8693 14.4435 14.4826 15.0875 15.2383 15.0875C15.994 15.0875 16.6074 14.4435 16.6074 13.65C16.6074 12.8565 15.994 12.2125 15.2383 12.2125ZM22.905 12.5C22.905 18.848 17.9983 24 11.9526 24C5.90688 24 1.00021 18.848 1.00021 12.5C1.00021 6.152 5.90688 1 11.9526 1C17.9983 1 22.905 6.152 22.905 12.5ZM10.485 3.438C12.0183 6.106 14.8002 7.9 17.9764 7.9C18.4802 7.9 18.9731 7.8425 19.444 7.762C17.9107 5.094 15.1288 3.3 11.9526 3.3C11.4488 3.3 10.9559 3.3575 10.485 3.438ZM3.65069 9.5905C5.52355 8.475 6.96926 6.658 7.65926 4.4845C5.7864 5.6 4.34069 7.417 3.65069 9.5905ZM20.7145 12.5C20.7145 11.603 20.5831 10.7405 20.3531 9.924C19.5864 10.0965 18.7978 10.2 17.9764 10.2C14.5483 10.2 11.4926 8.544 9.47736 5.9565C8.32736 8.9005 6.03831 11.212 3.19069 12.339C3.20164 12.385 3.19069 12.4425 3.19069 12.5C3.19069 17.5715 7.12259 21.7 11.9526 21.7C16.7826 21.7 20.7145 17.5715 20.7145 12.5Z" />
  </SvgIcon>
);

export default CustomerIcon;

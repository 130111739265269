import H from '../../../../config/highchartsConfig';

/**
 * Converts a date string (e.g. "2029-01-01T00:00:00") into a year string.
 */
function formatDateToYear(dateString: string): string {
  const date = new Date(dateString);
  return date.getFullYear().toString();
}

/**
 * Highcharts formatter that displays the year only for the first and last points.
 */
export const yearOnlyFirstLastFormatter: H.AxisLabelsFormatterCallbackFunction = function () {
  const index = this.pos;
  const categories = this.axis.categories as string[];
  const isFirstPoint = index === 0;
  const isLastPoint = index === categories.length - 1;

  // Display label only for the first and last points
  if (isFirstPoint || isLastPoint) {
    return formatDateToYear(categories[index]);
  }

  return ''; // No label for other points
};

import { Button, styled, SvgIcon, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { motion } from 'framer-motion';
import React, { useLayoutEffect } from 'react';

import { colors } from '../../theme/default/colors';

interface ErrorScreenProps {
  error: Error;
}

const Root = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex['modal'],
  top: 0,
  left: 0,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  backgroundColor: colors.overlays.black,
}));

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 364,
  paddingBottom: 80,
  textAlign: 'center',
  userSelect: 'none',
  transform: 'scale(var(--error-vw-scale)) translateZ(0)',
  transformOrigin: 'top center',
});

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 136,
  height: 36,
  borderRadius: 36,
  backgroundColor: colors.neutrals.white,
  color: colors.neutrals.black,
  textAlign: 'center',
  ...theme.typography.subtitle,
  '&:hover': {
    backgroundColor: colors.neutrals.white,
    color: colors.neutrals.black,
  },
}));

const Cross: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="21" height="20" viewBox="0 0 21 20" {...props}>
    <path d="M3.52207 15.2502L8.27228 10.5L3.52207 5.74979C3.24264 5.47036 3.24264 5.01312 3.52207 4.7337L5.0462 3.20957C5.32562 2.93014 5.78286 2.93014 6.06229 3.20957L10.8125 7.95978L15.5627 3.20957C15.8421 2.93014 16.3248 2.93014 16.6042 3.20957L18.1029 4.7337C18.3824 5.01312 18.3824 5.47036 18.1029 5.74979L13.3527 10.5L18.1029 15.2502C18.3824 15.5296 18.3824 16.0123 18.1029 16.2917L16.6042 17.7904C16.3248 18.0699 15.8421 18.0699 15.5627 17.7904L10.8125 13.0402L6.06229 17.7904C5.78286 18.0699 5.32562 18.0699 5.0462 17.7904L3.52207 16.2917C3.24264 16.0123 3.24264 15.5296 3.52207 15.2502Z" />
  </SvgIcon>
);

const GeneralError = () => {
  useLayoutEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      cleanDocumentElement();
    };
  }, []);

  return (
    <Content>
      <Cross sx={{ mb: 2, fontSize: 64 }} />
      <Typography variant="h5" color="text.primary" sx={{ mb: 4.5 }}>
        Oops! Something Went Wrong!
      </Typography>
      <Typography variant="h8" color="text.primary" sx={{ mb: 5.625 }}>
        An error occurred, but rest assured it’s been logged and our team is on it! Please, refresh
        the page or check back soon. Thanks for your patience!
      </Typography>
      <StyledButton variant="contained" onClick={() => window.location.reload()}>
        Refresh
      </StyledButton>
    </Content>
  );
};

function isAppOutdatedError(errorMessage: string): boolean {
  const errorStrings = [
    'ChunkLoadError',
    'Failed to load module script',
    'Failed to fetch dynamically imported module',
  ];

  return errorStrings.some((errorString) => errorMessage.includes(errorString));
}

function handleWindowResize() {
  const style = document.documentElement.style;
  style.setProperty('--error-vw-scale', `${Math.min(Math.max(window.innerWidth / 900, 0.7), 1)}`);
}

function cleanDocumentElement() {
  const style = document.documentElement.style;
  style.removeProperty('--error-vw-scale');
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ error }) => {
  // If the error indicates outdated chunks (app update), reload the page immediately
  if (isAppOutdatedError(error.message)) {
    window.location.reload();
    return null;
  }

  return (
    <Root
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.1, ease: [0.64, 0.57, 0.67, 1.53] }}
    >
      <GeneralError />
    </Root>
  );
};

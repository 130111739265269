import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const StarIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M10.3559 2.74451C11.0287 1.37162 12.9725 1.37163 13.6452 2.74451L15.7442 7.02805C15.7977 7.13708 15.9009 7.21266 16.0204 7.23014L20.714 7.91704C22.2183 8.13719 22.8189 9.99907 21.7304 11.0677L18.3341 14.402C18.2477 14.4869 18.2082 14.6091 18.2286 14.729L19.0304 19.437C19.2874 20.946 17.7148 22.0967 16.3693 21.3843L12.1712 19.1614C12.0644 19.1048 11.9367 19.1048 11.8299 19.1614L7.63182 21.3843C6.28632 22.0967 4.71377 20.946 4.97074 19.437L5.77249 14.729C5.7929 14.6091 5.75345 14.4869 5.667 14.402L2.2707 11.0677C1.18217 9.99907 1.78284 8.13719 3.28715 7.91704L7.98071 7.23014C8.10019 7.21266 8.20346 7.13708 8.25689 7.02805L10.3559 2.74451ZM12.3295 3.3985C12.1949 3.12392 11.8062 3.12393 11.6716 3.3985L9.57261 7.68204C9.30546 8.22721 8.78906 8.60509 8.1917 8.69251L3.49813 9.37941C3.19727 9.42344 3.07714 9.79581 3.29484 10.0095L6.69114 13.3438C7.12339 13.7682 7.32064 14.3796 7.2186 14.9788L6.41684 19.6868C6.36545 19.9886 6.67996 20.2188 6.94906 20.0763L11.1471 17.8534C11.6814 17.5705 12.3197 17.5705 12.854 17.8534L17.0521 20.0763C17.3212 20.2188 17.6357 19.9886 17.5843 19.6868L16.7825 14.9788C16.6805 14.3796 16.8777 13.7682 17.31 13.3438L20.7063 10.0095C20.924 9.79582 20.8039 9.42344 20.503 9.37941L15.8094 8.69251C15.2121 8.60509 14.6957 8.22722 14.4285 7.68204L12.3295 3.3985Z" />
  </SvgIcon>
);

export default StarIcon;

import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PlusCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M1.82037e-05 13.9877C0.0123693 6.25927 6.29909 -0.0123275 14.0185 1.81956e-05C21.738 0.0123639 28.0124 6.29631 28 14.0124C27.9877 21.7284 21.7009 28 13.9815 28C6.26204 27.9877 -0.0123329 21.7037 1.82037e-05 13.9877ZM25.6163 14.0124C25.6286 7.60495 20.4164 2.39508 14.0062 2.38273C7.59596 2.37039 2.38379 7.58026 2.37143 13.9877C2.35908 20.3951 7.57126 25.6049 13.9815 25.6173C20.3917 25.6296 25.6163 20.4074 25.6163 14.0124Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7951 15.2026L12.79 20.688H15.2098L15.2149 15.2049L20.6878 15.2099L20.7001 12.7902L15.2171 12.7851L15.2221 7.31172L12.8024 7.29937L12.7973 12.7829L7.31152 12.7778V15.1976L12.7951 15.2026Z"
    />
  </SvgIcon>
);

export default PlusCircleIcon;

import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

interface AgentIntroProps {
  text: string;
}

const Container = styled(Box)({
  width: '100%',
});

const StyledTextContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.tokens.color['background.light'], 0.5),
  padding: theme.tokens.spacing['xlarge'],
  borderRadius: theme.tokens.spacing['xlarge'],
  textAlign: 'center',
}));

export function AgentIntro({ text }: Readonly<AgentIntroProps>) {
  return (
    <Container>
      <StyledTextContainer>
        <Typography variant="heading.H6">{text}</Typography>
      </StyledTextContainer>
    </Container>
  );
}

import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const NextIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path d="M15.2227,27.9477V25.854C15.2227,25.3986 15.4414,24.9656 15.8119,24.6977L22.303,20.0013L15.8119,15.3049C15.4414,15.037 15.2227,14.6084 15.2227,14.1486V12.0553C15.2227,11.7651 15.553,11.5955 15.7896,11.7651L26.7718,19.7115C26.9682,19.8544 26.9682,20.1441 26.7718,20.287L15.7896,28.2334C15.553,28.4075 15.2227,28.2379 15.2227,27.9477Z" />
  </SvgIcon>
);

export default NextIcon;

import { GetUserSettingsQuery, useGetUserSettingsQuery } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { Alert } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { TableDataViewContextProvider } from '../../contexts';
import { useModule } from '../../providers';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { TableDataView, TableDataViewProps } from '../TableDataView';

export interface TableSwitcherComponentProps {
  component: T.TableSwitcherComponent;
  onPeriodTypeChange?: (periodType: T.PeriodType) => void;
  TableDataViewProps?: Partial<TableDataViewProps>;
  storySourceModuleCode?: string;
}

const userSettingKey = 'PeriodType';

const getPeriodTypeFromUserSettingOptions = (optionValue: number, options: T.SettingOption[]) => {
  const settingOption = options.find(({ value }) => value === optionValue) ?? options[0];
  const defaultPeriodType = settingOption.label.toUpperCase() as T.PeriodType;

  if (Object.values(T.PeriodType).includes(defaultPeriodType)) {
    return defaultPeriodType;
  }

  return undefined;
};

export const TableSwitcherComponent: React.FC<TableSwitcherComponentProps> = ({
  component,
  onPeriodTypeChange,
  TableDataViewProps,
  storySourceModuleCode,
}) => {
  const { tables } = component;
  const [periodType, setPeriodType] = useState(T.PeriodType.Period);
  const moduleId = useModule()?.id ?? '';

  const onCompleted = useCallback((data: GetUserSettingsQuery) => {
    const userSetting = data?.userSettings.find(({ key }) => key === userSettingKey);
    if (userSetting) {
      const { userValue, defaultValue, options } = userSetting;
      const optionValue = userValue ?? defaultValue ?? 0;
      const defaultPeriodType = getPeriodTypeFromUserSettingOptions(optionValue, options);

      if (defaultPeriodType) {
        setPeriodType(defaultPeriodType);
        onPeriodTypeChange?.(defaultPeriodType);
      }
    }
  }, []);

  // Load the user settings to get the default user setting option index for "PeriodType"
  const { loading } = useGetUserSettingsQuery({
    variables: { input: { moduleId } },
    onCompleted,
  });

  // Find the table component to display based on the current period type user setting
  const tableComponent = useMemo(
    () => tables?.find((tableComponent) => tableComponent.periodType === periodType) ?? tables?.[0],
    [tables, periodType],
  );

  const handleSettingChangeCommitted = useCallback((userSetting: T.UserSetting) => {
    if (userSetting.key === userSettingKey) {
      const { userValue, defaultValue, options } = userSetting;
      const optionValue = userValue ?? defaultValue ?? 0;
      const newPeriodType = getPeriodTypeFromUserSettingOptions(optionValue, options);

      if (newPeriodType) {
        setPeriodType(newPeriodType);
        onPeriodTypeChange?.(newPeriodType);

        // Send false so the current `TableDataView` doesn't perform data update as it will be switched with a new one
        return false;
      }
    }

    return true;
  }, []);

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!tableComponent) {
    return (
      <Alert variant="outlined" severity="info">
        No data
      </Alert>
    );
  }

  return (
    <TableDataViewContextProvider>
      <TableDataView
        key={tableComponent.id}
        component={tableComponent}
        onUserSettingChangeCommitted={handleSettingChangeCommitted}
        storySourceModuleCode={storySourceModuleCode}
        {...TableDataViewProps}
      />
    </TableDataViewContextProvider>
  );
};

export default TableSwitcherComponent;

import { defaultTheme } from '@aily/ui-theme';
import { Box, styled } from '@mui/material';

import { useBanner } from '../context';
import { VisualType } from '../types';
import { requireBannerVariant } from '../utils';
import { greenGradientStyle } from './GreenGradient';

const IconContainer = styled(Box)<{ type?: 'green' | 'grey'; height: number; width: number }>(
  ({ type, height, width }) => ({
    width,
    height,
    borderRadius: type === 'grey' ? 8 : defaultTheme.borderRadius['round'],
    marginRight: defaultTheme.spacing['small'],
    overflow: 'hidden',
    background: type === 'grey' ? greenGradientStyle : defaultTheme.color['background.positive'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
});

export function Visual({ height, ...visualProps }: VisualType & { height: number }) {
  const banner = useBanner();
  const { type } = banner;
  const width = type === 'grey' ? 80 : height;

  const getVisualElement = () => {
    if (visualProps.visualType === 'external') {
      return <StyledImage src={visualProps.visualSrc} alt="banner visual" />;
    }
    const VariantComponent = requireBannerVariant(visualProps.variant);
    return <VariantComponent />;
  };

  return (
    <IconContainer type={type} height={height} width={width}>
      {getVisualElement()}
    </IconContainer>
  );
}

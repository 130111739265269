import { styled } from '@mui/material';
import React from 'react';

import { Stack } from '../../../../../../../Stack';
import { Typography } from '../../../../../../../Typography';

export interface ParagraphSentimentProps {
  sentiment: 'positive' | 'negative' | 'warning';
  sentimentText: string;
  text: string;
}

const ParagraphContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.tokens.spacing.xsmall} 0`,
  gap: theme.tokens.spacing.xxsmall,
}));

const ParagraphSentiment: React.FC<ParagraphSentimentProps> = ({
  sentiment,
  sentimentText,
  text,
}) => {
  return (
    <ParagraphContainer>
      <Typography
        variant="body.bold-condensed"
        color={
          {
            positive: 'text.success' as const,
            warning: 'text.warning' as const,
            negative: 'text.danger' as const,
          }[sentiment]
        }
      >
        {sentimentText}
      </Typography>
      <Typography variant="body.small-condensed" lineClamp={6}>
        {text}
      </Typography>
    </ParagraphContainer>
  );
};

export default ParagraphSentiment;

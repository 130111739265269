import { defaultTheme } from '@aily/ui-theme';
import { alpha } from '@mui/material/styles';

import H from '../../../../config/highchartsConfig';
import { quarterYearFormatter, yAxisFormatter } from './utils';

export const baseOptions: H.Options & {
  series: [H.SeriesOptionsType, H.SeriesOptionsType];
} = {
  chart: {
    height: 240,
    style: {
      overflow: 'visible', // So that nothing is cut off
    },
  },
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
    numericSymbols: ['K', 'M', 'B'],
  },
  xAxis: {
    type: 'category',
    categories: [], // Make sure to provide categories as date strings (e.g., ["2022-01-01", "2023-01-01", ...])
    labels: {
      rotation: -55, // Rotate x-axis labels by -55 degrees
      x: 10, // Shift the labels 10px to the right
      style: {
        ...defaultTheme.typography['body.tiny'],
        color: defaultTheme.color['neutral.400'],
      } as H.CSSObject,
      formatter: quarterYearFormatter,
    },
  },
  yAxis: {
    opposite: true,
    labels: {
      style: {
        ...defaultTheme.typography['body.tiny'],
        color: defaultTheme.color['neutral.400'],
      } as H.CSSObject,
      formatter: yAxisFormatter,
    },
    gridLineColor: '#313131', // Missing color token
  },
  series: [
    {
      name: 'AI Optimized',
      type: 'line',
      color: defaultTheme.color['brand.orange'],
      lineWidth: 2,
      zIndex: 1, // Display above the other series
      legendSymbol: 'rectangle',
      data: [],
      marker: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
    },
    {
      name: 'Baseline',
      type: 'area',
      color: defaultTheme.color['brand.aqua'],
      // This is the color token "gradient.aqua.transparent-dark" converted to a Highcharts gradient
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, defaultTheme.color['aqua.700']],
          [1, alpha(defaultTheme.color['aqua.700'], 0)],
        ],
      },
      lineWidth: 2,
      zIndex: 0,
      legendSymbol: 'rectangle',
      data: [],
    },
  ],
  legend: {
    enabled: true,
    align: 'left', // Align legend to the left
    verticalAlign: 'bottom',
    layout: 'horizontal',
    x: -8,
  },
  tooltip: {
    enabled: false,
  },
};

import { Box, BoxProps, styled, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars-2';

export interface ScrollableContainerProps extends BoxProps {
  children: React.ReactNode;
  showShadows?: boolean;
  shadowSize?: number | string;
  shadowColor?: string;
}

// Styled component for shadows
const Shadow = styled('div')<{
  size: number | string;
  position: 'top' | 'bottom';
  color: string;
}>(({ size, position, color }) => ({
  position: 'absolute',
  zIndex: 1,
  left: 0,
  right: 0,
  [position]: 0,
  height: size,
  background:
    position === 'top'
      ? `linear-gradient(to bottom, ${color} 0%, ${color}00 100%)` // Fade from color to transparent
      : `linear-gradient(to top, ${color} 0%, ${color}00 100%)`, // Fade from color to transparent
  opacity: 0,
  transition: 'opacity 0.1s',
  pointerEvents: 'none',
}));

const VerticalTrack = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  width: 6,
  borderRadius: 3,
});

const Thumb = styled('div')({
  cursor: 'pointer',
  zIndex: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: 'inherit',
});

const View = styled('div')({
  // This fixes an issue with the thin line of the default browser scrollbar sometimes being visible
  scrollbarWidth: 'thin',
});

const EmptyDiv = () => <div />;

const renderTrackVertical = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <VerticalTrack {...props} />
);

const renderThumb = (props: React.HTMLAttributes<HTMLDivElement>) => <Thumb {...props} />;

const renderView = (props: React.HTMLAttributes<HTMLDivElement>) => <View {...props} />;

// Helper to calculate shadow size based on either number or percentage
const calculateShadowSize = (size: number | string, containerHeight: number) => {
  if (typeof size === 'number') {
    return size; // Return the number as is for pixel values
  } else if (size.endsWith('%')) {
    const percentageValue = parseFloat(size) / 100;
    return percentageValue * containerHeight; // Calculate percentage of the container's height
  }

  return 0; // Fallback for incorrect values
};

// Helper to calculate shadow opacity based on thumb position
const calculateOpacity = (position: number, shadowPixelSize: number) =>
  Math.min(1, position / shadowPixelSize);

export const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
  children,
  showShadows,
  shadowSize = '10%',
  shadowColor,
  ...boxProps
}) => {
  const theme = useTheme();
  const defaultShadowColor = shadowColor ?? theme.tokens.color['background.regular'];

  const shadowTopRef = useRef<HTMLDivElement>(null);
  const shadowBottomRef = useRef<HTMLDivElement>(null);
  const scrollbarsRef = useRef<Scrollbars>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Handle shadow opacity based on scrolling
  const handleUpdate = useCallback(
    (values: positionValues) => {
      if (!showShadows || !containerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = values;
      const containerHeight = containerRef.current.clientHeight;

      // Calculate and apply the opacity values
      const maxThumbPosition = clientHeight * (1 - clientHeight / scrollHeight); // Max thumb movement
      const thumbPosition = (scrollTop / (scrollHeight - clientHeight)) * maxThumbPosition;
      const shadowPixelSize = calculateShadowSize(shadowSize, containerHeight);
      const shadowTopOpacity = calculateOpacity(thumbPosition, shadowPixelSize);
      const shadowBottomOpacity = calculateOpacity(
        maxThumbPosition - thumbPosition,
        shadowPixelSize,
      );

      if (shadowTopRef.current) {
        shadowTopRef.current.style.opacity = `${shadowTopOpacity}`;
      }

      if (shadowBottomRef.current) {
        shadowBottomRef.current.style.opacity = `${shadowBottomOpacity}`;
      }
    },
    [showShadows, shadowSize],
  );

  // Use ResizeObserver to handle size changes of the container
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (scrollbarsRef.current) {
        scrollbarsRef.current.forceUpdate(); // Force update scrollbars on resize
      }
    });

    const container = containerRef.current;
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  return (
    <Box ref={containerRef} position="relative" {...boxProps}>
      <Scrollbars
        ref={scrollbarsRef}
        onUpdate={handleUpdate}
        renderTrackVertical={renderTrackVertical}
        renderThumbVertical={renderThumb}
        renderThumbHorizontal={EmptyDiv} // Hide horizontal thumb
        renderView={renderView}
      >
        {children}
      </Scrollbars>
      {showShadows && (
        <>
          <Shadow ref={shadowTopRef} size={shadowSize} position="top" color={defaultShadowColor} />
          <Shadow
            ref={shadowBottomRef}
            size={shadowSize}
            position="bottom"
            color={defaultShadowColor}
          />
        </>
      )}
    </Box>
  );
};

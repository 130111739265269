import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material';
import { map } from 'lodash-es';
import { FC, Fragment } from 'react';

import { ButtonBase } from '../../../../buttons';
import { CloseIcon } from '../../../../icons';
import { Typography } from '../../../../Typography';

export type AgentMessageInsightSentiment = 'positive' | 'negative';

export interface AgentMessageInsightSummaryTitle {
  title: string;
  sentiment: AgentMessageInsightSentiment;
}

export interface AgentMessageInsightProps {
  title: string;
  summaryTitle: AgentMessageInsightSummaryTitle;
  summaries: string[] & { length: 1 | 2 | 3 | 4 | 5 };
  onCloseClick: () => void;
}

const sentimentColorMap: Record<AgentMessageInsightSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xxxlarge,
}));

const TitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SummaryContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xxlarge,
}));

export const AgentMessageInsight: FC<AgentMessageInsightProps> = ({
  title,
  summaryTitle,
  summaries,
  onCloseClick,
}) => (
  <Container>
    <TitleContainer>
      <Typography variant="heading.H5">{title}</Typography>
      <ButtonBase onClick={onCloseClick}>
        <CloseIcon size="icon.small" />
      </ButtonBase>
    </TitleContainer>
    <SummaryContainer>
      <Typography
        variant="heading.H4"
        color={sentimentColorMap[summaryTitle.sentiment]}
        lineClamp={2}
      >
        {summaryTitle.title}
      </Typography>

      {map(summaries, (summary, index) => (
        <Fragment key={index}>
          <Typography variant="heading.H8" lineClamp={8}>
            {summary}
          </Typography>
        </Fragment>
      ))}
    </SummaryContainer>
  </Container>
);

import SvgIcon, { SvgIconProps } from './SvgIcon';

const LocationIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M13.9997 2.33301C9.48467 2.33301 5.83301 5.98467 5.83301 10.4997C5.83301 16.6247 13.9997 25.6663 13.9997 25.6663C13.9997 25.6663 22.1663 16.6247 22.1663 10.4997C22.1663 5.98467 18.5147 2.33301 13.9997 2.33301ZM8.16634 10.4997C8.16634 7.27967 10.7797 4.66634 13.9997 4.66634C17.2197 4.66634 19.833 7.27967 19.833 10.4997C19.833 13.8597 16.473 18.888 13.9997 22.0263C11.573 18.9113 8.16634 13.8247 8.16634 10.4997Z" />
    <path d="M13.9997 13.4163C15.6105 13.4163 16.9163 12.1105 16.9163 10.4997C16.9163 8.88884 15.6105 7.58301 13.9997 7.58301C12.3888 7.58301 11.083 8.88884 11.083 10.4997C11.083 12.1105 12.3888 13.4163 13.9997 13.4163Z" />
  </SvgIcon>
);

export default LocationIcon;

import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NextIcon, PauseIcon, PlayIcon, PrevIcon } from '../../../icons';

interface AgentFooterProps {
  isActive: boolean;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  onClickPlayPlause: () => void;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const ButtonContainer = styled(IconButton)(({ theme }) => ({
  width: 34,
  height: 34,
  transition: 'opacity 0.3s',
  backgroundColor: theme.tokens.color['background.light'],
  color: theme.tokens.color['text.regular'],
  '&.Mui-disabled': {
    opacity: 0.5,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 40,
  },
}));

const PlayPauseButtonContainer = styled(IconButton)(({ theme }) => ({
  opacity: 1,
  color: theme.tokens.color['text.regular'],
  width: 44,
  height: 44,
  backgroundColor: theme.tokens.color['background.light'],
  borderRadius: '50%',
  border: '2px solid rgba(255, 255, 255, 0.1)', // Missing color token
  '&.Mui-disabled': {
    opacity: 0.5,
  },
}));

export function AgentFooter({
  isActive,
  isNextDisabled,
  isPreviousDisabled,
  onClickPlayPlause,
  onClickNext,
  onClickPrevious,
}: Readonly<AgentFooterProps>) {
  return (
    <Container>
      <ButtonContainer
        onClick={onClickPrevious}
        disabled={isPreviousDisabled}
        data-testid="agent-footer__prev-button"
      >
        <PrevIcon />
      </ButtonContainer>
      <PlayPauseButtonContainer onClick={onClickPlayPlause}>
        {isActive ? <PauseIcon size="icon.xxsmall" /> : <PlayIcon size="icon.xxsmall" />}
      </PlayPauseButtonContainer>
      <ButtonContainer
        onClick={onClickNext}
        disabled={isNextDisabled}
        data-testid="agent-footer__next-button"
      >
        <NextIcon />
      </ButtonContainer>
    </Container>
  );
}

import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';

import { AiIcon } from '../icons';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import TimelineAccordionItem, { TimelineAccordionItemProps } from './TimelineAccordionItem';

export interface TimelineAccordionProps<TLinkData = Record<string, unknown>> {
  title: string;
  subtitle?: string;
  items: TimelineAccordionItemProps<TLinkData>[];
  autoExpand?: boolean;
  defaultExpanded?: number[];
  onLinkClick?: (data: TLinkData) => void;
}

const oldAquaColor = '#00a19b'; // Old color used instead of a color token

const TimelineAccordionRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.tokens.spacing['medium'],
  width: '100%',
  borderRadius: theme.tokens.borderRadius['medium'],
  border: `1px solid ${oldAquaColor}`,
  padding: theme.tokens.spacing['medium'],
  paddingTop: theme.tokens.spacing['large'],
  background: `linear-gradient(180deg, ${alpha(oldAquaColor, 0)} 60%, ${alpha(oldAquaColor, 0.2)} 120%)`,
}));

const TimelineAccordion = <TLinkData = Record<string, unknown>,>({
  title,
  subtitle,
  items,
  autoExpand,
  defaultExpanded = [],
  onLinkClick,
}: TimelineAccordionProps<TLinkData>) => {
  const initialExpanded = autoExpand ? items.map((_, index) => index) : defaultExpanded;
  const [expanded, setExpanded] = useState<number[]>(initialExpanded);

  const handleToggle = (index: number) => {
    setExpanded((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const handleLinkClick = (data: TLinkData) => {
    onLinkClick?.(data);
  };

  return (
    <TimelineAccordionRoot role="region" aria-label="Timeline Accordion">
      <Stack direction="row" alignItems="center" spacing="small">
        <AiIcon size="icon.medium-large" />
        <Stack direction="column" justifyContent="center" sx={{ height: 36 }}>
          <Typography variant="heading.H9" lineClamp={1}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body.small" color="text.subtle" lineClamp={1}>
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="column" alignSelf="stretch">
        {items.map((item, index) => {
          const { title, iconName, sentiment, description, link } = item;
          return (
            <TimelineAccordionItem
              key={index}
              title={title}
              iconName={iconName}
              sentiment={sentiment}
              description={description}
              link={link}
              isLast={index === items.length - 1}
              isExpanded={expanded.includes(index)}
              onToggle={() => handleToggle(index)}
              onLinkClick={handleLinkClick}
            />
          );
        })}
      </Stack>
    </TimelineAccordionRoot>
  );
};

export default TimelineAccordion;

import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';

import { StarIcon } from '../../../../../../icons';
import { Typography } from '../../../../../../Typography';
import { ProgressBar } from '../ProgressBar';

export interface ProgressBarRowProps {
  /**
   * Value displayed on the left.
   */
  primaryValue: string;
  /**
   * Raw value used for the progress bar calculation.
   */
  rawValue: number;
  /**
   * Absolute maximum value representing 100%.
   */
  absoluteMax: number;
  /**
   * Whether to show a star icon before the primary value.
   */
  showStarIcon?: boolean;
  /**
   * Optional value displayed on the right.
   */
  secondaryValue?: string;
  /**
   * Optional label shown below.
   */
  label?: string;
  /**
   * Width of the progress bar container.
   */
  progressBarWidth?: string | number;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.tokens.spacing['xxsmall'],
  width: '100%',
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.tokens.spacing['xsmall'],
  width: '100%',
}));

const IconAndValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.tokens.spacing['xxsmall'],
}));

export const ProgressBarRow: React.FC<ProgressBarRowProps> = ({
  primaryValue,
  rawValue,
  absoluteMax,
  showStarIcon,
  secondaryValue,
  label,
  progressBarWidth = 160,
}) => {
  // Calculate progressValue as a percentage of the absoluteMax
  const progressValue = useMemo(() => (rawValue / absoluteMax) * 100, [rawValue, absoluteMax]);

  return (
    <Root>
      <Content>
        <IconAndValueContainer>
          {showStarIcon && <StarIcon size="icon.small" color="neutral.grey-lighter" />}
          <Typography variant="heading.H8" lineClamp={1} sx={{ width: 80 }}>
            {primaryValue}
          </Typography>
        </IconAndValueContainer>
        <ProgressBar value={progressValue} width={progressBarWidth} />
        {secondaryValue && (
          <Typography
            variant="heading.H8"
            align="right"
            lineClamp={1}
            sx={{ width: 80, marginLeft: 'auto' }}
          >
            {secondaryValue}
          </Typography>
        )}
      </Content>
      {label && (
        <Typography variant="body.xsmall-condensed" lineClamp={1}>
          {label}
        </Typography>
      )}
    </Root>
  );
};

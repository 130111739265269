import * as T from '@aily/graphql-sdk/schema';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CardProps,
  styled,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';

import { lineClamp } from '../../theme/utils';
import { mapSentimentToColor } from '../../utils';
import { FitText } from '../FitText';
import { MicroLineChart } from '../MicroLineChart';

interface Props extends CardProps {
  card: T.MicroChartCardResult;
  onCardClick?: () => void;
}

interface StyledCardProps {
  isKpiCard?: boolean;
}

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isKpiCard',
})<StyledCardProps>(({ theme, isKpiCard }) => ({
  width: 235,
  height: 154,
  '& .MuiCardActionArea-root': {
    height: '100%',
    ...(isKpiCard && {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }),
  },
  '& .MuiCardHeader-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 52,
    padding: theme.spacing(2, 1.75),
    paddingBottom: 0,
    '& .MuiTypography-root, & .MuiTypography-root + .MuiTypography-root': {
      ...lineClamp(isKpiCard ? 2 : 1),
    },
    '& .MuiTypography-root:only-child': {
      ...lineClamp(2),
    },
  },
  '& .MuiCardContent-root': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingTop: isKpiCard ? undefined : 0,
    marginTop: isKpiCard ? theme.spacing(2) : undefined,
  },
  '& .MuiCardMedia-root': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '50%',
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
  [theme.breakpoints.up('xl')]: {
    width: 263,
    height: 170,
  },
  [theme.breakpoints.up('xxl')]: {
    width: 338,
    height: 220,
  },
}));

const MicroChartCard: FC<Props> = ({ card, onCardClick, ...rest }) => {
  return (
    <StyledCard {...rest}>
      <CardActionArea component="div" onClick={onCardClick}>
        <CardHeader
          disableTypography
          title={
            <Typography variant="h9" data-testid="Title">
              <FitText minFontSize={90}>{card.title.value ?? ''}</FitText>
            </Typography>
          }
          subheader={
            !!card.title.subLabels?.length && (
              <Typography variant="small" color="text.secondary">
                {card.title.subLabels[0]}
              </Typography>
            )
          }
        />
        <CardContent>
          <Typography variant="body" data-testid="actual">
            {card.actual?.value ?? 0}
          </Typography>
          {!!card.variance?.actual && (
            <Typography
              variant="body"
              color={mapSentimentToColor(card.variance?.sentiment ?? T.Sentiment.Neutral)}
              data-testid="variance"
            >
              {card.variance.actual}
            </Typography>
          )}
        </CardContent>
        <CardMedia>
          <MicroLineChart
            data={(card.microChart?.seriesData ?? []) as number[]}
            trendlineData={(card.microChart?.trendlineData ?? []) as number[]}
            color={mapSentimentToColor(
              card.microChart?.sentiment ?? card.variance?.sentiment ?? T.Sentiment.Neutral,
            )}
            lineWidth={2}
            trendlineWidth={0.5}
            trendlineOpacity={0.5}
            fillOpacity={1}
            offsetTop={0.1}
            offsetBottom={0.3}
            animated
          />
        </CardMedia>
      </CardActionArea>
    </StyledCard>
  );
};

export default MicroChartCard;

import { ColorTokenKey } from '@aily/ui-theme';
import { Fade } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { useId } from 'react';

import { ButtonBase, MaybeButtonBase } from '../buttons';
import { ChevronDownIcon, ChevronUpIcon, IconName, icons, OpenInNewIcon } from '../icons';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

export interface TimelineAccordionItemProps<TLinkData = Record<string, unknown>> {
  title: string;
  iconName: IconName;
  sentiment?: TimelineAccordionItemSentiment;
  description?: string;
  link?: TimelineAccordionItemLink<TLinkData>;
  isLast?: boolean;
  isExpanded?: boolean;
  onToggle?: () => void;
  onLinkClick?: (data: TLinkData) => void;
}

export type TimelineAccordionItemSentiment = 'positive' | 'negative' | 'warning' | 'neutral';

export type TimelineAccordionItemLink<TLinkData> = { label: string; data: TLinkData };

const sentimentColorMap: Record<TimelineAccordionItemSentiment, ColorTokenKey> = {
  positive: 'brand.aqua',
  negative: 'brand.pink',
  warning: 'brand.orange',
  neutral: 'neutral.grey-light',
};

const TimelineAccordionItemRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: theme.tokens.spacing['small'],
  minHeight: 57,
}));

const IconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: ColorTokenKey }>(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: 32,
  border: `2px solid ${theme.tokens.color[color]}`,
}));

const ConnectorLine = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: ColorTokenKey }>(({ theme, color }) => ({
  width: 2,
  flexGrow: 1,
  backgroundColor: theme.tokens.color[color],
}));

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.tokens.spacing['xxsmall'],
  width: '100%',
  height: 32,
}));

const DescriptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderTop: `1px solid ${theme.tokens.color['neutral.grey']}`,
  padding: theme.spacing(theme.tokens.spacing['xsmall'], 0),
}));

const LinkButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: theme.tokens.spacing['xxsmall'],
  padding: theme.spacing(theme.tokens.spacing['xsmall'], 0, theme.tokens.spacing['medium']),
}));

const TimelineAccordionItem = <TLinkData,>({
  title,
  iconName,
  sentiment = 'neutral',
  description,
  link,
  isLast,
  isExpanded,
  onToggle,
  onLinkClick,
}: TimelineAccordionItemProps<TLinkData>) => {
  const id = useId();
  const headerId = `timeline-accordion__header-${id}`;
  const panelId = `timeline-accordion__panel-${id}`;

  const IconComponent = icons[iconName];
  const color = sentimentColorMap[sentiment];
  // Enable expanding only if there's a description or a link
  const isExpandable = Boolean(description || link);
  const ExpandIconComponent = isExpanded ? ChevronUpIcon : ChevronDownIcon;

  return (
    <TimelineAccordionItemRoot>
      <Stack direction="column" alignItems="center" alignSelf="stretch">
        <IconContainer color={color}>
          <IconComponent size="icon.xxsmall" color="text.regular" />
        </IconContainer>
        {!isLast && <ConnectorLine color={color} />}
      </Stack>
      <Stack flex={1}>
        <MaybeButtonBase
          id={headerId}
          onClick={isExpandable ? onToggle : undefined}
          aria-expanded={isExpandable ? isExpanded : undefined}
          aria-controls={isExpandable ? panelId : undefined}
        >
          <TitleContainer>
            <Typography variant="body.regular-condensed" lineClamp={1}>
              {title}
            </Typography>
            {isExpandable && <ExpandIconComponent size="icon.xxsmall" color="text.subtle" />}
          </TitleContainer>
        </MaybeButtonBase>
        {isExpandable && (
          <Collapse in={isExpanded} timeout={300} unmountOnExit>
            <Fade in={isExpanded} timeout={300}>
              <div id={panelId} role="region" aria-labelledby={headerId}>
                {description && (
                  <DescriptionContainer>
                    <Typography variant="body.small" sx={{ whiteSpace: 'pre-wrap' }}>
                      {description}
                    </Typography>
                  </DescriptionContainer>
                )}
                {link && (
                  <LinkButton onClick={() => onLinkClick?.(link.data)}>
                    <OpenInNewIcon size="icon.xsmall" color="brand.aqua" />
                    <Typography variant="body.xsmall-bold" color="brand.aqua" lineClamp={1}>
                      {link.label}
                    </Typography>
                  </LinkButton>
                )}
              </div>
            </Fade>
          </Collapse>
        )}
      </Stack>
    </TimelineAccordionItemRoot>
  );
};

export default TimelineAccordionItem;

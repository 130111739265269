import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ShareIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M20.5625 18.1562C19.7832 18.1562 19.0641 18.4297 18.5008 18.8863L12.8352 14.7875C12.93 14.2668 12.93 13.7332 12.8352 13.2125L18.5008 9.11367C19.0641 9.57031 19.7832 9.84375 20.5625 9.84375C22.3727 9.84375 23.8438 8.37266 23.8438 6.5625C23.8438 4.75234 22.3727 3.28125 20.5625 3.28125C18.7523 3.28125 17.2812 4.75234 17.2812 6.5625C17.2812 6.87969 17.325 7.1832 17.4098 7.47305L12.0285 11.3695C11.2301 10.3113 9.96133 9.625 8.53125 9.625C6.11406 9.625 4.15625 11.5828 4.15625 14C4.15625 16.4172 6.11406 18.375 8.53125 18.375C9.96133 18.375 11.2301 17.6887 12.0285 16.6305L17.4098 20.527C17.325 20.8168 17.2812 21.123 17.2812 21.4375C17.2812 23.2477 18.7523 24.7188 20.5625 24.7188C22.3727 24.7188 23.8438 23.2477 23.8438 21.4375C23.8438 19.6273 22.3727 18.1562 20.5625 18.1562ZM20.5625 5.14062C21.3473 5.14062 21.9844 5.77773 21.9844 6.5625C21.9844 7.34727 21.3473 7.98438 20.5625 7.98438C19.7777 7.98438 19.1406 7.34727 19.1406 6.5625C19.1406 5.77773 19.7777 5.14062 20.5625 5.14062ZM8.53125 16.4062C7.20508 16.4062 6.125 15.3262 6.125 14C6.125 12.6738 7.20508 11.5938 8.53125 11.5938C9.85742 11.5938 10.9375 12.6738 10.9375 14C10.9375 15.3262 9.85742 16.4062 8.53125 16.4062ZM20.5625 22.8594C19.7777 22.8594 19.1406 22.2223 19.1406 21.4375C19.1406 20.6527 19.7777 20.0156 20.5625 20.0156C21.3473 20.0156 21.9844 20.6527 21.9844 21.4375C21.9844 22.2223 21.3473 22.8594 20.5625 22.8594Z" />
  </SvgIcon>
);

export default ShareIcon;

import { useTheme } from '@mui/material';
import React, { useId } from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const AiIcon: React.FC<SvgIconProps> = ({ textColor, ...rest }) => {
  const theme = useTheme();
  const textColorValue = textColor ? theme.tokens.color[textColor] : '#fff';
  const id = useId();
  return (
    <SvgIcon width="30" height="30" viewBox="0 0 30 30" {...rest}>
      <defs>
        <linearGradient id={`${id}-gradient`} x2="100%">
          <stop stopColor="#31d4b6" />
          <stop offset="1" stopColor="#48ff9b" />
        </linearGradient>
      </defs>
      <path
        d="M22.187 17.9295C22.187 18.2406 22.0804 18.4939 21.867 18.6895C21.6626 18.885 21.4137 18.9828 21.1204 18.9828C20.8182 18.9828 20.5648 18.885 20.3604 18.6895C20.1559 18.4939 20.0537 18.2406 20.0537 17.9295C20.0537 17.6183 20.1559 17.365 20.3604 17.1695C20.5648 16.965 20.8182 16.8628 21.1204 16.8628C21.4137 16.8628 21.6626 16.965 21.867 17.1695C22.0804 17.365 22.187 17.6183 22.187 17.9295Z"
        fill="#31d4b6"
      />
      <path
        d="M18.315 18.8092H16.7017V11.8359H18.315V18.8092ZM18.4884 9.7959C18.4884 10.0981 18.3906 10.3381 18.195 10.5159C17.9995 10.6937 17.7684 10.7826 17.5017 10.7826C17.2262 10.7826 16.9906 10.6937 16.795 10.5159C16.5995 10.3381 16.5017 10.0981 16.5017 9.7959C16.5017 9.48479 16.5995 9.24034 16.795 9.06257C16.9906 8.88479 17.2262 8.7959 17.5017 8.7959C17.7684 8.7959 17.9995 8.88479 18.195 9.06257C18.3906 9.24034 18.4884 9.48479 18.4884 9.7959Z"
        fill={textColorValue}
      />
      <path
        d="M12.0197 17.6759C12.5174 17.6759 12.8952 17.6493 13.153 17.5959V15.8093C13.0641 15.7826 12.9352 15.7559 12.7663 15.7293C12.5974 15.7026 12.4108 15.6893 12.2063 15.6893C12.0286 15.6893 11.8463 15.7026 11.6597 15.7293C11.4819 15.7559 11.3174 15.8048 11.1663 15.8759C11.0241 15.947 10.9086 16.0493 10.8197 16.1826C10.7308 16.307 10.6863 16.467 10.6863 16.6626C10.6863 17.0448 10.8063 17.3115 11.0463 17.4626C11.2863 17.6048 11.6108 17.6759 12.0197 17.6759ZM11.8863 11.6626C12.4197 11.6626 12.8686 11.7293 13.233 11.8626C13.5974 11.9959 13.8863 12.1826 14.0997 12.4226C14.3219 12.6626 14.4774 12.9559 14.5663 13.3026C14.6641 13.6404 14.713 14.0137 14.713 14.4226V18.6493C14.4641 18.7026 14.0863 18.7648 13.5797 18.8359C13.0819 18.9159 12.5174 18.9559 11.8863 18.9559C11.4686 18.9559 11.0863 18.9159 10.7397 18.8359C10.393 18.7559 10.0952 18.627 9.84633 18.4493C9.60633 18.2715 9.41522 18.0404 9.273 17.7559C9.13966 17.4715 9.073 17.1204 9.073 16.7026C9.073 16.3026 9.14855 15.9648 9.29966 15.6893C9.45966 15.4137 9.673 15.1915 9.93966 15.0226C10.2063 14.8448 10.513 14.7204 10.8597 14.6493C11.2152 14.5693 11.5841 14.5293 11.9663 14.5293C12.1441 14.5293 12.3308 14.5426 12.5263 14.5693C12.7219 14.587 12.9308 14.6226 13.153 14.6759V14.4093C13.153 14.2226 13.1308 14.0448 13.0863 13.8759C13.0419 13.707 12.9619 13.5604 12.8463 13.4359C12.7397 13.3026 12.593 13.2004 12.4063 13.1293C12.2286 13.0582 12.0019 13.0226 11.7263 13.0226C11.353 13.0226 11.0108 13.0493 10.6997 13.1026C10.3886 13.1559 10.1352 13.2182 9.93966 13.2893L9.73966 11.9826C9.94411 11.9115 10.2419 11.8404 10.633 11.7693C11.0241 11.6982 11.4419 11.6626 11.8863 11.6626Z"
        fill={textColorValue}
      />
      <path
        d="M13.8095 1.19048C13.8095 0.532994 14.3425 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 14.3425 0.532994 13.8095 1.19048 13.8095C1.84796 13.8095 2.38095 14.3425 2.38095 15C2.38095 21.9693 8.03069 27.619 15 27.619C21.9693 27.619 27.619 21.9693 27.619 15C27.619 8.03069 21.9693 2.38095 15 2.38095C14.3425 2.38095 13.8095 1.84796 13.8095 1.19048Z"
        fill={`url(#${id}-gradient)`}
      />
    </SvgIcon>
  );
};

export default AiIcon;

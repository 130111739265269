import { styled } from '@mui/material';
import React from 'react';

import { Stack } from '../../../../Stack';
import {
  TitleBarHorizontalStack,
  TitleBarHorizontalStackProps,
} from './internal/TitleBarHorizontalStack/TitleBarHorizontalStack';

export interface ProgressBarSectionedTemplateProps {
  sections: TitleBarHorizontalStackProps[];
}

const SectionsContainerStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.tokens.spacing.xxlarge,
}));

export const ProgressBarSectionedTemplate: React.FC<ProgressBarSectionedTemplateProps> = ({
  sections,
}) => {
  return (
    <SectionsContainerStack spacing={'large'}>
      {sections.map((section, index) => (
        <TitleBarHorizontalStack key={index} title={section.title} rows={section.rows} />
      ))}
    </SectionsContainerStack>
  );
};

import { EventTrackingConfig, getPathDetails, withEventTracking } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { alpha } from '@mui/material';
import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useUpdateEffect } from 'react-use';
import { makeStyles } from 'tss-react/mui';

import { StoryContextProvider } from '../../contexts';
import TrackedStoryView from '../StoryView/TrackedStoryView';
import StorySliderNavigation from './StorySliderNavigation';

const maxWidth = 1360;
const baseFontSize = 15;
const transition = 'all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)';
const ratio = 656 / 454;

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    maxWidth: `${maxWidth}px`,
    '&::before, &::after': {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      width: 'calc(100% / 10)',
      height: '100%',
      content: '""',
      pointerEvents: 'none',
      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% / 4)',
      },
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% / 6)',
      },
    },
    '&.isWide': {
      '&::before, &::after': {
        width: 20,
      },
    },
    '&::before': {
      left: 0,
      backgroundImage: `linear-gradient(
        90deg,
        ${theme.palette.background.default} 0%,
        ${alpha(theme.palette.background.default, 0)} 100%
      )`,
    },
    '&::after': {
      right: 0,
      backgroundImage: `linear-gradient(
        270deg,
        ${theme.palette.background.default} 0%,
        ${alpha(theme.palette.background.default, 0)} 100%
      )`,
    },
    '@media (max-height: 899.98px) and (orientation: landscape)': {
      maxWidth: `${ratio * 100}vh`,
    },
  },
  slider: {
    position: 'relative',
    overflow: 'hidden',
    padding: '80px 10%',
    outline: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '80px calc(100% / 4)',
    },
    [theme.breakpoints.up('md')]: {
      padding: '80px calc(100% / 3)',
    },
    '& .carousel__slider-tray--horizontal': {
      transition,
      transitionProperty: 'transform',
      willChange: 'transform',
    },
  },
  slide: {
    position: 'relative',
    float: 'left',
    transition,
    transitionProperty: 'transform',
    willChange: 'transform',
    '&.carousel__slide--hidden': {
      transform: 'scale(0.75)',
    },
    '& .carousel__inner-slide': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      transition: 'all 0.5s',
      transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      transitionProperty: 'width, left',
      willChange: 'width, left',
      pointerEvents: 'all',
    },
    '&.carousel__slide--hidden .carousel__inner-slide': {
      pointerEvents: 'none',
    },
    '&.isWide .carousel__inner-slide': {
      [theme.breakpoints.up('sm')]: {
        width: 'calc(200% - 40px)',
        left: 'calc(-50% + 20px)',
      },
      [theme.breakpoints.up('md')]: {
        width: 'calc(300% - 40px)',
        left: 'calc(-100% + 20px)',
      },
    },
    '&.carousel__slide--visible.isWide': {
      zIndex: 2,
    },
    '&.carousel__slide--visible + .carousel__slide--hidden': {
      zIndex: 1,
    },
  },
  button: {
    position: 'absolute',
    top: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
    transition,
    transitionProperty: 'left, opacity',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    borderRadius: '50%',
    width: 28,
    height: 28,
    backgroundColor: isSafari ? '#ffffff' : theme.palette.text.primary,
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[1],
    '&[disabled]': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
  buttonBack: {
    left: 'calc(100% / 10)',
    [theme.breakpoints.up('sm')]: {
      left: 'calc(100% / 4)',
    },
    [theme.breakpoints.up('md')]: {
      left: 'calc(100% / 3)',
    },
    '&.isWide': {
      left: '10%',
      zIndex: 30,
      [theme.breakpoints.up('sm')]: {
        left: 20,
      },
    },
  },
  buttonNext: {
    left: 'calc(100% / 10 * 9)',
    [theme.breakpoints.up('sm')]: {
      left: 'calc(100% / 4 * 3)',
    },
    [theme.breakpoints.up('md')]: {
      left: 'calc(100% / 3 * 2)',
    },
    '&.isWide': {
      left: '90%',
      zIndex: 30,
      [theme.breakpoints.up('sm')]: {
        left: 'calc(100% - 20px)',
      },
    },
  },
  story: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${(baseFontSize * 100) / theme.breakpoints.values.sm}vw`,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: `${(baseFontSize * 100) / theme.breakpoints.values.md}vw`,
    },
    [theme.breakpoints.between('md', maxWidth)]: {
      fontSize: `${(baseFontSize * 100) / maxWidth}vw`,
    },
    '@media (max-height: 899.98px) and (orientation: landscape)': {
      fontSize: `${(15 * (ratio * 100)) / maxWidth}vh`,
    },
  },
}));

export interface StorySliderProps {
  stories: T.Story[];
  defaultSlide?: number;
  setParentClassName?: React.Dispatch<React.SetStateAction<string[]>>;
  isPlaying?: boolean;
  onCurrentSlideChange?: (story: T.Story, index: number) => void;
  onSliderNavigationButtonClick?: () => void;
  onStoryRead?: (story: T.Story) => void;
  onStorySeeMoreButtonClick?: (story: T.Story) => void;
  onStoryResetButtonClick?: (story: T.Story) => void;
  onStoryReferenceClick?: (story: T.Story, reference: T.ExternalLink) => void;
  onTrackSeeMore?: (story: T.Story) => void;
  onTrackReferenceClick?: (story: T.Story, reference: T.ExternalLink) => void;
  onTrackSwipe?: (story: T.Story) => void;
  onTrackSee?: (story: T.Story) => void;
  onUserInteract?: () => void;
}

const StorySlider: React.FC<StorySliderProps> = ({
  stories,
  defaultSlide,
  setParentClassName,
  onCurrentSlideChange,
  onSliderNavigationButtonClick,
  onStoryRead,
  onStorySeeMoreButtonClick,
  onStoryResetButtonClick,
  onStoryReferenceClick,
  onTrackSeeMore,
  onTrackReferenceClick,
  onTrackSwipe,
  onTrackSee,
  onUserInteract,
}) => {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(defaultSlide ?? 0);
  const { classes, cx } = useStyles();
  const lastSeenStoryIdRef = useRef<string | undefined>(undefined);
  const isSeeMoreButtonHoveredRef = useRef<boolean>(false);

  const isWide = useCallback((story: T.Story): boolean => {
    if (story.storyType === T.StoryDisplayType.Media) {
      return (
        !!story?.content && story.content.length === 1 && !!story.content.find(T.isVideoComponent)
      );
    }

    return !!story.content?.some(
      (component) =>
        T.isTableComponent(component) ||
        T.isTableSwitcherComponent(component) ||
        T.isChartComponent(component),
    );
  }, []);

  useEffect(() => {
    const currentStory = stories[currentSlide];

    if (currentStory?.id !== lastSeenStoryIdRef.current) {
      onTrackSee?.(currentStory);
    }

    lastSeenStoryIdRef.current = currentStory?.id;
  }, [currentSlide, stories, onTrackSee]);

  const onChange = useCallback(() => {
    const currentStory = stories[carouselContext.state.currentSlide];

    if (
      !isSeeMoreButtonHoveredRef.current &&
      !carouselContext.state.masterSpinnerFinished &&
      lastSeenStoryIdRef.current !== currentStory?.id
    ) {
      onTrackSwipe?.(currentStory);
    }

    setCurrentSlide(carouselContext.state.currentSlide);
  }, [carouselContext, stories, isSeeMoreButtonHoveredRef, onTrackSwipe]);

  useEffect(() => {
    if (stories[currentSlide] && isWide(stories[currentSlide])) {
      setParentClassName?.((prevState) => [...Array.from(new Set([...prevState, 'isWide']))]);
    } else {
      setParentClassName?.((prevState) => prevState.filter((c) => c !== 'isWide'));
    }
  }, [stories, currentSlide, isWide, setParentClassName]);

  useEffect(() => {
    carouselContext.subscribe(onChange);

    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, onChange]);

  useUpdateEffect(() => {
    const currentStory = stories[currentSlide];

    if (currentStory) {
      onCurrentSlideChange?.(currentStory, currentSlide);
    }
  }, [currentSlide, stories, onCurrentSlideChange]);

  const handleStorySeeMoreButtonClick = useCallback(
    (story: T.Story) => {
      onTrackSeeMore?.(story);
      onStorySeeMoreButtonClick?.(story);
    },
    [onStorySeeMoreButtonClick, onTrackSeeMore],
  );

  const handleStoryReferenceClick = useCallback(
    (story: T.Story, reference: T.ExternalLink) => {
      onTrackReferenceClick?.(story, reference);
      onStoryReferenceClick?.(story, reference);
    },
    [onStoryReferenceClick, onTrackReferenceClick],
  );

  const toggleIsSeeMoreButtonHovered = useCallback(
    (isHovered: boolean) => {
      isSeeMoreButtonHoveredRef.current = isHovered;
    },
    [isSeeMoreButtonHoveredRef],
  );

  return (
    <>
      {stories.length > 1 && <StorySliderNavigation />}
      <Slider className={classes.slider}>
        {stories.map((story, index) => {
          const distanceFromCurrentItem = Math.abs(index - currentSlide);
          const rangeOffset = 3;

          return (
            <Slide
              key={index}
              className={cx(classes.slide, index === currentSlide && isWide(story) && 'isWide')}
              index={index}
            >
              <StoryContextProvider story={story} onStoryReferenceClick={handleStoryReferenceClick}>
                <TrackedStoryView
                  className={classes.story}
                  story={story}
                  loading={distanceFromCurrentItem >= rangeOffset}
                  distanceFromCurrentItem={distanceFromCurrentItem}
                  onRead={onStoryRead}
                  onUserInteract={onUserInteract}
                  onSeeMoreButtonClick={handleStorySeeMoreButtonClick}
                  onSeeMoreButtonHoverChange={toggleIsSeeMoreButtonHovered}
                  onResetButtonClick={onStoryResetButtonClick}
                  fullWidth={isWide(story)}
                />
              </StoryContextProvider>
            </Slide>
          );
        })}
      </Slider>
      <ButtonBack
        className={cx(
          classes.button,
          classes.buttonBack,
          isWide(stories[currentSlide]) && 'isWide',
        )}
        onClick={onSliderNavigationButtonClick}
      >
        <ChevronLeft />
      </ButtonBack>
      <ButtonNext
        className={cx(
          classes.button,
          classes.buttonNext,
          isWide(stories[currentSlide]) && 'isWide',
        )}
        onClick={onSliderNavigationButtonClick}
      >
        <ChevronRight />
      </ButtonNext>
    </>
  );
};

const StoryCarouselProvider: React.FC<StorySliderProps> = ({
  stories,
  defaultSlide,
  isPlaying,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  const [className, setClassName] = useState([classes.root]);

  return (
    <CarouselProvider
      className={cx(className)}
      totalSlides={stories.length}
      naturalSlideWidth={454}
      naturalSlideHeight={656}
      currentSlide={defaultSlide}
      isPlaying={isPlaying}
      interval={7000}
      dragEnabled={false}
    >
      <StorySlider
        stories={stories}
        defaultSlide={defaultSlide}
        setParentClassName={setClassName}
        {...rest}
      />
    </CarouselProvider>
  );
};

const trackingConfig: EventTrackingConfig<StorySliderProps> = {
  onTrackSeeMore: {
    eventName: 'story.redirected',
    getEventProps: (story) => {
      const { focusArea, focusAreaPath } = getPathDetails(story.link?.absolutePath ?? '');
      return {
        component: 'story',
        intent: 'redirect',
        item_type: 'button',
        component_id: story.id,
        component_type: story.storyType,
        event_version: '2.0.0',
        name: story.headline,
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
        url: story.link?.absolutePath ?? '',
      };
    },
  },
  onTrackReferenceClick: {
    eventName: 'story.redirected',
    getEventProps: (story, reference) => {
      const { focusArea, focusAreaPath } = getPathDetails(story.link?.absolutePath ?? '');
      return {
        component: 'story',
        intent: 'redirect',
        item_type: 'hyperlink',
        component_id: story.id,
        component_type: story.storyType,
        url: reference?.url ?? '',
        event_version: '2.0.0',
        name: story.headline,
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
  onTrackSwipe: {
    eventName: `story.swiped`,
    getEventProps: (story) => {
      const { focusArea, focusAreaPath } = getPathDetails(story.link?.absolutePath ?? '');
      return {
        component: 'story',
        intent: 'swipe',
        item_type: 'button',
        component_id: story.id,
        component_type: story.storyType,
        event_version: '2.1.0',
        name: story.headline,
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
  onTrackSee: {
    eventName: `story.seen`,
    getEventProps: (story) => {
      const { focusArea, focusAreaPath } = getPathDetails(story.link?.absolutePath ?? '');

      return {
        component: 'story',
        intent: 'see',
        item_type: 'story',
        component_id: story.id,
        component_type: story.storyType,
        event_version: '2.1.0',
        name: story.headline,
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
};

export default withEventTracking(StoryCarouselProvider, trackingConfig);

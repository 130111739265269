import * as T from '@aily/graphql-sdk/schema';
import { Theme } from '@mui/material';
import { kebabCase } from 'lodash-es';
import React from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';

export const headerRowHeight = 48;

export const baseStickyStyle: React.CSSProperties = {
  position: 'sticky',
  zIndex: 1,
  top: isSafari || isMobileSafari ? 0 : headerRowHeight,
};

export const aggregationRowCellStyle = (theme: Theme): React.CSSProperties => ({
  ...baseStickyStyle,
  ...theme.typography.bodyBold,
  backgroundColor: theme.palette.background.default,
});

export const headerRowCellStyle = (theme: Theme): React.CSSProperties => ({
  ...baseStickyStyle,
  ...theme.typography.bodyBold,
  backgroundColor: theme.palette.divider,
});

export const hoverableRowIconCellStyle: React.CSSProperties = {
  visibility: 'visible',
};

/**
 * Generates a data-testid for the selectable column
 * @param column - The column to generate the data-testid for
 * @param moduleCode - The code of the module
 * @returns The data-testid for the selectable column in the format of `module-code-column-data-key-column-select`
 */
export const selectableColumnDataTestGenerator = (
  column: T.TableColumnResult,
  moduleCode?: string,
) => {
  return kebabCase(`${moduleCode}-${column.dataKey}-column-select`);
};

import { defaultTheme } from '@aily/ui-theme';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface ValueMarkerProps {
  value?: number | null;
  color?: string;
  size?: number;
}

/**
 * Using `makeStyles` because the component is rendered with `ReactDOMServer.renderToString`, ensuring styles are correctly attached.
 */
const useStyles = makeStyles<{ color: string; size: number }>()((theme, { color, size }) => ({
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the circle
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundColor: color,
    color: theme.tokens.color['text.regular'],
    pointerEvents: 'none', // Avoid interfering with tooltip/clicks
    ...theme.tokens.typography['body.xsmall-bold-condensed'],
  },
}));

const formatValue = (value: number): string => {
  const maximumFractionDigits = value >= 10 ? 0 : 1;

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
};

const ValueMarker: React.FC<ValueMarkerProps> = ({
  value,
  color = defaultTheme.color['brand.orange'],
  size = 66,
}) => {
  const { classes } = useStyles({ color, size });
  return <div className={classes.circle}>{formatValue(value ?? 0)}</div>;
};

export default ValueMarker;

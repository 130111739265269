import { styled } from '@mui/material/styles';
import React from 'react';

import { ScrollableContainer } from '../../../../ScrollableContainer';
import { Stack } from '../../../../Stack';
import { Typography } from '../../../../Typography';
import ParagraphSentimentList, {
  ParagraphSentimentListProps,
} from './internal/ParagraphSentimentList/ParagraphSentimentList';

export interface RecommenderBaseTemplateProps {
  title: string;
  subtitle: string;
  paragraphList: ParagraphSentimentListProps['rows'];
}

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.tokens.spacing.medium,
}));

const ScrollableListContainer = styled(ScrollableContainer)({
  height: '287px',
});

export const RecommendationsBaseTemplate: React.FC<RecommenderBaseTemplateProps> = ({
  title,
  subtitle,
  paragraphList,
}) => {
  return (
    <Container>
      <Stack>
        <Typography variant="heading.H9" color="text.regular">
          {title}
        </Typography>
        <Typography variant="heading.H9" color="text.subtle">
          {subtitle}
        </Typography>
      </Stack>

      <ScrollableListContainer showShadows>
        <ParagraphSentimentList rows={paragraphList} />
      </ScrollableListContainer>
    </Container>
  );
};

import { Box, Fade, Modal } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';

import { AgentPopupHeader } from './AgentPopupHeader/AgentPopupHeader';

export interface AgentPopupBaseProps {
  title: string;
  moduleTitle?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  disableStorybookFullscreen?: boolean;
}

const StyledModal = styled(Modal)(() => ({
  backdropFilter: `blur(45px)`,
}));

const Container = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xxlarge,
  width: 650,
  transform: 'translate(-50%, -50%)',
  borderRadius: theme.tokens.borderRadius.medium,
  padding: theme.tokens.spacing.xxxlarge,
  backgroundColor: alpha(theme.tokens.color['neutral.600'], 0.4),
  outline: 'none',
}));

export const AgentPopupBase: React.FC<AgentPopupBaseProps> = ({
  title,
  moduleTitle,
  onClose,
  children,
  isOpen,
  disableStorybookFullscreen,
}) => {
  const disableModalProps = disableStorybookFullscreen
    ? {
        disablePortal: true,
        disableScrollLock: true,
        disableEnforceFocus: true,
      }
    : {};

  return (
    <StyledModal open={isOpen} onClose={onClose} {...disableModalProps}>
      <Fade in={isOpen} timeout={150}>
        <Container>
          <AgentPopupHeader title={title} moduleTitle={moduleTitle} onClose={onClose} />
          {children}
        </Container>
      </Fade>
    </StyledModal>
  );
};

import { getToken } from '@aily/auth-service';
import { captureException } from '@aily/monitoring-service';

export async function downloadMedia(url: string, controller: AbortController) {
  const token = getToken();

  if (!url) {
    return;
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: controller.signal, // Attach the abort signal
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    if (controller.signal.aborted) {
      return; // Gracefully handle fetch abortion
    }

    captureException(error, {
      extra: { url },
      tags: { component: 'downloadMedia' },
    });

    console.error('Error fetching audio:', error);
    throw error;
  }
}

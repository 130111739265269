import { Stack, styled } from '@mui/material';

import { Typography } from '../../../../../Typography';

export interface MilestonesRowProps {
  sentiment: MilestoneSentiment;
  title: string;
  subtitle: string;
}
export type MilestoneSentiment = 'positive' | 'negative';
const MilestoneContainer = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['xsmall'],
  width: '100%',
}));

export const MilestonesRow: React.FC<MilestonesRowProps> = ({ sentiment, title, subtitle }) => {
  return (
    <MilestoneContainer>
      <Typography
        variant="heading.H6"
        color={sentiment === 'positive' ? 'mint.500' : 'pink.500'}
        lineClamp={2}
      >
        {title}
      </Typography>
      <Typography variant="heading.H8">{subtitle}</Typography>
    </MilestoneContainer>
  );
};

import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { SegmentControl, SegmentControlOption } from '../../../../../others/SegmentControl';

export interface OptimisationScopeSelectProps {
  defaultValue?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  options?: [SegmentControlOption<string>, SegmentControlOption<string>]; // Only two options allowed
}

const Root = styled('div')(({ theme }) => ({
  minWidth: 278,
  borderRadius: 20, // Missing borderRadius token
  padding: theme.tokens.spacing['large'],
  backgroundColor: theme.tokens.color['background.light'],
}));

const defaultOptions: [SegmentControlOption<string>, SegmentControlOption<string>] = [
  { value: 'enterprise', label: 'Enterprise' },
  { value: 'asset', label: 'Asset' },
];

export const OptimisationScopeSelect: FC<OptimisationScopeSelectProps> = ({
  options = defaultOptions,
  ...rest
}) => (
  <Root>
    <SegmentControl options={options} {...rest} />
  </Root>
);

import { Box, styled } from '@mui/material';
import React from 'react';

export interface ProgressBarProps {
  percentage: number;
}

const ProgressBarRoot = styled(Box)(() => ({
  width: '100%',
  height: 8,
}));

const ProgressBarFill = styled(Box)<ProgressBarProps>(({ theme, percentage }) => ({
  width: `${percentage}%`,
  height: '100%',
  backgroundColor: theme.tokens.color['mint.500'],
  opacity: 0.2,
  borderRadius: theme.tokens.borderRadius['4'],
  transition: 'width 0.6s ease',
}));

export const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  return (
    <ProgressBarRoot>
      <ProgressBarFill percentage={percentage} />
    </ProgressBarRoot>
  );
};

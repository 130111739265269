import { useFeatureFlags } from '../providers';
import { Variant } from '../types';

/**
 * Gets the feature flag variant for a given key.
 *
 * @param {string} key - The feature flag key.
 * @param {string | Variant} [fallback] - Optional fallback value if the variant is not found.
 * @returns {Variant} The variant for the given key.
 */
export function useFeatureFlagsVariant(key: string, fallback?: string | Variant): Variant {
  const { experimentClient } = useFeatureFlags();
  return experimentClient.variant(key, fallback);
}

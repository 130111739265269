import * as T from '@aily/graphql-sdk/schema';
import { ChipProps } from '@mui/material';
import React from 'react';

import { DataViewContainer, DataViewContainerProps } from '../DataViewContainer';
import { HeaderRow } from './HeaderRow';

export interface HeaderRowComponentProps {
  component: T.HeaderRowComponent;
  sentimentColorMap?: Partial<Record<T.Sentiment, ChipProps['color']>>;
  HeaderDataViewProps?: Partial<DataViewContainerProps>;
}

export const HeaderRowComponent: React.FC<HeaderRowComponentProps> = ({
  component: { id, drillIds, filters, variant, code },
  sentimentColorMap,
  HeaderDataViewProps,
}) => (
  <DataViewContainer
    id={id}
    drillIds={drillIds}
    filters={filters}
    componentCode={code}
    {...HeaderDataViewProps}
  >
    {({ dataView, loading }) => (
      <HeaderRow
        dataView={T.isHeaderRow(dataView) ? dataView : undefined}
        variant={variant}
        loading={loading}
        sentimentColorMap={sentimentColorMap}
      />
    )}
  </DataViewContainer>
);

export default HeaderRowComponent;

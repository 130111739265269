import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const SeparatorLine = styled(Box)(() => ({
  borderTop: '2px solid',
  borderColor: '#4E4E4E',
  width: '100%',
}));

export const HorizontalSeparator: React.FC = () => {
  return <SeparatorLine />;
};

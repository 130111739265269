import { downloadMedia, useEnvironment } from '@aily/saas-core';
import { useEffect, useState } from 'react';
import { useAudio } from 'react-use';

export const formatAudioUrl = (audioSrc: string, url: string) => {
  if (!audioSrc) {
    return '';
  }

  const baseUrl = url.split('/graphql/')[0];
  const encodedAudioNameUrl = encodeURIComponent(audioSrc);

  return `${baseUrl}/file/get-image-file/${encodedAudioNameUrl}`;
};

const createTimeout = (callback: () => void, delay: number) => {
  const timeoutId = setTimeout(callback, delay);
  return () => clearTimeout(timeoutId);
};

export const useRealAudio = (trackName: string, pauseAudio: boolean, onAudioEnd: () => void) => {
  const { API_URL } = useEnvironment();
  const [audioSrc, setAudioSrc] = useState('');

  const [audio, state, controls] = useAudio({
    src: audioSrc,
    autoPlay: false,
  });

  useEffect(() => {
    const controller = new AbortController();

    const fetchMediaBlob = async () => {
      if (trackName) {
        try {
          const formattedAudioUrl = formatAudioUrl(trackName, API_URL);
          const blob = await downloadMedia(formattedAudioUrl, controller);
          setAudioSrc(blob ?? '');
        } catch (error) {
          // Error already handled, no need to show error — media just won't play
        }
      }
    };

    fetchMediaBlob();

    return () => {
      controller.abort();
    };
  }, [trackName, API_URL]);

  useEffect(() => {
    if (!trackName || pauseAudio) {
      return;
    }

    return createTimeout(() => {
      controls.play();
    }, 500);
  }, [trackName, controls, pauseAudio, audioSrc]);

  useEffect(() => {
    if (state.duration > 0 && state.time === state.duration) {
      return createTimeout(() => {
        onAudioEnd();
        setAudioSrc('');
        state.time = 0;
      }, 50);
    }
  }, [state.time, state.duration, state, onAudioEnd]);

  useEffect(() => {
    if (pauseAudio) {
      controls.pause();
    }
  }, [pauseAudio, controls]);

  const fastForward = () => {
    if (state.time < state.duration) {
      controls.seek(state.time + 5);
    }
  };

  const rewind = (onRewindToStart: () => void) => {
    if (state.time > 0) {
      const newTime = Math.max(state.time - 5, 0);

      if (newTime === 0) {
        onRewindToStart?.();
      } else {
        controls.seek(newTime);
      }
    }
  };

  return {
    audio,
    state,
    controls,
    audioProgressPercentage: audioSrc ? (state.time / state.duration) * 100 : 0,
    fastForward,
    rewind,
  };
};

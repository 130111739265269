import * as T from '@aily/graphql-sdk/schema';
import { styled, SvgIconProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ArrowCircleDown, ArrowCircleUp, MinusCircle } from '../../../icons';
import { colors } from '../../../theme/default/colors';
import { mapSentimentToColor } from '../../../utils';

export interface StatusIconProps extends SvgIconProps {
  sentiment?: string;
}

type IconType = 'ArrowCircleUp' | 'ArrowCircleDown' | 'MinusCircle';

const componentMap: Record<IconType, React.ComponentType<SvgIconProps>> = {
  ArrowCircleUp,
  ArrowCircleDown,
  MinusCircle,
};

// Styled icon component factory
const createStyledIcon = (Component: React.ComponentType<StatusIconProps>) =>
  styled(Component, {
    shouldForwardProp: (prop) => prop !== 'sentiment',
  })(({ sentiment }) => ({
    fontSize: 80,
    color: mapSentimentToColor((sentiment ?? T.Sentiment.Neutral) as T.Sentiment, {
      [T.Sentiment.Neutral]: colors.primary.aqua,
    }),
  }));

const StatusIcon: React.FC<StatusIconProps> = ({ sentiment, className, ...rest }) => {
  let IconComponent: React.ComponentType<StatusIconProps> | undefined;

  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      IconComponent = createStyledIcon(componentMap.ArrowCircleUp);
      break;
    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
    case T.Sentiment.Critical:
      IconComponent = createStyledIcon(componentMap.ArrowCircleDown);
      break;
    default:
      IconComponent = createStyledIcon(componentMap.MinusCircle);
  }

  return (
    <IconComponent className={clsx(className, 'StatusIcon')} sentiment={sentiment} {...rest} />
  );
};

export default React.memo(StatusIcon);

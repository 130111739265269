import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { map } from 'lodash-es';
import React from 'react';

import { Stack } from '../../../../Stack';
import {
  LeaderboardRowTemplate,
  LeaderboardRowTemplateProps,
} from './internal/LeaderboardRowTemplate/LeaderboardRowTemplate';

const Separator = styled(Box)({
  height: '1px',
  // TODO: Remove hardcoded color once we have the correct border color in the design system
  backgroundColor: '#404040',
});

export interface LeaderboardTemplateProps {
  rows: LeaderboardRowTemplateProps[];
}

export const LeaderboardTemplate: React.FC<LeaderboardTemplateProps> = ({ rows }) => {
  return (
    <Stack spacing={'medium'}>
      {map(rows, (row) => (
        <>
          <LeaderboardRowTemplate {...row} />
          <Separator />
        </>
      ))}
    </Stack>
  );
};

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { ButtonBase } from '../../../../../buttons/ButtonBase';

export interface AgentCardButtonProps {
  variant?: 'dark' | 'light';
  text: string;
  onClick?: () => void;
}

const ButtonRoot = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<Pick<AgentCardButtonProps, 'variant'>>(({ theme, variant = 'dark' }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 153,
  height: 38,
  gap: theme.tokens.spacing['xsmall'],
  borderRadius: theme.tokens.borderRadius['24'],

  ...(variant === 'dark' && {
    backgroundColor: 'var(--color-neutral-grey-700, #404040)',
    color: theme.tokens.color['text.regular'],
  }),

  ...(variant === 'light' && {
    backgroundColor: 'var(--color-neutral-white, #FFFFFF)',
    color: theme.tokens.color['neutral.black'],
  }),
}));

export const AgentCardButton: React.FC<AgentCardButtonProps> = ({
  variant = 'dark',
  text,
  onClick,
}) => {
  return (
    <ButtonRoot variant={variant} onClick={onClick}>
      <Typography
        variant="heading.button"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '90%',
        }}
      >
        {text}
      </Typography>
    </ButtonRoot>
  );
};

export default AgentCardButton;

import * as T from '@aily/graphql-sdk/schema';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { mapSentimentToColor } from '../../utils';
import { FitText } from '../FitText';

export interface TableCellValueWithForecastProps {
  value: T.ValueWithForecastResult;
}

export const TableCellValueWithForecast: FC<TableCellValueWithForecastProps> = ({ value }) => {
  const { value: actualValue, sentiment, forecastValue, forecastColor } = value;

  return (
    <Stack direction="column" justifyContent="space-between">
      <Typography
        variant="bodyBold"
        sx={{ color: sentiment ? mapSentimentToColor(sentiment) : undefined }}
      >
        <FitText>{actualValue ?? ''}</FitText>
      </Typography>
      <Typography variant="small" sx={{ color: forecastColor }}>
        <FitText>{forecastValue ?? ''}</FitText>
      </Typography>
    </Stack>
  );
};

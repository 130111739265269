import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const RawMaterialsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M11.7892 23.6415L13.2149 23.6442L14.4396 18.5363L10.6348 18.5293L11.7892 23.6415Z" />
    <path d="M23.3788 20.1815L23.376 21.6606C23.6158 21.815 23.8079 22.0461 23.8997 22.3396C24.1068 23.0039 23.7363 23.7103 23.0721 23.9175C22.4078 24.1247 21.7014 23.7542 21.4942 23.0899C21.3688 22.6874 21.4578 22.2712 21.6931 21.9584L20.8217 20.8309C20.0872 21.5392 19.8055 22.6065 20.1109 23.5853C20.3265 24.2765 20.7978 24.8416 21.439 25.1775C22.0791 25.5131 22.812 25.5794 23.504 25.3646C24.1945 25.149 24.7598 24.6777 25.0957 24.0372C25.4319 23.3967 25.498 22.6633 25.2828 21.9728C25.0011 21.0709 24.2785 20.4179 23.3788 20.1815L23.3788 20.1815Z" />
    <path d="M0.00509723 8.57631L2.8898 8.58966V7.46536L0 7.45985L0.00509723 8.57631Z" />
    <path d="M17.5662 3L8.89349 9.74883L25.8773 9.7806L17.5662 3ZM14.429 8.01048L14.2549 8.15219C14.1939 8.20218 14.1194 8.22675 14.0457 8.22675C13.9493 8.22675 13.854 8.18502 13.7883 8.10411C13.6726 7.96198 13.6938 7.75334 13.8364 7.63748L14.0105 7.49577C14.1511 7.38012 14.3613 7.4013 14.4771 7.54385C14.5928 7.68619 14.5716 7.89483 14.429 8.01049V8.01048ZM15.2977 7.30513C15.2361 7.35512 15.1622 7.37969 15.0884 7.37969C14.9921 7.37969 14.8963 7.33754 14.8311 7.25705C14.7154 7.11492 14.737 6.90564 14.8792 6.79041L17.6186 4.56595C17.7612 4.45072 17.9694 4.4719 18.0852 4.61403C18.2009 4.75616 18.1793 4.96543 18.0372 5.08066L15.2977 7.30513Z" />
    <path d="M5.47832 7.63306L3.55377 7.64069V8.41448H4.75458C4.88739 8.41448 5.0077 8.49412 5.06002 8.61634L5.53957 9.74297L6.36119 9.74445L5.47832 7.63306Z" />
    <path d="M5.79968 10.4074L8.05213 16.3645C8.39103 17.2603 9.26199 17.8633 10.2203 17.8652H10.2222L14.8609 17.8722C14.8633 17.8722 14.8656 17.8737 14.8679 17.8739L21.7664 17.8866H21.7747C23.3226 17.8866 24.713 17.0271 25.405 15.6422L27.9999 10.4491L5.79968 10.4074ZM14.0457 16.4919H10.2135C9.746 16.4684 9.31454 16.1179 9.14698 15.6394L7.824 11.8798C7.763 11.7068 7.85408 11.5174 8.02671 11.4568C8.19977 11.3948 8.38871 11.4869 8.44971 11.6596L9.77269 15.4195C9.85127 15.6436 10.048 15.8194 10.2304 15.8287L14.0454 15.8283C14.2286 15.8283 14.3773 15.9768 14.3773 16.1602C14.3775 16.3435 14.2291 16.4919 14.0456 16.4919L14.0457 16.4919ZM15.2255 16.4919H15.0689C14.8857 16.4919 14.737 16.3435 14.737 16.16C14.737 15.9768 14.8855 15.8281 15.0689 15.8281H15.2255C15.4087 15.8281 15.5574 15.9766 15.5574 16.16C15.5574 16.3435 15.4089 16.4919 15.2255 16.4919Z" />
    <path d="M22.3218 21.5116C22.4523 21.471 22.5836 21.4587 22.7131 21.4604L22.7184 18.445C22.411 18.5071 22.0977 18.5499 21.7749 18.5499H21.7656L19.8947 18.5465L22.2202 21.5553C22.2543 21.5409 22.2859 21.5229 22.3219 21.5117L22.3218 21.5116Z" />
  </SvgIcon>
);

export default RawMaterialsIcon;

export function Badge() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none">
      <path
        d="M32.151 14.0876L18.9299 19.8168C18.7754 19.8837 18.6657 20.0198 18.6393 20.1861C18.4013 21.6869 17.3036 29.2918 18.6823 34.0506C20.7477 41.1797 30.2665 48.4645 32.061 49.7875C32.2352 49.9159 32.4687 49.9138 32.64 49.7815C34.3844 48.4341 43.5623 41.082 45.5684 34.0506C46.9285 29.2833 45.8441 21.6763 45.6103 20.1831C45.5845 20.0185 45.4772 19.8832 45.3252 19.815L32.5543 14.0902C32.4262 14.0327 32.2798 14.0318 32.151 14.0876Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M32.3191 17.7389L22.6233 22.038C22.4701 22.1059 22.3617 22.243 22.3364 22.4087C22.1419 23.6838 21.3723 29.3237 22.378 32.8758C23.8842 38.1951 30.7298 43.6286 32.2329 44.7707C32.4085 44.9041 32.6403 44.9013 32.8128 44.764C34.2748 43.6004 40.8719 38.1206 42.3343 32.8758C43.3267 29.3165 42.5658 23.6732 42.3748 22.4053C42.3501 22.2414 42.2441 22.1054 42.0935 22.0363L32.7303 17.7415C32.5999 17.6817 32.4502 17.6807 32.3191 17.7389Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7123 22.2046L34.7757 26.9256L40.1319 27.3752L36.0757 30.7756L37.2777 35.8002L32.7123 33.1372L28.1469 35.8002L29.3489 30.7756L25.2927 27.3752L30.6489 26.9256L32.7123 22.2046Z"
        fill="#48FF9B"
      />
    </svg>
  );
}

import * as T from '@aily/graphql-sdk/schema';
import { filter, findIndex, sortBy } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import H from '../../config/highchartsConfig';
import { mapSentimentToColor } from '../../utils';
import { translateSeriesColor } from '..';
import { Table, TableColumnProps } from '../Table';

const useStyles = makeStyles()((theme) => ({
  tooltipContent: {
    borderRadius: 4,
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[5],
    '& .MuiTable-root': {
      borderCollapse: 'collapse',
    },
    '& .MuiTableCell-root': {
      ...theme.typography.small,
      height: 'auto',
      border: 'none',
      padding: 0,
    },
    '& .MuiTableCell-root + .MuiTableCell-root': {
      paddingLeft: theme.spacing(2),
    },
    '& .MuiTableRow-root + .MuiTableRow-root .MuiTableCell-root': {
      paddingTop: theme.spacing(1),
    },
  },
  tooltipTitle: {
    ...theme.typography.h9,
    marginBottom: theme.spacing(2),
  },
  customTooltipContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  customTooltipLeafLeftContent: {
    marginRight: 20,
    textAlign: 'right',
    width: 70,
  },
  tooltipLeftContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipTotal: {
    marginBottom: theme.spacing(1),
  },
  tooltipRightContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    textAlign: 'right',
    width: 70,
  },
  tooltipRightContentWider: {
    width: 100,
  },
  legendSymbol: {
    display: 'inline-block',
    width: 7,
    height: 7,
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  variance: {
    ...theme.typography.h7,
  },
}));

type ExtendedSeries = H.Series & {
  userOptions: H.SeriesOptionsType & {
    custom?: Partial<
      Pick<
        T.ChartSeries,
        | 'tooltipRangeFormat'
        | 'tooltipTitleLow'
        | 'tooltipTitleMedian'
        | 'tooltipTitleHigh'
        | 'tooltipCustomType'
      >
    >;
  };
};

type ExtendedPoint = H.Point & { custom?: { y?: T.ChartSeriesDataPointValue } };
type ExtendedTooltipFormatterContext = H.TooltipFormatterContextObject & {
  series: ExtendedSeries;
  point: ExtendedPoint;
  points?: ExtendedTooltipFormatterContext[];
};

interface Props {
  context: ExtendedTooltipFormatterContext;
  tooltip: H.Tooltip;
}

interface CustomTooltipTitle {
  Batch: string;
  DatetimeLine: string;
}

export type ChartTooltipCustomFlags = {
  isPlaifolioChart?: boolean;
  isGtmPulseChart?: boolean;
};

type CustomOptions = {
  isInventoryChart?: boolean;
  isWhatIfChart?: boolean;
  isPatientsChart?: boolean;
  custom?: ChartTooltipCustomFlags;
};

type ExtendedChartOptions = H.PlotOptions & CustomOptions;

type RowType = (H.TooltipFormatterContextObject & {
  series: ExtendedSeries;
  point: ExtendedPoint;
  points?: ExtendedTooltipFormatterContext[];
})[];

type RangeSeriesTypes = 'arearange' | 'columnrange' | 'lowmedhigh';

const isRangeSeriesType = (seriesType: string): seriesType is RangeSeriesTypes => {
  return ['arearange', 'columnrange', 'lowmedhigh'].includes(seriesType);
};

export const ChartTooltipFormatter: React.FC<Props> = ({ context }) => {
  const { classes } = useStyles();
  const rows = useMemo(
    () =>
      sortBy(
        filter(context.points, 'series.userOptions.custom.showInTooltip'),
        'series.userOptions.legendIndex',
      ),
    [context.points],
  );

  const getTooltipTitle = useMemo(
    () => (isBatchType: boolean, jsonString: T.Maybe<string> | undefined) => {
      try {
        return isBatchType && jsonString ? JSON.parse(jsonString) : [];
      } catch (e) {
        console.error('Error to parse json data:', e);
        return [];
      }
    },
    [],
  );

  const getTooltipFormattedDate = useMemo(
    () => (date: string) => {
      return new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
        .format(new Date(date))
        .replaceAll('/', '.');
    },
    [],
  );

  const getTooltipFormattedValue = useMemo(
    () => (value: number | null | undefined) => {
      if (!value && typeof value != 'number') {
        return '';
      }

      return value.toLocaleString('en-US', { maximumFractionDigits: 1 });
    },
    [],
  );

  const getBorderStyleForDashedLine = (
    row: ExtendedTooltipFormatterContext,
    maxX: number,
    linkedSeries: number[],
  ) => {
    if (
      row.color &&
      (row.point.x > maxX || (row.point.x === maxX && linkedSeries.includes(row.series.index)))
    ) {
      return '1px dashed';
    }
    return undefined;
  };

  const getBorderColorForDashedLine = (
    row: ExtendedTooltipFormatterContext,
    maxX: number,
    linkedSeries: number[],
  ) => {
    if (
      row.color &&
      (row.point.x > maxX || (row.point.x === maxX && linkedSeries.includes(row.series.index)))
    ) {
      return translateSeriesColor(row.color);
    }
    return undefined;
  };

  const getBackgroundColorForDashedLine = (
    row: ExtendedTooltipFormatterContext,
    maxX: number,
    linkedSeries: number[],
  ) => {
    if (
      row.color &&
      (row.point.x < maxX ||
        (row.point.x === maxX && !linkedSeries.includes(row.series.index)) ||
        (maxX === -1 && !linkedSeries.includes(row.series.index)))
    ) {
      return translateSeriesColor(row.color);
    }
    return undefined;
  };

  const isPlaifolioChart = !!(context.series?.chart.options as ExtendedChartOptions)?.custom
    ?.isPlaifolioChart;

  const isPulseChart = !!(
    (context.series?.chart.options as ExtendedChartOptions)?.custom?.isGtmPulseChart ??
    (context?.points?.[0]?.series?.chart.options as ExtendedChartOptions)?.custom?.isGtmPulseChart
  );

  let plaifolioTooltipTitle;
  if (isPlaifolioChart) {
    // @ts-expect-error This is a very specific case where we know the options are extended
    plaifolioTooltipTitle = context.series.userOptions?.plaifolioTooltipTitle;
  }

  const {
    isInventoryChart = false,
    isWhatIfChart = false,
    isPatientsChart = false,
  } = context.series ? (context.series?.chart.options.plotOptions as ExtendedChartOptions) : {};

  const tooltipRightContentClassname = useMemo(() => {
    const classNames = `${classes.tooltipRightContent}`;
    const widerTooltipRequired = context.points?.some(
      // This RnD series in particular needs wider space for the tooltip
      (point) => point.series.linkedParent?.name === 'Top competitor',
    );
    if (widerTooltipRequired) {
      return `${classNames} ${classes.tooltipRightContentWider}`;
    }
    return classNames;
  }, [context.points]);

  const chartType = Object.freeze({
    INVENTORY: 'INVENTORY',
    WHATIF: 'WHATIF',
    PATIENTS: 'PATIENTS',
    NONE: '',
  });

  const getChartType = () => {
    switch (true) {
      case isInventoryChart:
        return chartType.INVENTORY;
      case isWhatIfChart:
        return chartType.WHATIF;
      case isPatientsChart:
        return chartType.PATIENTS;
      default:
        return chartType.NONE;
    }
  };

  let maxXForTrendSeries = -1;
  let trendLinkedSeries: number[] = [];
  let trendSeries: number[] = [];

  if (isInventoryChart) {
    maxXForTrendSeries =
      context.series?.chart.series
        .find((x) => x.name === 'Total inventory shipped')
        ?.points.findLast((x) => x)?.x ?? -1;

    trendLinkedSeries =
      context.series?.chart.series
        .find((x) => x.name === 'Risk trend')
        ?.linkedSeries.map((a) => a.index) ?? [];
    trendSeries =
      trendLinkedSeries.concat(
        context.series?.chart.series.findIndex((x) => x.name === 'Enrollment trend'),
      ) ?? [];
  }

  if (isPatientsChart) {
    trendLinkedSeries =
      context.series?.chart.series
        .find((x) => x.name === 'Trend' || x.name === 'AI Forecast')
        ?.linkedSeries.map((a) => a.index) ?? [];
    trendLinkedSeries = trendLinkedSeries.concat(
      context.series?.chart.series.findIndex((x) => x.name === 'Plan'),
    );
  }

  if (isWhatIfChart) {
    const dashedSeries = ['Trend', 'Enrollment plan'];
    trendLinkedSeries = context.series?.chart.series
      .filter((x) => dashedSeries.includes(x.name))
      .map((x) => x.index);
  }

  const getFilteredRows = (
    rows: RowType,
    intersectionPoint: number,
    trendSeries: number[],
    typeChart: string,
  ) => {
    let filteredRows = rows[rows.length - 1]?.point.custom?.y?.variance ? rows.slice(0, -1) : rows;

    if (typeChart !== '' && filteredRows[0].point.x === intersectionPoint) {
      filteredRows = rows.filter((x) => !trendSeries.includes(x.series.index));
    }

    return filteredRows;
  };

  const handleRangeCellRender = useCallback(
    (range: string, seriesName: string, label?: string, color?: H.ColorType) => (
      <span>
        <span
          className={classes.legendSymbol}
          style={{
            backgroundColor: color ? translateSeriesColor(color) : undefined,
          }}
        />
        {label ?? `${seriesName} ${range}`}
      </span>
    ),
    [classes],
  );

  const handleCellRender = useCallback(
    (row: ExtendedTooltipFormatterContext, _column: TableColumnProps, columnIndex: number) => {
      const rowIndex = findIndex(rows, row);
      const isBatchType =
        row.series.userOptions.custom?.tooltipCustomType == T.TooltipCustomType.Batch;

      const tooltipTitle: CustomTooltipTitle = getTooltipTitle(
        isBatchType,
        row.point.custom?.y?.value?.formatted,
      );

      if (row.point.custom?.y?.variance && !rows[rowIndex + 1]?.point.custom?.y?.variance) {
        return null;
      }

      if (columnIndex === 0) {
        if (row.point.custom?.y?.variance && rows[rowIndex + 1]?.point.custom?.y?.variance) {
          const variance = row.point.custom?.y?.variance as T.VarianceResult;
          return (
            <span
              className={classes.variance}
              style={{ color: mapSentimentToColor(variance.sentiment ?? T.Sentiment.Neutral) }}
            >
              {variance.actual}
            </span>
          );
        }

        if (
          isRangeSeriesType(row.series.type) &&
          row.series.userOptions.custom?.tooltipRangeFormat === T.TooltipRangeFormat.Split
        ) {
          return (
            <div className={classes.stack}>
              {row.series.userOptions.custom?.tooltipTitleMedian &&
                handleRangeCellRender(
                  '', // Median range cells are not followed by any suffix
                  row.series.name,
                  row.series.userOptions.custom?.tooltipTitleMedian,
                  row.color,
                )}
              {handleRangeCellRender(
                'Upper',
                row.series.name,
                row.series.userOptions.custom?.tooltipTitleHigh ?? undefined,
                row.color,
              )}
              {handleRangeCellRender(
                'Lower',
                row.series.name,
                row.series.userOptions.custom?.tooltipTitleLow ?? undefined,
                row.color,
              )}
            </div>
          );
        }

        if (isBatchType) {
          return (
            <span>
              <span
                className={classes.legendSymbol}
                style={{ backgroundColor: row.color ? translateSeriesColor(row.color) : undefined }}
              />
              {tooltipTitle.Batch[row.point.x]}
            </span>
          );
        }

        const stopIndex = isPulseChart ? 2 : 0;
        return (
          <div className={classes.tooltipLeftContent}>
            {rowIndex === 0 && isPlaifolioChart && (
              <div className={classes.tooltipTotal}>
                <span className={classes.legendSymbol} style={{ backgroundColor: '#fff' }} />
                <span>Total</span>
              </div>
            )}
            <div>
              <span
                className={classes.legendSymbol}
                style={
                  getChartType() === ''
                    ? {
                        backgroundColor: row.color
                          ? translateSeriesColor(row.color, stopIndex)
                          : undefined,
                      }
                    : {
                        border: getBorderStyleForDashedLine(
                          row,
                          maxXForTrendSeries,
                          trendLinkedSeries,
                        ),
                        borderColor: getBorderColorForDashedLine(
                          row,
                          maxXForTrendSeries,
                          trendLinkedSeries,
                        ),
                        backgroundColor: getBackgroundColorForDashedLine(
                          row,
                          maxXForTrendSeries,
                          trendLinkedSeries,
                        ),
                      }
                }
              />
              {row.series.name}
            </div>
          </div>
        );
      }

      if (columnIndex === 1) {
        if (row.point.custom?.y?.variance && rows[rowIndex + 1]?.point.custom?.y?.variance) {
          const variance = rows[rowIndex + 1]?.point.custom?.y?.variance as T.VarianceResult;
          return (
            <span
              className={classes.variance}
              style={{ color: mapSentimentToColor(variance.sentiment ?? T.Sentiment.Neutral) }}
            >
              {variance.actual}
            </span>
          );
        }

        if (isBatchType) {
          return (
            <div className={classes.customTooltipContent}>
              <b className={classes.customTooltipLeafLeftContent}>
                {getTooltipFormattedValue(row.y)}
              </b>
              <b>{getTooltipFormattedDate(tooltipTitle.DatetimeLine[row.point.x])}</b>
            </div>
          );
        }

        switch (row.series.type) {
          case 'arearange':
          case 'columnrange':
          case 'lowmedhigh':
            if (row.series.userOptions.custom?.tooltipRangeFormat === T.TooltipRangeFormat.Split) {
              return (
                <div className={classes.stack}>
                  {row.point.custom?.y?.median?.formatted && (
                    <div className={classes.tooltipRightContent}>
                      {row.point.custom?.y?.median?.formatted}
                    </div>
                  )}
                  <div className={classes.tooltipRightContent}>
                    {row.point.custom?.y?.high?.formatted}
                  </div>
                  <div className={classes.tooltipRightContent}>
                    {row.point.custom?.y?.low?.formatted}
                  </div>
                </div>
              );
            }

            return (
              <b>
                {row.point.custom?.y?.low?.formatted !== row.point.custom?.y?.high?.formatted
                  ? `${row.point.custom?.y?.low?.formatted} - ${row.point.custom?.y?.high?.formatted}`
                  : row.point.custom?.y?.low?.formatted}
              </b>
            );
          default:
            return (
              <div className={tooltipRightContentClassname}>
                {rowIndex === 0 && isPlaifolioChart && (
                  <span className={classes.tooltipTotal}>{row.point.total}</span>
                )}
                {row.point.custom?.y?.value?.formatted}
              </div>
            );
        }
      }

      return null;
    },
    [rows, handleRangeCellRender],
  );

  return (
    <div className={classes.tooltipContent}>
      <div className={classes.tooltipTitle}>{plaifolioTooltipTitle ?? context.x}</div>
      <Table
        rows={getFilteredRows(rows, maxXForTrendSeries, trendSeries, getChartType())}
        columns={[
          { dataKey: 'series', label: '' },
          { dataKey: 'value', label: '', align: 'right' },
        ]}
        onCellRender={handleCellRender}
        withoutHeader
      />
    </div>
  );
};

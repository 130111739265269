import { useFeatureFlagsVariant } from '@aily/feature-flags-service';
import { Banner, BannerVariants } from '@aily/ui-components';
import React, {
  ComponentProps,
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const BANNER_FEATURE_FLAG = 'all_home_banner';

type BannerProps = ComponentProps<typeof Banner>;

export type BannerContextProps = {
  closeBanner: () => void;
  banner: BannerProps | null;
};

export const BannerContext = createContext<BannerContextProps>({
  closeBanner: () => {},
  banner: null,
});

export const useBannerContext = () => {
  const bannerContext = useContext(BannerContext);

  return bannerContext;
};

type BannerProviderProps = PropsWithChildren;

// NOTE; This is a temporary mock banner data. Remove once we have a real banner data
const mockBanner = {
  subject: 'Aily Pro',
  title: 'Connect the dots',
  body: 'Get a clear overview of your financial situation and make informed decisions.',
};

const BaseBannerProvider = ({ children }: { children: ReactNode }) => {
  const [banner, setBanner] = useState<BannerProps | null>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);

  // NOTE; This is a temporary simulation of getting a mock banner data. Remove once we have a real banner data
  useEffect(() => {
    if (banner) {
      return;
    }
    interval.current = setInterval(() => {
      if (Math.random() > 0.5) {
        const type = Math.random() > 0.5 ? 'green' : 'grey';
        const variant = BannerVariants.at(Math.floor(Math.random() * (BannerVariants.length + 1)));
        if (variant) {
          setBanner({
            ...mockBanner,
            type,
            variant,
          });
        } else {
          setBanner({
            ...mockBanner,
            type,
            visualType: 'external',
            visualSrc: 'https://img.icons8.com/color/48/chipping.png',
          });
        }
      }
    }, 10000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [banner]);

  const contextValue = useMemo(
    () => ({
      closeBanner: () => {
        setBanner(null);
      },
      banner,
    }),
    [banner],
  );

  return <BannerContext.Provider value={contextValue}>{children}</BannerContext.Provider>;
};

// TODO: Once the feature flag is removed,
// remove this component and export the BaseBannerProvider as BannerProvider
export function BannerProvider({ children }: BannerProviderProps) {
  const enabledByFeatureFlag = useFeatureFlagsVariant(BANNER_FEATURE_FLAG)?.payload?.enabled;

  if (!enabledByFeatureFlag) {
    return <>{children}</>;
  }
  return <BaseBannerProvider>{children}</BaseBannerProvider>;
}

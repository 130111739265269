import { Banner } from '@aily/ui-components/src/components/banners/Banner';
import { styled } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import type { Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import { useBannerContext } from '../../providers';

const BannerWrapper = styled(motion.div)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1300, // This value is because Drawer (Sidebar) has 1200 by default and we need to be above that for the Banner to be visible
});

const ContentWrapper = styled(motion.div)({
  transition: 'margin-top 0.3s ease',
  '& .MuiDrawer-paper .sidebar-content': {
    transition: 'padding-top 0.3s ease',
  },
});

const TRANSITION_CONFIG: Transition = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
};

function BannerContainer({ children }: { children: React.ReactNode }) {
  const { banner, closeBanner } = useBannerContext();
  const isBannerVisible = Boolean(banner);
  const [bannerHeight, setBannerHeight] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bannerRef.current) {
      setBannerHeight(bannerRef.current.getBoundingClientRect().height);
    }
  }, [banner]);

  useResizeObserver(bannerRef.current, (entry) => {
    setBannerHeight(entry.contentRect.height);
  });

  // Push the sidebar content by this amount
  const paddingTop = isBannerVisible ? `${bannerHeight}px` : 0;

  return (
    <>
      <AnimatePresence>
        {banner && (
          <BannerWrapper
            ref={bannerRef}
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0 }}
            transition={TRANSITION_CONFIG}
          >
            <Banner onClose={closeBanner} {...banner} />
          </BannerWrapper>
        )}
      </AnimatePresence>

      <ContentWrapper
        sx={{
          marginTop: isBannerVisible ? `${bannerHeight}px` : 0,
          '& .MuiDrawer-paper .sidebar-content': {
            paddingTop,
          },
        }}
      >
        {children}
      </ContentWrapper>
    </>
  );
}

export function withBanner<T extends object>(Component: React.ComponentType<T>) {
  return function ComponentWithBanner(props: T) {
    return (
      <BannerContainer>
        <Component {...props} />
      </BannerContainer>
    );
  };
}

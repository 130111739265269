import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const OpenInNewIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M23.5101 23.3333H4.84342V4.66667H14.1768V2H4.84342C3.36342 2 2.17676 3.2 2.17676 4.66667V23.3333C2.17676 24.8 3.36342 26 4.84342 26H23.5101C24.9768 26 26.1768 24.8 26.1768 23.3333V14H23.5101V23.3333ZM16.8434 2V4.66667H21.6301L8.52343 17.7733L10.4034 19.6533L23.5101 6.54667V11.3333H26.1768V2H16.8434Z" />
  </SvgIcon>
);

export default OpenInNewIcon;

import * as T from '@aily/graphql-sdk/schema';
import { Box, IconButton, Skeleton, Stack, styled, useThemeProps } from '@mui/material';
import { motion, MotionProps, Transition } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';

import { useHandleLink, useResponsiveValue } from '../../hooks';
import { ChevronLeft, ChevronRight } from '../../icons';
import { useModule } from '../../providers';
import { mapColorEnumToStatus, mapModuleCodeToLogoVariant } from '../../utils';
import DialsResponsiveCarousel from '../Carousel/DialsResponsiveCarousel';
import { CircularProgressProps } from '../CircularProgress';
import { ModuleLogo } from '../ModuleLogo';
import { RadialGauge, RadialGaugeProps } from '../RadialGauge';
import { TaglineLogo, TaglineLogoProps } from '../TaglineLogo';
import { RenderDialFunction, RenderDialSkeletonFunction } from './types';

const transition: Transition = {
  type: 'spring',
  stiffness: 200,
  damping: 20,
  duration: 0.3,
};

const paddingTop = 46;

const StyledBox = styled(Box)(() => ({
  position: 'relative',
  width: 986,
  height: 200,
  paddingTop,
}));

const ExpandArrows = styled(Box)(({ theme }) => ({
  position: 'absolute',
  overflow: 'visible',
  bottom: 0,
  left: 0,
  width: '100%',
  height: 154,
  pointerEvents: 'none',
  '& .MuiIconButton-root': {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    padding: 4,
    pointerEvents: 'all',
    color: theme.palette.text.primary,
  },
}));

export const LogoWrapper = styled(motion.div)(() => ({}));

export const CompactLogoWrapper = styled(motion.div)(() => ({
  position: 'absolute',
  top: paddingTop,
  left: 0,
}));

const InlineLogoWrapper = styled(motion.div)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
}));

interface ExpandableDialsProps {
  dataView?: T.DialsDataView;
  loading?: boolean;
  useCarousel?: boolean;
  onDialClick?: (link: T.Link) => void;
  onDialRender?: RenderDialFunction;
  onDialSkeletonRender?: RenderDialSkeletonFunction;
}

const FadeIn = styled((props: MotionProps) => (
  <motion.div
    initial="visible"
    variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
    transition={transition}
    {...props}
  />
))({
  height: '100%',
});

export const ExpandableDials: React.FC<ExpandableDialsProps> = ({
  dataView,
  loading,
  useCarousel,
  onDialClick,
  onDialRender,
  onDialSkeletonRender,
}) => {
  const module = useModule();
  const handleLink = useHandleLink();
  const [expanded, setExpanded] = useState(false);
  const itemsCount = dataView?.items?.length ?? 0;
  const isInline = itemsCount > 4;

  const circularProgressThemeProps = useThemeProps({
    props: {} as CircularProgressProps,
    name: 'CircularProgress',
  });

  const gaugeSize = useResponsiveValue(circularProgressThemeProps.size ?? 154, 154);

  const radialGaugeProps: RadialGaugeProps = useMemo(() => {
    return {};
  }, []);

  const containerWidth = useResponsiveValue<number>({ xs: 986, xl: 1100, xxl: 1400 }) as number;
  const containerHeight = useResponsiveValue<number>({ xs: 154, xl: 170, xxl: 220 }) as number;
  const spacing = 40;
  const extraWidth = (gaugeSize + spacing) * Math.max(0, itemsCount - 3) - spacing;
  const expandWidth = containerWidth + extraWidth;

  const taglineLogoProps: TaglineLogoProps = {
    logo: (
      <ModuleLogo
        variant={mapModuleCodeToLogoVariant(module?.moduleCode ?? T.ModuleCode.Financial)}
      />
    ),
    tagline: 'Make. Today. Count.',
    annotation: loading ? (
      <Skeleton variant="text" sx={{ width: 200, height: 14, mt: 0 }} />
    ) : (
      `${dataView?.annotation} (${dataView?.annotationDate})`
    ),
  };

  const handleRadialGaugeLabelClick = useCallback(
    (link?: T.Link) => () => {
      if (link) {
        const radialGaugeLabelClickHandler = onDialClick ? onDialClick : handleLink;
        radialGaugeLabelClickHandler(link);
      }
    },
    [onDialClick, handleLink],
  );

  const renderDialItem = useCallback(
    (dial: T.DialItem, index: number) => {
      const {
        progress,
        progressText,
        target,
        targetText,
        actualText,
        varianceText,
        varianceAIText,
        sentiment: color,
        label,
        link,
      } = dial;

      const element = onDialRender?.(dial, index);
      if (element) {
        return element;
      }

      return (
        <RadialGauge
          key={index}
          {...radialGaugeProps}
          progress={progress}
          progressText={progressText?.value ?? undefined}
          target={target ?? undefined}
          targetText={targetText?.value ?? undefined}
          color={mapColorEnumToStatus(color)}
          label={label}
          LabelTypographyProps={
            link?.linkResult
              ? {
                  style: { cursor: 'pointer' },
                  onClick: handleRadialGaugeLabelClick(link.linkResult),
                }
              : undefined
          }
          actualText={actualText?.value ?? undefined}
          varianceText={varianceText?.value ?? undefined}
          varianceAIText={varianceAIText?.value ?? undefined}
          varianceTextColor={
            varianceText?.color?.color
              ? mapColorEnumToStatus(varianceText.color.color as T.Color)
              : undefined
          }
          varianceAITextColor={
            varianceAIText?.color?.color
              ? mapColorEnumToStatus(varianceAIText.color.color as T.Color)
              : undefined
          }
        />
      );
    },
    [radialGaugeProps, onDialRender, handleRadialGaugeLabelClick],
  );

  const mapDialsToDialItem = useCallback(() => {
    return dataView?.items?.map((item, index) => {
      if (useCarousel) return renderDialItem(item, index);

      const visibility = index <= 2 || expanded ? 'visible' : 'hidden';
      return (
        <FadeIn key={index} animate={visibility} initial={visibility}>
          {renderDialItem(item, index)}
        </FadeIn>
      );
    });
  }, [dataView?.items, expanded, useCarousel, renderDialItem]);

  const renderDials = useCallback(() => {
    if (loading) {
      const element = onDialSkeletonRender?.();

      if (element) {
        return element;
      }

      return (
        <>
          <RadialGauge {...radialGaugeProps} loading />
          <RadialGauge {...radialGaugeProps} loading />
          <RadialGauge {...radialGaugeProps} loading />
        </>
      );
    }

    if (useCarousel) {
      return <DialsResponsiveCarousel>{mapDialsToDialItem()}</DialsResponsiveCarousel>;
    }

    return mapDialsToDialItem();
  }, [loading, radialGaugeProps, useCarousel, mapDialsToDialItem, onDialSkeletonRender]);

  const renderExpandContent = useCallback(
    () => (
      <>
        {isInline && (
          <LogoWrapper
            animate={!expanded ? 'visible' : 'hidden'}
            transition={transition}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <TaglineLogo {...taglineLogoProps} />
          </LogoWrapper>
        )}
        <Stack
          direction="row"
          spacing={5}
          sx={{
            position: 'absolute',
            ...(useCarousel
              ? { left: '70%', top: '50%', overflow: 'hidden', transform: 'translate(-50%, -50%)' }
              : { right: 0 }),
          }}
          data-testid="Dials"
        >
          {renderDials()}
        </Stack>
      </>
    ),
    [isInline, expanded, taglineLogoProps, useCarousel, renderDials],
  );

  return (
    <StyledBox sx={{ width: containerWidth, height: containerHeight + paddingTop }}>
      {isInline ? (
        <InlineLogoWrapper
          initial="hidden"
          animate={expanded ? 'visible' : 'hidden'}
          transition={transition}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <TaglineLogo {...taglineLogoProps} inline />
        </InlineLogoWrapper>
      ) : (
        <>
          <LogoWrapper
            animate={!expanded ? 'visible' : 'hidden'}
            transition={transition}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            sx={{ position: 'absolute', top: paddingTop, left: 0 }}
          >
            <TaglineLogo {...taglineLogoProps} />
          </LogoWrapper>
          <CompactLogoWrapper
            initial="hidden"
            animate={expanded ? 'visible' : 'hidden'}
            transition={transition}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <TaglineLogo {...taglineLogoProps} compact />
          </CompactLogoWrapper>
        </>
      )}
      {!useCarousel ? (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            width: expandWidth,
          }}
          component={motion.div}
          animate={expanded ? 'expanded' : 'collapsed'}
          transition={transition}
          variants={{
            expanded: { translateX: -extraWidth - 40 },
            collapsed: { translateX: 0 },
          }}
        >
          {renderExpandContent()}
        </Box>
      ) : (
        <>{renderExpandContent()}</>
      )}
      {!useCarousel && (
        <ExpandArrows sx={{ height: containerHeight }}>
          <IconButton onClick={() => setExpanded(!expanded)}>
            {expanded ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </ExpandArrows>
      )}
    </StyledBox>
  );
};

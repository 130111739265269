import { HStack, Text } from '@aily-labs/ui';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  styled,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { ReactNode, useCallback } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { lineClamp } from '../../theme/utils';
import { ListItemResult } from './types';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  color: theme.palette.text.secondary,
  '&.MuiListItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'initial',
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
      color: theme.tokens.color['neutral.grey-light'],
    },
  },
  '& .MuiListItemSecondaryAction-root': {
    position: 'relative',
    top: 'auto',
    right: 'auto',
    transform: 'none',
  },
  '& .MuiListItemButton-root': {
    flexGrow: 0,
    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
  '& .MuiIconButton-root': {
    width: 70,
    height: 33,
    marginRight: 0,
    borderRadius: 0,
    color: 'inherit',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  '& mark': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: 0,
  },
  '& .MuiListItemText-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiTypography-root, & .MuiTypography-root + .MuiTypography-root': {
      ...lineClamp(1),
    },
    '& .MuiTypography-root:only-child': {
      ...lineClamp(2),
      lineHeight: 16 / 15,
    },
  },
}));
interface NestedPrioritiesDimensionsProps {
  currentItems: ListItemResult[];
  drillDown: (item: ListItemResult) => void;
  drillUp: () => void;
  loading: boolean;
  parent?: string;
  secondaryAction: (item: ListItemResult) => ReactNode;
}

const NestedPrioritiesDimensions: React.FC<NestedPrioritiesDimensionsProps> = ({
  currentItems,
  drillDown,
  drillUp,
  loading,
  parent,
  secondaryAction,
}) => {
  const Row = ({ index, style }: ListChildComponentProps) => {
    const item = currentItems[index];
    const isFirstRow = parent === item.label;
    const isDrillable = item.isDrillable || !isEmpty(item.children);

    const handleOnClick = useCallback(() => {
      if (!isDrillable) return;

      if (isFirstRow) drillUp();
      else drillDown(item);
    }, [isFirstRow, item, isDrillable]);

    if (loading) return <Skeleton />;

    return (
      <StyledListItem
        style={style}
        disablePadding
        key={index}
        secondaryAction={!isFirstRow && secondaryAction(item)}
      >
        <ListItemButton
          onClick={handleOnClick}
          sx={{ cursor: !isFirstRow && !isDrillable ? 'default' : 'pointer' }}
        >
          {isFirstRow ? (
            <HStack gap="$spacingXSmall" alignItems="center">
              <ListItemIcon>
                <ChevronLeft />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Text type="boldCondensed" color="$textColorRegular">
                    {parent}
                  </Text>
                }
              />
            </HStack>
          ) : (
            <HStack gap="$spacingXSmall" alignItems="center">
              <ListItemText
                disableTypography
                primary={
                  <Text type="regular" color="$textColorRegular">
                    {item.label}
                  </Text>
                }
              />
              {isDrillable && (
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
              )}
            </HStack>
          )}
        </ListItemButton>
      </StyledListItem>
    );
  };

  if (!currentItems) return null;

  return (
    <FixedSizeList height={12 * 46} width="100%" itemSize={46} itemCount={currentItems.length}>
      {Row}
    </FixedSizeList>
  );
};

export default NestedPrioritiesDimensions;

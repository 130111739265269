import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const CorrelatorIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      d="M13.5 9.6C15.2121 9.6 16.6 8.21208 16.6 6.5C16.6 4.78792 15.2121 3.4 13.5 3.4C11.7879 3.4 10.4 4.78792 10.4 6.5C10.4 8.21208 11.7879 9.6 13.5 9.6ZM16.3265 10.0018C17.3472 9.17682 18 7.91464 18 6.5C18 4.01472 15.9853 2 13.5 2C11.0147 2 9 4.01472 9 6.5C9 8.09128 9.82596 9.48965 11.0725 10.2898L8.48677 15.4613C7.88758 15.166 7.21317 15.0001 6.5 15.0001C4.01472 15.0001 2 17.0148 2 19.5001C2 21.9854 4.01472 24.0001 6.5 24.0001C8.18228 24.0001 9.64895 23.077 10.4211 21.7097L21.2874 25.1672L21.7119 23.8331L10.9108 20.3963C10.9693 20.1067 11 19.807 11 19.5001C11 18.2377 10.4802 17.0967 9.64291 16.2795L12.356 10.8533C12.7213 10.949 13.1047 11 13.5 11C14.0686 11 14.6125 10.8946 15.1133 10.7022L22.4062 22.3709L23.5934 21.6289L16.3265 10.0018ZM6.5 22.6001C8.21208 22.6001 9.6 21.2122 9.6 19.5001C9.6 17.788 8.21208 16.4001 6.5 16.4001C4.78792 16.4001 3.4 17.788 3.4 19.5001C3.4 21.2122 4.78792 22.6001 6.5 22.6001Z"
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M25.5 28.6C27.2121 28.6 28.6 27.2121 28.6 25.5C28.6 23.7879 27.2121 22.4 25.5 22.4C23.7879 22.4 22.4 23.7879 22.4 25.5C22.4 27.2121 23.7879 28.6 25.5 28.6ZM25.5 30C27.9853 30 30 27.9853 30 25.5C30 23.0147 27.9853 21 25.5 21C23.0147 21 21 23.0147 21 25.5C21 27.9853 23.0147 30 25.5 30Z"
      fill="#48FF9B"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default CorrelatorIcon;

import { styled } from '@mui/material/styles';
import React from 'react';

import { ButtonBase } from '../../../../../buttons';
import { CloseIcon } from '../../../../../icons';
import { Typography } from '../../../../../Typography';

export interface AgentPopupHeaderProps {
  title: string;
  moduleTitle?: string;
  onClose: () => void;
}

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.tokens.spacing['medium'],
}));

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.tokens.spacing.small,
}));

export const AgentPopupHeader: React.FC<AgentPopupHeaderProps> = ({
  title,
  moduleTitle,
  onClose,
}) => {
  return (
    <HeaderContainer>
      <TitleContainer>
        {moduleTitle && (
          <Typography variant="heading.H5" color="sentiment.neutral">
            {moduleTitle}
          </Typography>
        )}
        <Typography variant="heading.H5">{title}</Typography>
      </TitleContainer>
      <ButtonBase onClick={onClose}>
        <CloseIcon size="icon.medium" color="neutral.grey-lighter" />
      </ButtonBase>
    </HeaderContainer>
  );
};

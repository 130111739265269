import theme from '@aily/saas-core/theme/default';
import { defaultTheme } from '@aily/ui-theme';
import { alpha, ThemeProvider } from '@mui/material/styles';
import ReactDOMServer from 'react-dom/server';

import H from '../../../../config/highchartsConfig';
import { yearOnlyFirstLastFormatter } from './utils';
import ValueMarker from './ValueMarker';

export const baseOptions: H.Options & {
  series: [H.SeriesOptionsType, H.SeriesOptionsType];
} = {
  chart: {
    height: 450,
    style: {
      overflow: 'visible', // So that nothing is cut off
    },
  },
  xAxis: {
    type: 'category',
    categories: [], // Make sure to provide categories as date strings (e.g., ["2022-01-01", "2023-01-01", ...])
    labels: {
      style: {
        ...defaultTheme.typography['body.tiny'],
        color: defaultTheme.color['neutral.400'],
      } as H.CSSObject,
      formatter: yearOnlyFirstLastFormatter,
    },
  },
  yAxis: {
    opposite: true,
    labels: {
      style: {
        ...defaultTheme.typography['body.tiny'],
        color: defaultTheme.color['neutral.400'],
      } as H.CSSObject,
    },
    gridLineColor: '#313131', // Missing color token
  },
  series: [
    {
      name: 'Topic',
      type: 'line',
      color: defaultTheme.color['brand.orange'],
      lineWidth: 2,
      zIndex: 1, // Display above the other series
      legendSymbol: 'rectangle',
      data: [],

      // Add a custom marker for the last data point
      marker: {
        enabled: true,
        radius: 0, // Hide the default marker
      },
      dataLabels: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          // Check if this is the last data point
          const isLastPoint = this.point.index === this.series.data.length - 1;
          if (isLastPoint) {
            return ReactDOMServer.renderToString(
              <ThemeProvider theme={theme}>
                <ValueMarker value={this.y} color={defaultTheme.color['brand.orange']} />
              </ThemeProvider>,
            );
          }
          return '';
        },
      },
    },
    {
      name: 'Comparison',
      type: 'area',
      color: defaultTheme.color['brand.aqua'],
      // This is the color token "gradient.aqua.transparent-dark" converted to a Highcharts gradient
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, defaultTheme.color['aqua.700']],
          [1, alpha(defaultTheme.color['aqua.700'], 0)],
        ],
      },
      lineWidth: 2,
      zIndex: 0,
      legendSymbol: 'rectangle',
      data: [],
    },
  ],
  legend: {
    enabled: true,
    align: 'left', // Align legend to the left
    verticalAlign: 'bottom',
  },
  tooltip: {
    enabled: false,
  },
};
